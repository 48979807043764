import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropertiesMarketplace } from "../HomePage/PropertiesMarketplace";
import { generalActions } from "../../_actions";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Carousel from "react-multi-carousel";
import PropertyBoxComponent from "../../components/PropertyBoxComponent";
import { Skeleton } from "@material-ui/lab";
import PropertyCardSkeleton from "../../components/PropertyCardSkeleton";
const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="testimonials-buttons-group">
      <IconButton onClick={previous} aria-label="Previous">
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton onClick={next} aria-label="Next">
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

const PromotedPropertiesSlider = ({
  getProperties,
  properties,
  getAllLocations,
  getAllPropertyActiveLocations,
}) => {
  const [isLoading, setLoading] = useState(true);
  const desktop = useMediaQuery("(min-width:660px)");
  const navigate = useNavigate();
  useEffect(() => {
    getProperties(10, 0, true);
    getAllLocations();
    getAllPropertyActiveLocations();
  }, []);

  // console.log(properties);
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  if (properties?.data?.length > 0) {
    return (
      <section className="promoted-properties-section">
        <Container>
          <h3>Homes For You</h3>
        </Container>
        <Container>
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-padding-bottom"
            customButtonGroup={<ButtonGroup />}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 768,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1200,
                  min: 769,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {properties?.data.map((property, index) => (
              <div
                style={{ padding: "0 10px", height: "99%" }}
                key={property.property_id}
              >
                <PropertyBoxComponent
                  variant={property.property_type}
                  property={property}
                />
              </div>
            ))}
          </Carousel>
        </Container>
        <div className="show-more-wrapper">
          <p>Continue exploring mansions</p>
          <Button onClick={() => navigate("/marketplace")} size="medium">
            Show more
          </Button>
        </div>
      </section>
    );
  }

  return (
    <section className="promoted-properties-section">
      <Grid justifyContent="center" alignItems="center" container>
        {desktop ? (
          Array.from(new Array(3)).map((item, index) => (
            <PropertyCardSkeleton index={index} key={index} />
          ))
        ) : (
          <PropertyCardSkeleton index={4} />
        )}
      </Grid>
    </section>
  );
};

function mapState(state) {
  const { general } = state;
  const { properties, locations, activePropertyLocations } = general;
  return { properties, locations, activePropertyLocations };
}

const actionCreators = {
  getProperties: generalActions.getProperties,
  getAllLocations: generalActions.getAllLocations,
  getAllPropertyActiveLocations: generalActions.getAllPropertyActiveLocations,
  resetPropertyActiveLocations: generalActions.resetPropertyActiveLocations,
};

export default connect(mapState, actionCreators)(PromotedPropertiesSlider);
