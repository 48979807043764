import moment from "moment";
import { requiredKeyFigures } from "./initialState";

export const differenceBetweenTwoArrays = (arr1, arr2) => {
  let differenceArray = arr1.filter((x) => !arr2.includes(x));
  return differenceArray;
};

const getKeyFigureName = (key, userMeasurement) => {
  switch (key) {
    case "Sq Feet":
      return userMeasurement === "metric" ? "Sq Meters" : key;
    default:
      return key;
  }
};

const getKeyFigureValue = (key, value, userMeasurement) => {
  const squareMeterRate = userMeasurement === "metric" ? 0.092903 : 1;
  switch (key) {
    case "Sq Feet":
      const isInteger = /^\d+$/.test(value);
      const val = parseInt(value);
      return (isInteger && val && Math.round(val * squareMeterRate)) || value;
    default:
      return value;
  }
};

export const setProperty = (
  property,
  userMeasurement,
  onUpdatePropertyLocation
) => {
  property.macrolocation_heading_en = property.macrolocation_heading?.en || "";
  property.macrolocation_heading_de = property.macrolocation_heading?.de || "";
  property.macrolocation_description_en =
    property.macrolocation_description?.en || "";
  property.macrolocation_description_de =
    property.macrolocation_description?.de || "";
  delete property.macrolocation_heading;
  delete property.macrolocation_description;

  property.microlocation_heading_en = property.microlocation_heading?.en || "";
  property.microlocation_heading_de = property.microlocation_heading?.de || "";
  property.microlocation_description_en =
    property.microlocation_description?.en || "";
  property.microlocation_description_de =
    property.microlocation_description?.de || "";
  delete property.microlocation_heading;
  delete property.microlocation_description;

  property.predicted_value_increase_en =
    property.predicted_value_increase?.en || "";
  property.predicted_value_increase_de =
    property.predicted_value_increase?.de || "";
  delete property.predicted_value_increase;

  property.forecasted_distribution_en =
    property.forecasted_distribution?.en || "";
  property.forecasted_distribution_de =
    property.forecasted_distribution?.de || "";
  delete property.forecasted_distribution;

  property.legal_warnings_en = property.legal_warnings?.en || "";
  property.legal_warnings_de = property.legal_warnings?.de || "";
  delete property.legal_warnings;

  property.macro_location_image_preview = property.macrolocation_image_path;
  property.micro_location_image_preview = property.microlocation_image_path;
  // @TODO
  property.property_floor_plans = (property.property_floor_plans || []).map(
    (fp) => ({
      ...fp,
      floor_plan_image_preview: fp.image_path,
    })
  );

  property.property_price = property.property_price || 0;

  if (property.location_coords && property.location_coords.lat) {
    property.location_coords.lat =
      parseFloat(property.location_coords.lat) || 0;
    property.location_coords.lng =
      parseFloat(property.location_coords.lng) || 0;
    var location_coords = {
      position: {
        lat: property.location_coords.lat,
        lng: property.location_coords.lng,
      },
    };
    onUpdatePropertyLocation(location_coords);
  }

  property.sections = (property.property_sections || []).map((s) => ({
    id: s.id,
    section_heading_en: s.heading.en,
    section_description_en: s.description.en,
    heading_tab_value: 0,
    description_tab_value: 0,
    section_key: s.section_key,
  }));
  const keyFigureArray = [];
  const addedKeyFigures = (property.property_key_figures || []).map((f) => {
    keyFigureArray.push(f.property_key.en);
    return {
      id: f.id,
      property_key_en: getKeyFigureName(f.property_key.en, userMeasurement),
      property_value_en: getKeyFigureValue(
        f.property_key.en,
        f.property_value.en,
        userMeasurement
      ),
      tab_value_key: 0,
      tab_value_val: 0,
    };
  });
  // diffArrayDefaultAddedKeyFig - Difference between already added key feaures and required ones
  const diffArrayDefaultAddedKeyFig = differenceBetweenTwoArrays(
    requiredKeyFigures,
    keyFigureArray
  );
  // Create appropriate objects for missing required features
  const defaultMissingKeyFigures = diffArrayDefaultAddedKeyFig.map(
    (keyName) => {
      return {
        property_key_en: keyName,
        property_value_en: "",
        tab_value_key: 0,
        tab_value_val: 0,
      };
    }
  );
  // Sort already added features and missing ones
  // First show required
  property.key_figures = [...defaultMissingKeyFigures, ...addedKeyFigures].sort(
    (a, b) => {
      if (
        requiredKeyFigures.includes(a.property_key_en) &&
        !requiredKeyFigures.includes(b.property_key_en)
      ) {
        return -1;
      } else if (
        !requiredKeyFigures.includes(a.property_key_en) &&
        requiredKeyFigures.includes(b.property_key_en)
      ) {
        return 1;
      } else {
        return a.property_key_en > b.property_key_en
          ? 1
          : b.property_key_en > a.property_key_en
          ? -1
          : 0;
      }
    }
  );
  property.partners = (property.property_project_partners || []).map((p) => ({
    id: p.id,
    partner_id: p._partner_id,
    involvement: p.involvement,
  }));

  property.property_picture = (property.property_images || []).map((p) => ({
    id: p.id,
    index: p.index,
    property_picture_preview: p.image_path,
    property_images: null,
    image_description_en: p.description.en,
    tab_value: 0,
  }));

  property.property_picture.sort((a, b) => a.index - b.index);

  property.property_documents = (property.property_documents || []).map(
    (d) => ({
      id: d.id,
      property_doc_file: null,
      property_doc_filename: d.file_path?.toLowerCase(),
      file_description_en: d.description?.en,
      tab_value: 0,
    })
  );

  property.start_date = moment(property.start_date, "DD-MM-YYYY").toDate();
  property.end_date = moment(property.end_date, "DD-MM-YYYY").toDate();
  property.promoted_date = property.promoted_date;

  return property;
};
