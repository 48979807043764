import { Box, Typography } from "@material-ui/core";
import { SectionLineComponent } from "./SectionLineComponent";
import ReactHtmlParser from "react-html-parser";

export const PartnersComponent = (props) => {
  const { partners } = props;

  return partners.length > 0
    ? partners.map((partner, index) => {
        const { involvement, partner_master } = partner;
        const partner_description = partner_master
          ? partner_master.description
          : "";
        const imageStyle = {
          width: "225px",
          height: "61px",
          backgroundImage: `url(${partner_master.logo})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        };

        return (
          <>
            <Box key={index}>
              <Box>
                <Typography variant="body1" style={{ marginBottom: "24px" }}>
                  {involvement ? `${involvement}` : ""}
                </Typography>
              </Box>
              <Box mb={2} sx={imageStyle}>
                {/* {partner_master.logo ? '' : 'R'} */}
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h6"
                  style={{ textDecoration: "underline" }}
                >
                  {partner_master.name}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2">
                  {ReactHtmlParser(
                    partner_description ? partner_description : ""
                  )}
                </Typography>
              </Box>
            </Box>
            {index === partners.length - 1 ? (
              ""
            ) : (
              <SectionLineComponent mt={3} mb={3} />
            )}
          </>
        );
      })
    : "";
};
