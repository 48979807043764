import { blockchainService } from "../_services";
import { blockchainConstants } from "../_constants";
import { alertActions } from "./";

function getBalances() {
  return (dispatch) => {
    blockchainService.getBalances().then(
      (balances) => {
        dispatch(success(balances));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(balances) {
    return {
      type: blockchainConstants.GET_BALANCES_SUCCESS,
      payload: balances,
    };
  }
  function failure(error) {
    return { type: blockchainConstants.GET_BALANCES_FAILURE, error };
  }
}

export const blockchainActions = {
  getBalances,
};
