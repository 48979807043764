import React from "react";
import { Grid, Box, TextField, Tabs, Tab, Paper } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import { TabPanel } from "../../../../components/GeneralComponent/LanguageTabs";
import "../../styles.scss";

const RenderFinancialPart = (state, handleChange) => {
  const { property, predicted_value_increase_tabIndex } = state;

  return (
    <div className="step-wizard-block block-2" id="step_2">
      <Grid container spacing={1}>
        <Grid item md={8} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                value={property.net_rental_income}
                onChange={handleChange}
                id="text-net_rental_income"
                name="net_rental_income"
                className="form-control"
                label="Net Rental Income *"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                label="Management Costs *"
                value={property.management_costs}
                name="management_costs"
                id="text-management_costs"
                onChange={handleChange}
                className="form-control"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                value={property.property_management_cost}
                onChange={handleChange}
                id="text-property_management_cost"
                name="property_management_cost"
                className="form-control"
                label="Property Management Cost *"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                label="Rent Protection Cost *"
                value={property.rent_protection_cost}
                name="rent_protection_cost"
                id="text-rent_protection_cost"
                onChange={handleChange}
                className="form-control"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                value={property.maintenance_cost}
                onChange={handleChange}
                id="text-maintenance_cost"
                name="maintenance_cost"
                className="form-control"
                label="Maintenance Cost *"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                label="Distribution Surplus *"
                value={property.distribution_surplus}
                name="distribution_surplus"
                id="text-distribution_surplus"
                onChange={handleChange}
                className="form-control"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                value={property.predicted_value_increase_p_a}
                onChange={handleChange}
                id="text-predicted_value_increase_p_a"
                name="predicted_value_increase_p_a"
                className="form-control"
                label="Predicted Value Increase PA *"
                validators={["required", "isFloat"]}
                errorMessages={[
                  "This field is required",
                  "This field only contains numbers",
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Box component="div" mt={2}>
                <TabPanel value={predicted_value_increase_tabIndex} index={0}>
                  <TextField
                    fullWidth
                    id="text-predicted_value_increase_en"
                    name="predicted_value_increase_en"
                    label="Predicted Value Increase"
                    multiline
                    minRows={3}
                    value={property.predicted_value_increase_en}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderFinancialPart;
