import { Container, Box, Divider, Typography } from "@material-ui/core";

export const FrequentlyAskedQuestions = () => {
  return (
    <Box sx={{ my: 10 }} className="page-box">
      <Container>
        <Typography variant="h4" paragraph={true} align="center">
          Frequently Asked Questions
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>1. What is the Immobilium platform?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium platform is a real estate trading and investment platform
            enabling users to instantly purchase actual properties and
            participate in fractional ownership investments. The platform aims
            to become the first global decentralized real estate exchange,
            powered by blockchain.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>2. How do I register?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            To start with the registration process you will need to verify your
            email and register as a user. This will allow you to browse through
            the properties <strong>without</strong> possibility to purchase
            properties or partake in fractional ownership investments.{" "}
            <strong>Once you complete KYC</strong>, the option to purchase
            properties will be made available. If you are an agent or
            professional user, please go to{" "}
            <a href="https://www.agents.immobilium.io" target="_blank">
              www.agents.immobilium.io
            </a>{" "}
            and receive your free promotional $IMB tokens!
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>3. What is KYC?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            KYC stands for Know Your Client. Since on our platform we are
            dealing with real estate and these transactions ultimately require
            knowledge of persons involved in the transactions we engage in KYC
            process to verify identity of persons registering as well as
            compliance of their background to be part of the platform. This way
            we can ensure compliance with all legal processes involved in actual
            transactions.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>4. How can I purchase properties?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            When you find the property you like and proceed with purchase, you
            will be asked how you would like to pay for the property. There are
            three options
          </Typography>
          <ol>
            <li>
              With funds that you have previously connected to your account
              (most conveniently send via Revolut -{" "}
              <a href="https://www.revolut.com/" target="_blank">
                www.revolut.com
              </a>
              )
            </li>
            <li>
              With a direct transfer to Escrow account (US properties have
              designated Escrow agents, all non US property transactions are
              related to Zedra -{" "}
              <a href="https://www.zedra.com/" target="_blank">
                www.zedra.com
              </a>
              )
            </li>
            <li>
              With crypto payment gateway via Utrust (
              <a href="https://www.utrust.com" target="_blank">
                www.utrust.com
              </a>
              )
            </li>
          </ol>
          <Typography variant="body1" paragraph={true} align="justify">
            Please note that once you choose to buy property, all transaction
            parties will be notified of your action and for the transaction to
            be completed, transferred funds need to appear at the designated
            Escrow within 72 hours from the initiation. The property you have
            selected will be unavailable and temporarily removed form the
            platform pending transaction completion.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>5. What Is Escrow?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Escrow is a legal concept describing a financial agreement whereby
            an asset or money is held by a third party on behalf of two other
            parties that are in the process of completing a transaction. Escrow
            accounts are managed by the escrow agent. The agent releases the
            assets or funds only upon the fulfillment of predetermined
            contractual obligations (or upon receiving appropriate
            instructions).
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            In USA use of Escrow agents is more common and all transactions are
            mediated by select escrow partners. For transactions outside the US,
            Immobilium is working with Zedra (
            <a href="https://www.zedra.com" target="_blank">
              www.zedra.com
            </a>
            ).
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>6. What is a crypto payment gateway?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Most sellers want to receive FIAT (USD, EUR) for their properties.
            Buyers who have selected cryptocurrencies (BTC or ETH) can choose to
            buy with those cryptocurrencies.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            By selecting the option to pay with cryptocurrencies buyers will be
            given a time limited option to pay with cryptocurrencies via Utrust
            (
            <a href="https://www.utrust.com" target="_blank">
              www.utrust.com
            </a>
            ) payment gateway. Utrust will then proceed to convert crypto into
            FIAT and deposit FIAT to appropriate Escrow account for further
            transaction processing. Transaction times are sufficient to comply
            with Immobilium regulations of 72 hours for the funds to be visible
            at Escrow accounts.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            This way we save our users time, money and other exposure by
            allowing direct conversion of one asset to another. Risk of crypto
            depreciation is on the side of the buyer.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>7. What are the fees to use the platform?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium charges no more than 2.5% (two and a half percent) fee
            for using the platform. Depending on markets and market practices in
            combination with IMB token holdings, users can expect to pay fees as
            low as 1% only.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Users may inquire about additional convenience services for
            additional fees. Some of the services are not provided by Immobilium
            but by reputable third party service providers.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>8. How can I upload property?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Once you have completed KYC and you enter your personal dashboard on
            the right hand side you will see the "Add Property" button. Clicking
            that button will initiate the procedure for property uploading.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Please make sure you are uploading as much information about the
            property as possible. The more information about the property you
            show the higher the chances of a buyer selecting your property for
            purchase.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Be aware that buyers from Immobilium do not visit property before
            the actual purchase so the information presented will have to be as
            detailed as possible and verified.{" "}
            <strong>Unless you are an agent</strong> please note that your
            property and the documentation will need to be verified by one of
            our colleagues physically to make sure the property you are listing
            is actually real and has all the documents you are uploading.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            If you require assistance to obtain{" "}
            <strong>
              valuation reports, inspection reports, energy efficiency reports,
              professional photos or floorplans
            </strong>{" "}
            - feel free to reach out to us for assistance.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Please note that to upload properties you will need to utilize 20
            IMB tokens. If you are not an agent and have not obtained the tokens
            through promotion - please reach out to IMB team on{" "}
            <a href="https://www.imbcoin.io" target="_blank">
              www.imbcoin.io
            </a>
            .
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>9. What are $IMB tokens?</strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium tokens are utility tokens that allow users to benefit
            from the platform. They allow users to upload properties, reduce
            fees on platform based services, access off market properties and we
            intend to add more utility functions over time. For more information
            visit{" "}
            <a href="https://www.imbcoin.io" target="_blank">
              www.imbcoin.io
            </a>
            .
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              10. Why do I need to assign a Power of Attorney when buying
              property in a foreign country?
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            When buying property in a country which is not your own, your
            physical presence and overseeing of a transaction is not possible
            without someone representing you. Through our network of business
            hubs and local partners we partner with most reputable local law
            offices with personal connections to our hub managers to see through
            the transactions of our buyers. The legal support in foreign country
            will physically represent you in relevant institutions to enable
            you, as party issuing POA to the lawyers, to be the ultimate owner
            of the property you bought.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            The Immobilium team in your home country will instruct, settle the
            fees and prepare you for a visit to the Embassy of the country in
            which you intend to purchase property to ensure proper POA
            authorizations. Once you have given this POA, you can execute
            countless numbers of transactions on that particular market.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
