import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "react-google-autocomplete";
import { generalService } from "../../../_services";

const DefaultPropertyDetailEdit = ({
  property,
  onUpdate,
  onUpdateLocation,
}) => {
  const [available_dividend_distributions, setAvailableDividendDistributions] =
    useState([]);
  const [available_financial_instruments, setAvailableFinancialInstruments] =
    useState([]);
  const [available_locations, setAvailableLocation] = useState([]);
  const [receivedData, setReceivedData] = useState(false);
  const prevPropertyRef = useRef();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      const { status: finance_status, data: finanaces } =
        await generalService.getFinancialInstruments();
      finance_status &&
        isMounted &&
        setAvailableFinancialInstruments(finanaces);

      const { status: dist_status, data: dists } =
        await generalService.getDividendDistributions();
      dist_status && isMounted && setAvailableDividendDistributions(dists);

      const { status: location_status, data: locations } =
        await generalService.getAvailableLocations();
      location_status && isMounted && setAvailableLocation(locations);

      setReceivedData(!receivedData);
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    prevPropertyRef.current = property;
  }, [property]);

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    onUpdate({
      [fieldName]: fieldValue,
    });
  };

  const onPlaceSelected = (place) => {
    function handleSubTypes(field) {
      var fieldKey = field.types.find(
        (subType) => subType === "postal_code" || subType === "country"
      );
      return {
        field_name: fieldKey,
        field_value: field.long_name,
      };
    }

    var fields =
      place && place.address_components
        ? place.address_components
            .filter((c) =>
              c.types.some((sc) => sc === "postal_code" || sc === "country")
            )
            .map(handleSubTypes)
        : [];
    var postalCodeObj = fields.find((f) => f.field_name === "postal_code");
    var countryObj = fields.find((f) => f.field_name === "country");
    var postal_code =
      postalCodeObj && postalCodeObj.field_value
        ? postalCodeObj.field_value
        : "";
    var country =
      countryObj && countryObj.field_value ? countryObj.field_value : "";
    var vicinity = place.vicinity;

    onUpdate({
      address_postcode: postal_code,
      address_line1: vicinity || "",
      address_country: country,
    });

    if (place && place.geometry && place.geometry.location) {
      var location_cords = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };

      onUpdateLocation && onUpdateLocation(location_cords);
    }
  };

  const handleAddressCityChange = (event) => {
    var fieldName = event.target.name,
      cityName = event.target.value;
    var city = available_locations.find((l) => l.city_name === cityName);
    if (city) {
      var cityValue = city.city_name;
      onUpdate({
        [fieldName]: cityValue,
        macrolocation_heading_en: city.location_heading["en"],
        macrolocation_description_en: city.location_description["en"],
      });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.title}
            onChange={handleChange}
            id="text-name"
            name="title"
            className="form-control"
            label="Property Title *"
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Property type</InputLabel>
            <Select
              id="text-property_category_type"
              name="property_category_type"
              value={property.property_category_type}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="apartment"> Apartment</MenuItem>
              <MenuItem value="apartment house">Apartment House</MenuItem>
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="office space">Office Space</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            label="Token Name *"
            value={property.token_name}
            name="token_name"
            id="text-token_name"
            onChange={handleChange}
            className="form-control"
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <label className="autocomplete-form-label">Address Line</label>
            <Autocomplete
              className="autocomplete-input"
              onPlaceSelected={onPlaceSelected}
              options={{
                fields: [
                  "address_components",
                  "adr_address",
                  "formatted_phone_number",
                  "icon",
                  "name",
                  "photos",
                  "vicinity",
                  "geometry.location",
                  "place_id",
                  "formatted_address",
                ],
                types: ["address"],
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Address City</InputLabel>
            <Select
              name="address_city"
              id="text-address_city"
              value={property.address_city}
              onChange={handleAddressCityChange}
            >
              <MenuItem disabled value={"None"}>
                <em>None</em>
              </MenuItem>
              {available_locations.length > 0 &&
                available_locations.map((location, index) => (
                  <MenuItem key={index} value={location.city_name}>
                    {location.city_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.address_postcode}
            onChange={handleChange}
            id="text-address_postcode"
            name="address_postcode"
            className="form-control"
            label="Address Postcode"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.address_country}
            onChange={handleChange}
            id="text-address_country"
            name="address_country"
            className="form-control"
            label="Address Country"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Financial Instrument</InputLabel>
            <Select
              name="financial_instrument"
              id="text-financial_instrument"
              value={property.financial_instrument}
              onChange={handleChange}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {available_financial_instruments.length > 0 &&
                available_financial_instruments.map((f, index) => (
                  <MenuItem key={index} value={f.id}>
                    {f.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Dividend Distribution</InputLabel>
            <Select
              name="dividend_distribution"
              id="text-dividend_distribution"
              value={property.dividend_distribution}
              onChange={handleChange}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {available_dividend_distributions.length > 0 &&
                available_dividend_distributions.map((d, index) => (
                  <MenuItem key={index} value={d.id}>
                    {d.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultPropertyDetailEdit;
export { DefaultPropertyDetailEdit };
