import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from "../../components/GeneralComponent/Loader";
import { history } from "../../_helpers/history";
import "./styles.scss";

const PropertyData = (props) => {
  var progressClassName =
    props.progress > 50
      ? "funding-progress progress-success"
      : props.progress > 20
      ? "funding-progress progress-info"
      : "funding-progress progress-danger";
  var profitClassName =
    props.profit > 0 ? "profit" : props.profit < 0 ? "loss" : "none";

  function formatPrice(n) {
    var val = Math.abs(Math.round(Number(n) * 100) / 100);
    var parts = val.toString().split(".");
    var num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");
    return num;
  }

  function openProperty() {
    history.push("/property/view/" + props.property_id);
  }

  return (
    <tr className="property-data" onClick={openProperty}>
      <td>
        <img src={props.img} className="property-img" alt="Property" />
      </td>
      <td>
        <p className="txt-property-name">{props.name}</p>
        <p className="txt-investment-title">{props.title}</p>
      </td>
      {props.progress ? (
        <td>
          <LinearProgress
            className={progressClassName}
            variant="determinate"
            value={props.progress}
          />
          <p className="txt-funding-progress">Funding progress</p>
        </td>
      ) : (
        <td>
          <p className="txt-profit-loss">
            <span className={profitClassName}>
              {formatPrice(props.profit)} %
            </span>
          </p>
          <p className="txt-funding-progress">Profit / Loss</p>
        </td>
      )}
    </tr>
  );
};

const DashboardCommittedInvestmentsWidget = (props) => {
  const { committedInvestments } = props;

  return (
    <div className="dashboard-investments-widget">
      {committedInvestments ? (
        <table className="table">
          <tbody>
            {committedInvestments.length > 0 ? (
              committedInvestments.map((investment, index) => {
                var property_id =
                  investment.property_master &&
                  investment.property_master.property_id;
                var isPropertyImages =
                  investment.property_master &&
                  investment.property_master.property_images;
                var propertyImage = isPropertyImages
                  ? investment.property_master.property_images[0].image_path
                  : "https://i.imgur.com/ebxQNvT.png";
                var isPropertyLocation =
                  investment.property_master.address_city &&
                  investment.property_master.address_country;
                var propertyLocation =
                  investment.property_master.address_city +
                  ", " +
                  investment.property_master.address_country;

                return (
                  <PropertyData
                    property_id={property_id}
                    key={index}
                    img={propertyImage}
                    name={
                      isPropertyLocation ? propertyLocation : "Not mentioned"
                    }
                    title={investment.property_master.title}
                    progress={investment.property_funding_percentage}
                  />
                );
              })
            ) : (
              <tr className="center-text">
                <td colSpan="3" className="custom-td">
                  Not any committed investment yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const DashboardLatestInvestmentsWidget = (props) => {
  const { latestInvestments } = props;

  const calculateProfitPercentage = (
    price_per_share,
    market_price_per_share
  ) => {
    var grossProfit = market_price_per_share - price_per_share;
    var prsntg = ((grossProfit / price_per_share) * 100).toFixed(2);
    return prsntg;
  };

  return (
    <div className="dashboard-investments-widget">
      {latestInvestments ? (
        <table className="table">
          <tbody>
            {latestInvestments.length > 0 ? (
              latestInvestments.map((investment, index) => {
                var property_id =
                  investment.property_master &&
                  investment.property_master.property_id;
                var isPropertyImages =
                  investment.property_master &&
                  investment.property_master.property_images;
                var propertyImage = isPropertyImages
                  ? investment.property_master.property_images[0].image_path
                  : "https://i.imgur.com/ebxQNvT.png";
                var isPropertyLocation =
                  investment.property_master.address_city &&
                  investment.property_master.address_country;
                var propertyLocation =
                  investment.property_master.address_city +
                  ", " +
                  investment.property_master.address_country;
                return (
                  <PropertyData
                    property_id={property_id}
                    key={index}
                    img={propertyImage}
                    name={
                      isPropertyLocation ? propertyLocation : "Not mentioned"
                    }
                    title={investment.property_master.title}
                    profit={calculateProfitPercentage(
                      investment.price_per_share,
                      investment.property_master.current_price_per_share
                    )}
                  />
                );
              })
            ) : (
              <tr className="center-text">
                <td colSpan="3" className="custom-td">
                  No latest investments
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export {
  DashboardCommittedInvestmentsWidget,
  DashboardLatestInvestmentsWidget,
};
