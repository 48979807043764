import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ForceWalletComponent from "../components/ForceWalletComponent";
import ScrollToTop from "../_helpers/scrollToTop";
import AdminRoute from "./AdminRoute";
import GuestRoute from "./GuestRoute";
import UserRoute from "./UserRoute";

const AppRouteInner = ({ users, logopath, pagebackground, logobackground }) => {
  const [isAuth, setIsAuth] = useState(!!(users && users.profile_data));
  const [isAdmin, setIsAdmin] = useState(
    !!(users && users.token_data && users.token_data.is_admin)
  );

  useEffect(() => {
    const is_auth = !!(users && users.profile_data);
    const is_admin = !!(users && users.token_data && users.token_data.is_admin);
    setIsAuth(is_auth);
    setIsAdmin(is_admin);
  }, [users]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {!isAuth && (
        <GuestRoute
          logopath={logopath}
          logobackground={logobackground}
          pagebackground={pagebackground}
        />
      )}
      {isAuth && <ForceWalletComponent />}
      {isAuth &&
        (isAdmin ? (
          <AdminRoute
            logopath={logopath}
            logobackground={logobackground}
            pagebackground={pagebackground}
          />
        ) : (
          <UserRoute
            logopath={logopath}
            logobackground={logobackground}
            pagebackground={pagebackground}
          />
        ))}
    </BrowserRouter>
  );
};

const mapState = (state) => {
  const { users } = state;
  return { users };
};

const AppRoute = connect(mapState)(AppRouteInner);

export default AppRoute;
export { AppRoute };
