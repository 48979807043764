import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormControlLabel } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CheckboxValidatorElement from "./../../components/CheckboxValidatorElement";
import RegistrationSuccessModal from "./RegistrationSuccessModal";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { userActions, alertActions } from "../../_actions";
import "./styles.scss";
import arrow_white_icon from "../../assets/images/arrow-right-white-icon.png";

class RegistrationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registrationStatus: false,
      user: {
        first_name: "",
        last_name: "",
        email_id: "",
        password: "",
        confirm_password: "",
        tnc: false,
      },
    };
    this.props.clearAlerts();
  }

  closeSuccessModal = () => {
    this.setState({ registrationStatus: false });
  };

  checkTnc = () => {
    const { user } = this.state;
    user["tnc"] = !user.tnc;
    this.setState({ user });
  };

  handleChange = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  componentDidMount() {
    ValidatorForm.addValidationRule("isTruthy", (value) => value);
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.user.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
    ValidatorForm.removeValidationRule("isTruthy");
  }

  handleSubmit = () => {
    this.props.clearAlerts();

    const { user } = this.state;
    const { first_name, email_id, password } = user;

    if (first_name && email_id && password) {
      this.props.register(user);
    }
  };

  static getDerivedStateFromProps(props, prevState) {
    if (
      props.alert &&
      props.alert.type === "success" &&
      !prevState.registrationStatus
    ) {
      return {
        registrationStatus: true,
      };
    } else {
      return {
        registrationStatus: false,
      };
    }
  }

  render() {
    const { user } = this.state;
    const { alert } = this.props;

    return (
      <div className="registration-hero-banner">
        <div className="registration-box">
          <div className="form-rounded-container">
            <img src={arrow_white_icon} alt="arrow-icon" />
          </div>

          <ValidatorForm
            className="form"
            id="register-login-form"
            onSubmit={this.handleSubmit}
            ref="form"
            autoComplete="off"
          >
            {alert.message && alert.type === "error" && (
              <NotificationBox
                open={true}
                variant={alert.type}
                message={alert.message}
              />
            )}

            <div style={{ margin: "0 52px", marginTop: "20px" }}>
              <TextValidator
                fullWidth
                value={user.first_name}
                onChange={this.handleChange}
                name="first_name"
                className="form-control"
                label="First Name"
                margin="normal"
                variant="outlined"
                validators={["required"]}
                errorMessages={["this field is required"]}
              />

              <TextValidator
                fullWidth
                value={user.last_name}
                onChange={this.handleChange}
                name="last_name"
                className="form-control"
                label="Last Name"
                margin="normal"
                variant="outlined"
              />

              <TextValidator
                fullWidth
                value={user.email_id}
                onChange={this.handleChange}
                name="email_id"
                className="form-control"
                label="Email Address"
                margin="normal"
                variant="outlined"
                validators={["required", "isEmail"]}
                errorMessages={[
                  "this field is required",
                  "Email address is not valid",
                ]}
              />
              <TextValidator
                fullWidth
                autoComplete="off"
                value={user.password}
                onChange={this.handleChange}
                name="password"
                type="password"
                className="form-control"
                label="Password"
                margin="normal"
                variant="outlined"
                validators={["required"]}
                errorMessages={["this field is required"]}
              />

              <TextValidator
                fullWidth
                autoComplete="off"
                value={user.confirm_password}
                onChange={this.handleChange}
                name="confirm_password"
                type="password"
                label="Confirm Password"
                margin="normal"
                variant="outlined"
                validators={["isPasswordMatch", "required"]}
                errorMessages={[
                  "Both Password not match!",
                  "this field is required",
                ]}
              />

              <Button
                type="submit"
                variant="outlined"
                color="default"
                // className="btn-primary btn-filled-out btn-submit"
                style={{
                  backgroundColor: "#2BAAEA",
                  borderRadius: "4px",
                  color: "#fff",
                  border: "none",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  width: "119px",
                  height: "48px",
                  margin: "35px 0",
                }}
              >
                Register
              </Button>
            </div>

            <div
              style={{
                backgroundColor: "#3C4E55",
                color: "#fff",
                display: "flex",
                padding: "20px 20px 16px 52px",
                borderRadius: "0 0 16px 16px",
              }}
            >
              <FormControlLabel
                className="text-left"
                control={
                  <CheckboxValidatorElement
                    onChange={this.checkTnc}
                    name="tnc"
                    color="primary"
                    validators={["isTruthy"]}
                    errorMessages={["this field is required"]}
                    checked={user.tnc}
                    value={user.tnc}
                  />
                }
              />
              <div style={{ textAlign: "left", fontSize: "15px" }}>
                I hereby agree to receive information about the current
                investments and agree to the GTC and the privacy policy.
              </div>
            </div>
          </ValidatorForm>

          <RegistrationSuccessModal
            open={this.state.registrationStatus}
            handleClose={this.closeSuccessModal}
          />
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  register: userActions.register,
};

const connectedComponent = connect(mapState, actionCreators)(RegistrationPage);
export { connectedComponent as RegistrationPage };
