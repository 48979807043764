import React from "react";
import { Dialog, DialogContent, Box, Button } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import "./styles.scss";

const WithdrawInfoModal = ({
  withdrawAmount,
  open,
  handleClose,
  handleBack,
  handleFinish,
}) => {
  const formatAmount = (amount) => {
    amount = amount.split("").reverse().join("");
    if (amount) {
      const digits = amount.length;
      amount = amount.replace(/.{3}/g, "$&,");
      if (digits % 3 === 0) amount = amount.substring(0, amount.length - 1);
    }
    return amount.split("").reverse().join("");
  };

  return (
    <Dialog
      className="deposit-modal wider-modal"
      fullWidth={true}
      maxWidth={"xs"}
      onClose={handleClose}
      open={open}
    >
      <DialogContent>
        <div className="block-header">Withdraw</div>
        <div className="payment-icon">
          <CreditCardOutlinedIcon />
        </div>

        <div className="block-content">
          <p className="txt-status">-{formatAmount(withdrawAmount)} ($)</p>
        </div>

        <div className="block-content">
          <p className="text">
            You are about to withdraw money from your account. Are you sure you
            want to withdraw <strong>${formatAmount(withdrawAmount)}</strong>?
            <br />
            <br />
            Our operators will be notified about your withdraw request and it
            will be processed within 2 buissness days.
            <br />
          </p>
        </div>

        <Box my={1}>
          <Button className="btn-print" onClick={handleBack}>
            Back
          </Button>
          <Button className="btn-print" onClick={handleFinish}>
            Finish withdraw
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WithdrawInfoModal;
