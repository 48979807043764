import React, { Component } from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { Container } from "@material-ui/core";
import { generalActions } from "./_actions";
import "./styles.scss";
import AppRoute from "./routes";
import { HelmetProvider } from 'react-helmet-async';
import MetaComponent from "./components/MetaComponent";

const history = createBrowserHistory({ window });

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });

    this.state = {
      logopath: this.getQueryVariable("logopath"),
      pagebackground: this.getQueryVariable("pagebackground"),
      logobackground: this.getQueryVariable("logobackground"),
    };

    document.body.classList.add(`theme-light`);
  }

  componentDidMount() {
    const fetchCoinRates = async () => {
      this.props.updateCoinRates();
      this.props.updateCurrencyRates();
    };
    fetchCoinRates();
    const newIntervalId = setInterval(
      fetchCoinRates,
      process.env.REACT_APP_REFRESH_COIN_INTERVAL
    );
    this.setState((prevState) => ({
      ...prevState,
      intervalId: newIntervalId,
    }));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.users && props.users.profile_data) {
      const theme = props.users.profile_data.theme || "light";
      const themeClass = `theme-${theme}`;
      document.body.classList.forEach((value) => {
        if (value.startsWith("theme-")) {
          document.body.classList.remove(value);
        }
      });
      document.body.classList.add(themeClass);
    }

    return state;
  }

  getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  render() {
    return (
      <HelmetProvider>
        <MetaComponent />
        <Container maxWidth={false} className="app-root no-padding">
          <AppRoute
            logopath={this.state.logopath}
            pagebackground={this.state.pagebackground}
            logobackground={this.state.logobackground}
          />
        </Container>
      </HelmetProvider>
    );
  }
}

function mapState(state) {
  const { users } = state;
  return { users };
}

const actionCreators = {
  updateCoinRates: generalActions.updateCoinRates,
  updateCurrencyRates: generalActions.updateCurrencyRates,
};

export default connect(mapState, actionCreators)(App);
