import React from 'react'
import { Button } from "@material-ui/core";
import YachtBuyImg from '../../assets/images/home-buy.jpg'
import "./styles.scss"

const YachtSection = () => {
  return (
    <section className='yachts-section'>
      <div className='yacht-section-container'>
        <div className='yacht-section-row'>
          <div className='yacht-section-col content-col'>
            <div className='yacht-section-content'>
              <h3>NAVIGATE OUR FLEET</h3>
              <p>Search and explore our luxury yachts</p>
              <Button onClick={() => window.location.href = "/yachts-for-sale"} className='show-more-btn'>Explore</Button>
            </div>
          </div>
          <div className='yacht-section-col'>
            <img src={YachtBuyImg} alt="yachts buy" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default YachtSection