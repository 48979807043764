import React from "react";
import "./styles.scss";

const OverviewWidgetBlock1 = (props) => {
  function formatPrice(n) {
    var val = Math.round(Number(n) * 100) / 100;
    var parts = val.toString().split(".");
    var num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");
    return num;
  }

  const { type } = props;

  return (
    <div className="overview-widget-block-1">
      <div className="value-block">
        {type === 1 && (
          <>
            <p className="txt-value widget-block-2">
              <span>&#36; {formatPrice(props.value)}</span>
              <span
                className={
                  props.percentage > 0
                    ? "txt-percentage percentage-profit"
                    : " txt-percentage percentage-loss"
                }
              >
                {formatPrice(props.percentage)}
              </span>
            </p>
            <p className="txt-title widget-block-2">
              <span>{props.block_text}</span>
              <span
                className={
                  props.percentage_value > 0
                    ? "txt-portfolio-value portfolio-profit"
                    : "txt-portfolio-value portfolio-loss"
                }
              >
                {formatPrice(props.percentage_value)}
              </span>
            </p>
          </>
        )}
        {type === 2 && (
          <>
            <p className="txt-value widget-block-2">
              <span>&#36; {formatPrice(props.value)}</span>
              <span>{formatPrice(props.percentage)} %</span>
            </p>
            <p className="txt-title widget-block-2">
              <span>{props.block_text}</span>
              <span>{props.block_text_2}</span>
            </p>
          </>
        )}
        {type === 3 && (
          <>
            <p className="txt-value">
              &#36; {formatPrice(props.value)}{" "}
              <span className="profit-loss-percentage">
                {formatPrice(props.percentage)} %
              </span>
            </p>
            <p className="txt-title">{props.block_text}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default OverviewWidgetBlock1;
