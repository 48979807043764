import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import Lottie from "react-lottie";
import createLottieOptions from "../_helpers/lottieOptions.js";
import chatGpt from "../assets/animations/chat-gpt.json";

const ChatGptParagraph = ({ title, text }) => {
  const chatGptOptions = createLottieOptions(chatGpt);
  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box className="lottie-gpt-container">
            <Lottie options={chatGptOptions} height={200} width={200} />
          </Box>
          <Box className="chat-gpt-text">
            <Typography
              variant="h5"
              paragraph={true}
              className="chat-gpt-title"
            >
              We Have Asked
              <br />
              ChatGPT
            </Typography>
            <Typography
              variant="body1"
              paragraph={true}
              className="chat-gpt-subtitle"
            >
              to explain {title} to
              <br />
              five-year-old
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="chat-gpt-p">
          <Typography variant="body1" paragraph={true} align="justify">
            "{text}"
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatGptParagraph;
