import React, { Component } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../../_actions";
import NotificationBox from "../../../components/GeneralComponent/NotificationBox";
import "./styles.scss";

class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        security_questions: [],
      },
      formData: {
        user_id: 0,
        current_password: "",
        new_password: "",
        security_questions: {
          q1: {
            id: "",
            answer: "",
          },
          q2: {
            id: "",
            answer: "",
          },
        },
        update_form_type: "security",
      },
    };
  }

  componentDidMount() {
    this.props.getSecurityQuestion();
  }

  static getDerivedStateFromProps(props, state) {
    const { profile_data, security_questions_data } = props.users;
    if (profile_data && profile_data.user_id !== state.formData.user_id) {
      return {
        formData: {
          ...state.formData,
          user_id: profile_data.user_id,
          security_questions: profile_data.security_questions,
        },
      };
    }
    if (security_questions_data && security_questions_data.length > 0) {
      return {
        user: {
          ...state.user,
          security_questions: security_questions_data,
        },
      };
    }
    return null;
  }

  chunkSecurityDataArray = (myArray, chunk_size) => {
    if (myArray.length > 0) {
      var index = 0,
        arrayLength = myArray.length,
        name_index = 0;
      var chunk_names = ["primary_security_qty", "secondary_security_qty"];
      var securityChunkObj = {};
      for (index = 0; index < arrayLength; index += chunk_size) {
        var myChunk = myArray.slice(index, index + chunk_size);
        securityChunkObj[chunk_names[name_index]] = myChunk;
        name_index++;
      }
      return securityChunkObj;
    }
    return [];
  };

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleSecurityQtyChanges = (event) => {
    var name_array = event.target.name.split(".");
    var parentObj = JSON.parse(JSON.stringify(name_array[0]));
    var objItem = JSON.parse(JSON.stringify(name_array[1]));
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        security_questions: {
          ...prevState.formData.security_questions,
          [parentObj]: {
            ...prevState.formData.security_questions[parentObj],
            [objItem]: fieldValue,
          },
        },
      },
    }));
  };

  handleUpdateSecurity = () => {
    this.props.updateUserProfile(this.state.formData).then((value) => {
      setTimeout(() => this.props.clearAlerts(), 1000);
    });
  };

  render() {
    const { user, formData } = this.state;
    const { alert } = this.props;

    const qty_array =
      user && user.security_questions.length > 0 ? user.security_questions : [];
    var securityChunkedData = this.chunkSecurityDataArray(
      qty_array,
      qty_array.length / 2
    );

    return (
      <div className="security-tab">
        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}
        <p className="page-block-title">Security</p>

        <div className="user-profile-form">
          <form>
            <p className="txt-title">Change password</p>

            <hr className="hr-line" />

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <label className="form-label">Current password</label>
                <TextField
                  placeholder="Enter current password"
                  name="current_password"
                  value={formData.current_password}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <label className="form-label">New password</label>
                <TextField
                  placeholder="Enter new password"
                  name="new_password"
                  value={formData.new_password}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* <p className="txt-title mt-3" style={{ display: "none" }}>
              Security questions
            </p>

            <hr className="hr-line" style={{ display: "none" }} />

            <Grid container style={{ display: "none" }} spacing={3}>
              <Grid item md={6} xs={12}>
                <label className="form-label">Question #1</label>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    name="q1.id"
                    value={formData.security_questions.q1.id}
                    onChange={this.handleSecurityQtyChanges}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Please select question
                    </MenuItem>
                    {securityChunkedData.primary_security_qty &&
                      securityChunkedData.primary_security_qty.length > 0 &&
                      securityChunkedData.primary_security_qty.map(
                        (qty, index) => (
                          <MenuItem key={index} value={qty.id}>
                            {qty.question}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <label className="form-label">Answer</label>
                <TextField
                  placeholder="Enter your answer"
                  name="q1.answer"
                  value={formData.security_questions.q1.answer}
                  onChange={this.handleSecurityQtyChanges}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <label className="form-label">Question #2</label>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    name="q2.id"
                    value={formData.security_questions.q2.id}
                    onChange={this.handleSecurityQtyChanges}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Please select question
                    </MenuItem>
                    {securityChunkedData.secondary_security_qty &&
                      securityChunkedData.secondary_security_qty.length > 0 &&
                      securityChunkedData.secondary_security_qty.map(
                        (qty, index) => (
                          <MenuItem key={index} value={qty.id}>
                            {qty.question}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <label className="form-label">Answer</label>
                <TextField
                  placeholder="Enter your answer"
                  name="q2.answer"
                  value={formData.security_questions.q2.answer}
                  onChange={this.handleSecurityQtyChanges}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid> */}

            <div className="form-action">
              <Button
                className="btn btn-primary btn-filled-out"
                onClick={this.handleUpdateSecurity}
                variant={"contained"}
              >
                Update Settings
              </Button>
              <Button className="btn btn-primary" variant={"contained"}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  getSecurityQuestion: userActions.getSecurityQuestion,
  updateUserProfile: userActions.updateUserProfile,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(Security);
