import { Box, Typography, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  highlights: {
    backgroundColor: "#ECF9FF",
    boxShadow: "5px 7px 10px rgba(74, 191, 247, 0.25)",
  },
}));

export const SectionComponent = (props) => {
  const classes = useStyles();

  const boxStyle = {
    px: 3.5,
    py: 8,
  };

  return props.component ? (
    <Box
      sx={boxStyle}
      className={props.sectionTitle === "Highlights" ? classes.highlights : ""}
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" style={{ fontWeight: "800" }}>
          {props.sectionTitle}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2} md={2}>
          <Box
            sx={{ backgroundColor: "#2BAAEA", height: "24px", width: "96px" }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box>{props.component}</Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    ""
  );
};
