import React from "react";
import HeroSection from "./HeroSection";
import IntroSection from "./IntroSection";
// import FooterSection from './FooterSection'
import CountriesSection from "./CountriesSection";
import CounterSection from "./CounterSection";
import TestimonialsSection from "./TestimonialsSection";
// import PromotedPropertiesSections from './PromotedPropertiesSections'
import SearchingForAgentSection from "./SearchingForAgentSection";
import PromotedPropertiesSlider from "./NewPromotedPropertiesSlider";
import YachtSection from "./YachtSection";

const NewHomePage = () => {
  return (
    <>
      <HeroSection />
      <IntroSection />
      {/* <PromotedPropertiesSections /> */}
      <PromotedPropertiesSlider />
      <YachtSection />
      <CounterSection />
      <SearchingForAgentSection />
      {/* <TestimonialsSection /> */}
      <CountriesSection />
    </>
  );
};

export default NewHomePage;
