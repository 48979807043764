import React, { Component } from "react";
import { Dialog, DialogContent, Button, Grid } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import { userActions } from "../../../_actions";
import { connect } from "react-redux";
import "./styles.scss";

class TokensModal extends Component {
  constructor(props) {
    super(props);
  }

  adminClaimTokens = async () => {
    const wallet_address = this.props.selectedUser.wallet_address;
    await this.props.claimTokens({ wallet_address, is_admin: true });
    this.props.handleClose();
  };

  render() {
    return (
      <Dialog
        className="deposit-modal"
        fullWidth={true}
        maxWidth={"xs"}
        onClose={this.props.handleClose}
        open={this.props.open}
      >
        <DialogContent>
          <div className="block-header">Send Tokens</div>
          <div className="payment-icon">
            <CreditCardOutlinedIcon />
          </div>

          <div className="block-content">
            <p className="txt-type">
              You are about to send 300 IMB coins
              <br />
              and 1 ZBS coin to {this.props.selectedUser.first_name}{" "}
              {this.props.selectedUser.last_name}
            </p>
          </div>
          <Grid
            container
            justifyContent="space-between"
            className="token-modal-buttons"
          >
            <Grid item>
              <Button
                type="button"
                className="btn-print"
                onClick={this.props.handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                className="btn-print"
                onClick={this.adminClaimTokens}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  claimTokens: userActions.claimTokensRequest,
};

export default connect(mapState, actionCreators)(TokensModal);

// export default TokensModal;
