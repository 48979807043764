import { Fragment, useEffect, useRef, useState } from "react"
import ShareOutlined from "@material-ui/icons/ShareOutlined";
import { Facebook, Twitter, Email, FileCopy } from "@material-ui/icons";
import { Avatar, Collapse, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import './style.scss'

const ShareButton = (props) => {

    const { title, text } = props;

    const [url, setUrl] = useState();
    const [open, setOpen] = useState(false);
    const [copy, setCopy] = useState(false);

    const copyTimeout = useRef(null);

    const shareFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
    }

    const shareTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?url=${url}&text=${title}`, '_blank');
    }

    const shareEmail = () => {
        window.open(`mailto:?subject=${title}&body=${text} ${url}`, '_blank');
    }

    const shareCopy = () => {
        navigator.clipboard.writeText(url);
        setCopy(true);
        copyTimeout.current = setTimeout(() => {
            setCopy(false);
        }, 2000);
    }

    useEffect(() => {
        const url = window.location.href;
        setUrl(url);

        return () => {
            if (copyTimeout.current) {
                clearTimeout(copyTimeout.current);
            }
        }
    }, []);



    return (<Fragment>
        <div className="share-button">
            <div className="share-button__bnt" onClick={() => setOpen(!open)}><ShareOutlined /> <span>Share this property</span></div>
        </div>
      
        <Collapse in={open}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem className="share-button__item" onClick={shareFacebook}>
                    <ListItemAvatar>
                        <Avatar>
                            <Facebook />
                        </Avatar> 
                    </ListItemAvatar>
                    <ListItemText primary="Facebook" secondary="Share property on Facebook" />
                </ListItem>
                <ListItem className="share-button__item" onClick={shareTwitter}>
                    <ListItemAvatar>
                        <Avatar>
                            <Twitter />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Twitter" secondary="Share propery on Twitter" />
                </ListItem>
                <ListItem className="share-button__item" onClick={shareEmail}>
                    <ListItemAvatar>
                        <Avatar>
                            <Email />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Email" secondary="Send property with email" />
                </ListItem>
                <ListItem className="share-button__item" onClick={shareCopy}>
                    <ListItemAvatar>
                        <Avatar>
                            <FileCopy />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Copy" secondary={copy ? "Copied" : "Copy property url"} />
                </ListItem>
            </List>
        </Collapse>

    </Fragment>
    )
}


export default ShareButton