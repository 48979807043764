import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Typography,
  Divider,
  Tooltip,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import "./styles.scss";

const TokenPayModal = ({
  action,
  propertyTitle,
  content,
  immoTokenBalance,
  payAmount,
  open,
  handleClose,
  handleFinish,
  isOpen,
}) => {
  const formatAmount = (amount) => {
    const decimal = amount.split(".")[1];
    amount = amount.split(".")[0];
    amount = amount.split("").reverse().join("");
    if (amount) {
      const digits = amount.length;
      amount = amount.replace(/.{3}/g, "$&,");
      if (digits % 3 === 0) amount = amount.substring(0, amount.length - 1);
    }
    let res = amount.split("").reverse().join("");
    if (decimal) {
      res += `.${decimal}`;
    }
    return res;
  };

  const getConfirmButton = () => {
    if (immoTokenBalance - payAmount < 0) {
      return (
        <Tooltip title={`You don't have enough tokens!`} arrow>
          <Button className="btn-print">{action}</Button>
        </Tooltip>
      );
    } else {
      return (
        <Button className="btn-print" onClick={handleFinish} disabled={!isOpen}>
          {action}
        </Button>
      );
    }
  };

  return (
    <Dialog
      className="token-pay-modal wider-modal"
      fullWidth={true}
      maxWidth={"xs"}
      onClose={handleClose}
      open={open}
    >
      <DialogContent>
        <Box className="block-header">{action.toUpperCase()}</Box>
        <Box className="payment-icon">
          <StarIcon />
        </Box>

        <Box className="block-content">
          <Box sx={{ mb: 0.5, display: "flex", justifyContent: "center" }}>
            <Typography variant="h5">
              <strong>{propertyTitle}</strong>
            </Typography>
          </Box>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
            <Typography className="text" variant="button">
              {content}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
            <Typography>Your current balance</Typography>
            <Typography>
              <strong>{formatAmount(`${immoTokenBalance}`)} immo tokens</strong>
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
            <Typography>{action.toUpperCase()} property cost</Typography>
            <Typography className="txt-cost">
              <strong>- {formatAmount(`${payAmount}`)} immo tokens</strong>
            </Typography>
          </Box>
          <Divider />
          <Divider />
          <Divider />
          <Box sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
            <Typography>Your balance after this transaction</Typography>
            <Typography>
              <strong>
                {immoTokenBalance - payAmount >= 0
                  ? formatAmount(`${immoTokenBalance - payAmount}`)
                  : 0}{" "}
                immo tokens
              </strong>
            </Typography>
          </Box>
          <Divider />
        </Box>

        <Box
          my={1}
          mr={3}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button className="btn-print" onClick={handleClose}>
            Close
          </Button>
          {getConfirmButton()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TokenPayModal;
