import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { TabPanel } from "../../components/GeneralComponent/LanguageTabs";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { locationActions, alertActions } from "../../_actions";
import { withRouter } from "../../_helpers";
import countries from "../../data/countries";
import SelectValidator from "../../components/SelectValidatorElement";
import Editor from "../../components/Editor";

class AddLocationPage extends Component {
  constructor() {
    super();

    this.state = {
      location_heading_tabindex: 0,
      location_description_tabindex: 0,
      location: {
        country: "",
        city_name: "",
        heading_en: "",
        description_en: "",
      },
    };
  }

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleLocationTabChange = (locationInputName) => (event, newValue) => {
    this.setState({
      [locationInputName]: newValue,
    });
  };

  handleLocationDescEditorChange = (event) => {
    var fieldName = event.target.id.split("-")[1];
    var fieldValue = event.target.getContent();
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        [fieldName]: fieldValue,
      },
    }));
  };

  submit = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.props.addLocation(this.state.location).then((value) => {
          setTimeout(() => {
            this.props.clearAlerts();
            this.props.navigate("/dashboard/locations");
          }, 2000);
        });
      }
    });
  };

  handleSubmit = () => {
    this.form.submit();
  };

  handleLocationDescEditorChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        [fieldName]: fieldValue,
      },
    }));
  };

  render() {
    const {
      location_heading_tabindex,
      location_description_tabindex,
      location,
    } = this.state;
    const { alert } = this.props;

    return (
      <div className="main-content add-location-page">
        <DasboardNavBarComponent pageName="manage-location" />
        <Card>
          <CardContent>
            {alert.message && (
              <NotificationBox
                open={true}
                variant={alert.type}
                message={alert.message}
              />
            )}
            <ValidatorForm
              className="default-form location-form add-location-form"
              onSubmit={this.handleSubmit}
              ref={(r) => {
                this.form = r;
              }}
              autoComplete="off"
              instantValidate
            >
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextValidator
                    fullWidth
                    value={location.city_name}
                    onChange={this.handleChange}
                    id="text-name"
                    name="city_name"
                    className="form-control"
                    label="Location City *"
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth required>
                    <SelectValidator
                      name="country"
                      value={location.country}
                      onChange={this.handleChange}
                      fullWidth
                      displayEmpty
                      className="form-control country-select"
                      validators={["required"]}
                      errorMessages={"Country is required!"}
                    >
                      <MenuItem value="" disabled>
                        Country *
                      </MenuItem>
                      {countries.length > 0 &&
                        countries.map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TabPanel value={location_heading_tabindex} index={0}>
                    <Grid item md={6} xs={12}>
                      <TextValidator
                        fullWidth
                        value={location.heading_en}
                        onChange={this.handleChange}
                        id="text-heading_en"
                        name="heading_en"
                        className="form-control"
                        label="Location Heading"
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={location_heading_tabindex} index={1}>
                    <Grid item md={6} xs={12}>
                      <TextValidator
                        fullWidth
                        value={location.heading_de}
                        onChange={this.handleChange}
                        id="text-heading_de"
                        name="heading_de"
                        className="form-control"
                        label="Location Heading DE"
                      />
                    </Grid>
                  </TabPanel>
                </Grid>
                <Grid item md={12} xs={12}>
                  <p className="location-description-lable">
                    Location Description
                  </p>
                  <TabPanel value={location_description_tabindex} index={0}>
                    <Editor
                      label="Description *"
                      name="description_en"
                      id="text-description_en"
                      value={location.description_en}
                      onChange={this.handleLocationDescEditorChange}
                      plugins="autolink link image lists preview"
                      toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                      height={250}
                    />
                  </TabPanel>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.submit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </CardContent>
        </Card>
      </div>
    );
  }
}
function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  addLocation: locationActions.addLocation,
};

const connectedPage = withRouter(
  connect(mapState, actionCreators)(AddLocationPage)
);
export { connectedPage as AddLocationPage };
