import React, { createContext, useState } from "react";

export const ConfirmContext = createContext([]);

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: "",
    isOpen: false,
    proceed: undefined,
    cancel: undefined,

    isLoading: false,
    waitingMessage: "",
  });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  );
};

export { ConfirmContextProvider };
export default ConfirmContextProvider;
