import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const PropertyCardSkeleton = ({index}) => {
  return (
    <Box style={{padding: '0 16px'}} key={index} width={286}>
      <Skeleton variant="rect" width={286} height={200} />
      <Box pt={0.5}>
        <Skeleton width="40%" />
        <Skeleton width="40%" />
        <Skeleton />
        <Skeleton />
        <Skeleton height="80px" />
      </Box>
    </Box>
  )
}

export default PropertyCardSkeleton