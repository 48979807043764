import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import "../styles.scss";

class ConfirmModal extends Component {
  render() {
    const { open } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="modal-confirm">
            <Typography
              align="left"
              color="initial"
              variant="h5"
              className="modal-confirm-title"
            >
              Forgot password
            </Typography>

            <Typography
              align="left"
              color="initial"
              variant="body1"
              className="modal-confirm-content"
            >
              Send reset password link to this email address: <br />
              <strong>{this.props.email}</strong>
            </Typography>

            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              className="bttn-group"
            >
              <Button
                className="bttn bttn-cancel"
                onClick={this.props.handleClose}
              >
                Cancel
              </Button>
              <Button
                className="bttn bttn-confirm"
                onClick={this.props.handleConfirm}
              >
                Confirm
              </Button>
            </ButtonGroup>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmModal;
