import React, { useEffect, useState } from "react";
import DefaultPropertyDetailEdit from "./DefaultPropertyDetailEdit";
import DirectPropertyDetailEdit from "./DirectPropertyDetailEdit";

const PropertyDetailEdit = ({ property, onUpdate, onUpdateLocation }) => {
  const [propertyType, setPropertyType] = useState("direct"); // used to be "primary"
  const editView = {
    direct: DirectPropertyDetailEdit,
  };

  useEffect(() => {
    setPropertyType(property.property_type);
  }, [property]);

  const View = editView[propertyType] || DefaultPropertyDetailEdit;
  return (
    <View
      property={property}
      onUpdate={onUpdate}
      onUpdateLocation={onUpdateLocation}
    />
  );
};

export default PropertyDetailEdit;
export { PropertyDetailEdit };
