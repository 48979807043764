import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  Divider,
  TablePagination,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Input,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { parseISO, addDays, differenceInDays } from "date-fns";
import { connect } from "react-redux";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import DeleteModal from "../../components/DeleteModal";
import TransferPropertyModal from "../../components/DashboardComponent/modals/TransferPropertyModal";
import TokenPayModal from "../../components/DashboardComponent/modals/TokenPayModal";
import {
  generalService,
  secureStorage,
  userManageService,
  blockchainService,
} from "../../_services";

import "./styles.scss";
import { withRouter } from "../../_helpers";

class ManagePropertyPage extends Component {
  constructor(props) {
    super(props);
    this.searchParams = new URLSearchParams(window.location.search);

    this.state = {
      transferModalStatus: false,
      promoteModalStatus: false,
      deleteModalStatus: false,
      selectedProperty: {},
      immoTokenBalance: undefined,
      zbsTokenBalance: undefined,
      propertyPricing: {},
      modalAction: "promote",
      totalData: 0,
      properties: [],
      showPending: false,
      showPromoted: false,
      paymentRefNumber: "",
      transferPropId: 0,
      users: {
        owner: {},
        buyer: {},
      },
      page: {
        size: 10,
        page: 0,
        count: 0,
      },
      search: {
        addressCities: [],
        addressCountries: [],
        propertyTitle: "",
      },
      availableCities: [],
      availableCountries: [],
    };
  }

  async componentDidMount() {
    const searchObj = {};
    for (const [key, value] of this.searchParams.entries()) {
      try {
        searchObj[key] = JSON.parse(value);
      } catch (e) {
        searchObj[key] = value;
      }
    }

    const balanceObj = await blockchainService.getBalances();
    this.setState((prevState) => {
      return {
        search: { ...prevState.search, ...searchObj },
        immoTokenBalance: balanceObj.immo,
        zbsTokenBalance: balanceObj.zbs,
      };
    });
    this.props.getPropertyPricing(this.state.modalAction);
    const userLocations = true;
    // because of this line Loading countries
    this.props.getAllPropertyActiveLocations(userLocations);
    this.loadProperties();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.search.addressCountries.length !==
        this.state.search.addressCountries.length ||
      JSON.stringify(prevProps.general.activePropertyLocations) !==
        JSON.stringify(this.props.general.activePropertyLocations)
    ) {
      this.getAvailableCities();
    }
  }

  getAvailableCities() {
    const selectedCountries = [...this.state.search.addressCountries];
    const availableCities = [];
    if (selectedCountries.length > 0) {
      Object.keys(this.props.general.activePropertyLocations).length > 0
        ? selectedCountries.forEach((country) =>
            this.props.general.activePropertyLocations[country].forEach(
              (countryLocation) => {
                availableCities.push({
                  locationName: countryLocation,
                  locationCountry: country,
                });
              }
            )
          )
        : "";
    }
    this.setState({ availableCities });
  }

  static getDerivedStateFromProps(props, state) {
    const { dashboard_properties: properties, propertyPricing } = props.general;
    if (
      propertyPricing &&
      propertyPricing.pricing.amount !== state.propertyPricing.amount
    ) {
      return {
        propertyPricing: { ...propertyPricing.pricing },
      };
    }
    if (
      Object.keys(props.general.activePropertyLocations).length > 0 &&
      state.availableCountries.length === 0
    ) {
      const { activePropertyLocations } = props.general;
      const availableCountries = [];
      Object.keys(activePropertyLocations).forEach((country) => {
        availableCountries.push(country);
      });
      availableCountries.sort();
      return { availableCountries };
    }
    if (properties && properties.status === true) {
      return {
        properties: properties.data,
        totalData: properties.total,
        page: {
          ...state.page,
          count:
            (properties.pagination && properties.pagination.count) ||
            properties.total,
        },
      };
    } else if (properties && !properties.status) {
      return {
        properties: [],
        totalData: 0,
      };
    }
    return null;
  }

  loadProperties = () => {
    this.props.clearAlerts();
    this.props.getAllProperties(
      this.state.page.page,
      this.state.page.size,
      undefined,
      this.state.search
    );

    const newParams = new URLSearchParams();
    const { search: searchObj } = this.state;

    Object.keys(searchObj).forEach((key) => {
      const value = searchObj[key];
      if (typeof value === "object" && value !== null) {
        if (value.length > 0) {
          newParams.append(key, JSON.stringify(value));
        }
      } else if (value) {
        newParams.append(key, value);
      }
    });

    if (newParams.size) {
      window.history.pushState({}, "", `?${newParams.toString()}`);
    }
  };

  getPropertyById = (propertyId) => {
    const { properties } = this.state;
    return properties.find((property) => property.property_id === propertyId);
  };

  handlePageChange = (page) => {
    this.setState(
      {
        ...this.state,
        page,
      },
      () => this.loadProperties()
    );
  };

  handlePropertyPromote = async () => {
    this.setState({ promoteModalStatus: false });
    const res = await blockchainService.spendTokens(
      this.state.propertyPricing.amount
    );
    const data = {
      property_id: this.state.selectedProperty.property_id,
      is_promoted: 1,
      promoted_transaction_id: res.id,
      promoted_date: new Date(res.timestamp),
    };
    await this.props.updatePropertyValues(data);
    this.loadProperties();
  };

  handlePropertyDemote = async (propertyId) => {
    const data = {
      property_id: propertyId,
      is_promoted: 0,
      promoted_transaction_id: null,
      promoted_date: null,
    };
    await this.props.updatePropertyValues(data);
    this.loadProperties();
  };

  handlePropertyRemove = () => {
    this.props
      .deletePropertyById(this.state.selectedProperty.property_id)
      .then((value) => {
        this.closeDeleteModal();
        this.loadProperties();
        setTimeout(() => {
          this.props.clearAlerts();
        }, 2000);
      });
  };

  handlePropertyStatus = (id) => {
    const { properties } = this.state;
    let property = properties.find((property) => property.property_id === id);

    const data = {
      property_id: id,
      status: property["status"] === "active" ? "inactive" : "active",
    };

    this.props.updatePropertyValues(data).then((value) => {
      this.loadProperties();
    });
  };

  handlePropertySold = async (id) => {
    const { properties } = this.state;
    let property = properties.find((property) => property.property_id === id);

    const data = {
      property_id: id,
      is_sold: !property["is_sold"],
    };

    await this.props.updatePropertyValues(data);
    this.loadProperties();
  };

  openPromoteModal = async (propertyId) => {
    const balanceObj = await blockchainService.getBalances();
    this.setState({
      promoteModalStatus: true,
      selectedProperty: this.getPropertyById(propertyId),
      immoTokenBalance: balanceObj.immo,
      zbsTokenBalance: balanceObj.zbs,
    });
  };

  openDeleteModal = (propertyId) => {
    this.setState({
      deleteModalStatus: true,
      selectedProperty: this.getPropertyById(propertyId),
    });
  };

  getUser = async (id) => {
    let { error, data } = await userManageService.getUser(id);
    return data;
  };

  openTransferModal = async (property) => {
    const {
      _user_id: ownerId,
      _buyer_id: buyerId,
      property_id: transferPropId,
      purchase_ref_number: paymentRefNumber,
    } = property;

    if (ownerId && buyerId && paymentRefNumber) {
      const owner = await this.getUser(ownerId);
      const buyer = await this.getUser(buyerId);

      this.setState({
        paymentRefNumber,
        transferPropId,
        transferModalStatus: true,
        users: {
          owner,
          buyer,
        },
      });
    }
  };

  closeTransferModal = () => {
    this.setState({
      paymentRefNum: "",
      transferModalStatus: false,
      users: { owner: {}, buyer: {} },
    });
    this.loadProperties();
  };

  handleFinishTransferModal = async () => {
    await generalService.transferProperty({
      property_id: this.state.transferPropId,
      _user_id: this.state.users.buyer.user_id,
      _buyer_id: null,
      purchase_ref_number: null,
      status: "inactive",
      is_promoted: 0,
      promoted_transaction_id: null,
      promoted_date: null,
    });
    this.closeTransferModal();
  };

  handleRejectTransferModal = async () => {
    await generalService.transferProperty({
      property_id: this.state.transferPropId,
      _buyer_id: null,
      purchase_ref_number: null,
      status: "active",
    });
    this.closeTransferModal();
  };

  closePromoteModal = () => {
    this.setState({ promoteModalStatus: false });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModalStatus: false });
  };

  handlePendingCheckboxChange = (e) => {
    if (e.target.checked) {
      this.props.getPurchasePendingProperties();
    } else {
      this.props.getAllProperties();
    }
    this.setState({ showPending: e.target.checked });
  };

  handlePromotedCheckboxChange = (e) => {
    if (e.target.checked) {
      this.resetFilter();
      this.props.getPromotedProperties();
    } else {
      this.props.getAllProperties();
    }
    this.setState({ showPromoted: e.target.checked });
  };

  getPropertyRowClass = (status, draft = false) => {
    if (draft) return "draft-property";
    let className = "";
    switch (status) {
      case "inactive":
        className = "inactive-property";
        break;
      case "pending-escrow":
        className = "pending-escrow-property";
        break;
      case "pending-utrust":
        className = "pending-utrust-property";
        break;
    }
    return className;
  };

  isNumber = (num) => {
    return !isNaN(String(num));
  };

  handleChange = (event) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [event.target.name]: event.target.value,
      },
    }));
  };

  resetFilter = () => {
    this.setState((prevState) => ({
      search: {
        addressCities: [],
        addressCountries: [],
        propertyTitle: "",
      },
    }));
    this.props.navigate("/dashboard/properties");
  };

  getStyles = (city, cities) => {
    return {
      fontWeight: cities.indexOf(city) === -1 ? 500 : 800,
      backgroundColor: cities.indexOf(city) === -1 ? "" : "#c9c9c9",
    };
  };

  calculateRemainingPromotionDays = (startDate, durationDays = 30) => {
    const start = parseISO(startDate);
    const end = addDays(start, durationDays);
    const now = new Date();
    const remainingDays = differenceInDays(end, now);

    return remainingDays > 0 ? remainingDays : 0; // Ensure no negative days
  };

  render() {
    const { alert } = this.props;
    const { properties, page, search, availableCities, availableCountries } =
      this.state;
    return (
      <div className="main-content manage-property-page">
        <DasboardNavBarComponent pageName="manage-property" />
        <Box mb={3}>
          <Link
            to={
              this.isNumber(this.state.immoTokenBalance) &&
              this.state.immoTokenBalance >= 20
                ? "/dashboard/properties/add"
                : ""
            }
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !this.isNumber(this.state.immoTokenBalance) ||
                this.state.immoTokenBalance < 20
              }
            >
              Upload Property
            </Button>
          </Link>
          {this.isNumber(this.state.immoTokenBalance) ? (
            <Box sx={{ mt: 1, ml: 1, color: "#949292" }}>
              Note: You will need 20 IMB tokens to upload property{" "}
            </Box>
          ) : (
            <Box sx={{ mt: 1, ml: 1 }}>
              <Typography variant="body2" className="error">
                *Your token wallet is not linked properly!
              </Typography>
              <Typography variant="body2" className="error">
                *Try to unlink and import it again in tab{" "}
                <Link to="/dashboard/tokenwallet">Token Wallet</Link>
              </Typography>
            </Box>
          )}
        </Box>
        <Box ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showPending}
                onChange={this.handlePendingCheckboxChange}
                name="showPending"
              />
            }
            label="Show purchase pending properties"
          />
        </Box>
        <Box mb={1} ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showPromoted}
                onChange={this.handlePromotedCheckboxChange}
                name="showPromoted"
              />
            }
            label="Show only promoted properties"
          />
        </Box>

        <Card style={{ marginBottom: "15px" }}>
          <CardContent>
            <Box className="manage-property-filters">
              <Box className="manage-property-filter">
                <Typography
                  style={{
                    display: "block",
                    padding: 0,
                    marginBottom: "5px",
                    fontWeight: 800,
                  }}
                >
                  Address Country
                </Typography>
                <Select
                  className="form-control"
                  multiple
                  inputProps={{
                    name: "addressCountries",
                    id: "search-country",
                  }}
                  disableUnderline
                  MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                  value={search.addressCountries}
                  onChange={this.handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    selected = selected.filter((item) =>
                      availableCountries.includes(item)
                    );
                    if (selected.length === 0) {
                      return <span>{"Not selected"}</span>;
                    }
                    return selected.join(", ");
                  }}
                >
                  <MenuItem value="" disabled>
                    {"Not selected"}
                  </MenuItem>
                  {availableCountries.length > 0 &&
                    availableCountries.map((country, index) => (
                      <MenuItem
                        key={index}
                        value={country}
                        style={this.getStyles(country, search.addressCountries)}
                      >
                        {country}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="manage-property-filter">
                <Typography
                  style={{
                    display: "block",
                    padding: 0,
                    marginBottom: "5px",
                    fontWeight: 800,
                    ...(!availableCities.length > 0 && {
                      color: "#b0b0b0",
                    }),
                  }}
                >
                  Address City
                </Typography>
                <Select
                  className="form-control"
                  multiple
                  disabled={!availableCities.length > 0}
                  inputProps={{
                    name: "addressCities",
                    id: "search-city",
                  }}
                  disableUnderline
                  MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                  value={search.addressCities}
                  onChange={this.handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <span
                          style={{
                            ...(!availableCities.length > 0 && {
                              color: "#b0b0b0",
                            }),
                          }}
                        >
                          {"Not selected"}
                        </span>
                      );
                    }
                    return selected.join(", ");
                  }}
                >
                  <MenuItem value="" disabled>
                    {"Not selected"}
                  </MenuItem>
                  {availableCities.length > 0 &&
                    availableCities.map((city, index) => (
                      <MenuItem
                        key={index}
                        value={`${city.locationName} (${city.locationCountry})`}
                        style={this.getStyles(
                          `${city.locationName} (${city.locationCountry})`,
                          search.addressCities
                        )}
                      >
                        {`${city.locationName} (${city.locationCountry})`}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="manage-property-filter big-filter">
                <Typography
                  style={{
                    display: "block",
                    padding: 0,
                    marginBottom: "5px",
                    fontWeight: 800,
                  }}
                >
                  Property Title
                </Typography>
                <Input
                  value={search.propertyTitle}
                  onChange={this.handleChange}
                  name="propertyTitle"
                  placeholder="Type here..."
                  className="form-control"
                  disableUnderline
                />
              </Box>
            </Box>
            <Box className="manage-property-filters">
              <Box className="manage-property-filter">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState(
                      {
                        page: { size: 10, page: 0, count: 0 },
                        showPromoted: false,
                      },
                      () => {
                        this.loadProperties();
                      }
                    )
                  }
                >
                  Search
                </Button>
              </Box>
              <Box className="manage-property-filter">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.resetFilter();
                    setTimeout(() => {
                      this.setState(
                        {
                          page: { size: 10, page: 0, count: 0 },
                          showPromoted: false,
                        },
                        () => {
                          this.loadProperties();
                        }
                      );
                    }, 100);
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}
        {properties.length > 0 ? (
          <Card>
            <CardContent className="table-scroll">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    {secureStorage.getItem("is_admin") && <th>Uploaded by</th>}
                    <th>Payment Reference Number</th>
                    <th>Address Line1</th>
                    <th>Address City</th>
                    <th>Address Postcode</th>
                    <th>Address Country</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {properties.map((property, index) => {
                      const firstName =
                        property.user_property_master.first_name;
                      const lastName = property.user_property_master.last_name;
                      if (
                        this.state.showPending &&
                        !property.status.includes("pending")
                      ) {
                        return;
                      }
                      if (this.state.showPromoted && !property.is_promoted) {
                        return;
                      }
                      return (
                        <tr
                          key={index}
                          className={`${this.getPropertyRowClass(
                            property.status,
                            property.is_draft
                          )}`}
                        >
                          <td>{page.page * page.size + index + 1}</td>
                          <td>
                            {property.title}
                            {property.is_draft ? <span> [DRAFT]</span> : null}
                          </td>
                          {secureStorage.getItem("is_admin") && (
                            <td>{`${firstName} ${lastName}`}</td>
                          )}
                          <td>{property.purchase_ref_number}</td>
                          <td>{property.address_line1}</td>
                          <td>{property.address_city}</td>
                          <td>{property.address_postcode}</td>
                          <td>{property.address_country}</td>
                          <td>
                            <div className="action-buttons">
                              {property.status.includes("pending") ? (
                                secureStorage.getItem("is_admin") && (
                                  <Box component="span">
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="secondary"
                                      onClick={() =>
                                        this.openTransferModal(property)
                                      }
                                    >
                                      Check request
                                    </Button>
                                  </Box>
                                )
                              ) : (
                                <>
                                  {!property.external ? (
                                    <Box component="span" mr={1}>
                                      <Link
                                        to={`/dashboard/properties/edit/${property.property_id}`}
                                      >
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          color="primary"
                                          className="edit"
                                        >
                                          Edit
                                        </Button>
                                      </Link>
                                    </Box>
                                  ) : (
                                    <Box component="span" mr={1}>
                                      <Button
                                        disabled
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        className="edit edit-disabled"
                                      >
                                        Edit
                                      </Button>
                                    </Box>
                                  )}
                                  {property.is_promoted ? (
                                    <Box component="span" mr={1}>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        className="demote"
                                        onClick={() =>
                                          this.handlePropertyDemote(
                                            property.property_id
                                          )
                                        }
                                      >
                                        Demote
                                        <Tooltip
                                          title={`This property is promoted on front page for ${this.calculateRemainingPromotionDays(
                                            property.promoted_date
                                          )} more days`}
                                          placement="top"
                                        >
                                          <InfoIcon className="tab-lable-info-icon promoted-tooltip" />
                                        </Tooltip>
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Box component="span" mr={1}>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        className="promote"
                                        onClick={() =>
                                          this.openPromoteModal(
                                            property.property_id
                                          )
                                        }
                                        disabled={
                                          !this.isNumber(
                                            this.state.immoTokenBalance
                                          )
                                        }
                                      >
                                        Promote
                                      </Button>
                                    </Box>
                                  )}
                                  <Box component="span" mr={1}>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="secondary"
                                      className={
                                        property.is_sold ? "sold" : "set-sold"
                                      }
                                      onClick={() =>
                                        this.handlePropertySold(
                                          property.property_id
                                        )
                                      }
                                    >
                                      {property.is_sold ? "Sold" : "Set Sold"}
                                    </Button>
                                  </Box>
                                  <Box component="span" mr={1}>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="secondary"
                                      className={
                                        property.status === "active"
                                          ? "active"
                                          : "inactive"
                                      }
                                      onClick={() =>
                                        this.handlePropertyStatus(
                                          property.property_id
                                        )
                                      }
                                    >
                                      {property.status === "active"
                                        ? "Deactivate"
                                        : "Activate"}
                                    </Button>
                                  </Box>
                                  <Box component="span">
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="secondary"
                                      className="delete"
                                      onClick={() =>
                                        this.openDeleteModal(
                                          property.property_id
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                  <tr>
                    <TablePagination
                      rowsPerPageOptions={[10, 50]}
                      labelRowsPerPage="Items per page:"
                      page={page.page}
                      count={page.count}
                      rowsPerPage={page.size}
                      onRowsPerPageChange={(e) => {
                        this.handlePageChange({
                          ...page,
                          page: 0,
                          size: e.target.value,
                        });
                      }}
                      onPageChange={(e, idx) => {
                        this.handlePageChange({ ...page, page: idx });
                      }}
                    />
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        ) : (
          <>
            <Divider /> <br />
            <Typography variant="h5">No properties found</Typography>
            <p>
              You can upload property or buy one on{" "}
              <strong>
                <Link to="/marketplace">marketplace</Link>
              </strong>
            </p>
          </>
        )}
        {properties.length > 0 && (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Box sx={{ my: 3, width: "100%", maxWidth: 550 }}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Table Legend</Typography>
                  <Divider /> <Divider />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#f8e2c9",
                          borderRadius: 5,
                        }}
                      ></Box>
                      <ListItemText primary="Drafted property" />
                    </ListItem>
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#f2c9c7",
                          borderRadius: 5,
                        }}
                      ></Box>
                      <ListItemText primary="Inactive property" />
                    </ListItem>
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#cccddb",
                          borderRadius: 5,
                        }}
                      ></Box>
                      <ListItemText primary="Utrust payment pending" />
                    </ListItem>
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#ccdbdb",
                          borderRadius: 5,
                        }}
                      ></Box>
                      <ListItemText primary="Escrow payment pending" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Box>
        )}
        <DeleteModal
          open={this.state.deleteModalStatus}
          handleClose={this.closeDeleteModal}
          handleDelete={this.handlePropertyRemove}
          content={"property"}
        />
        <TransferPropertyModal
          handleClose={this.closeTransferModal}
          open={this.state.transferModalStatus}
          isOpen={this.state.transferModalStatus}
          owner={this.state.users.owner}
          buyer={this.state.users.buyer}
          paymentRefNum={this.state.paymentRefNumber}
          handleReject={this.handleRejectTransferModal}
          handleFinish={this.handleFinishTransferModal}
        />
        <TokenPayModal
          handleClose={this.closePromoteModal}
          open={this.state.promoteModalStatus}
          isOpen={this.state.promoteModalStatus}
          action={this.state.modalAction}
          propertyTitle={this.state.selectedProperty.title}
          content={`You are about to ${this.state.modalAction} this property`}
          immoTokenBalance={this.state.immoTokenBalance}
          zbsTokenBalance={this.state.zbsTokenBalance}
          payAmount={this.state.propertyPricing.amount}
          handleFinish={this.handlePropertyPromote}
        />
      </div>
    );
  }
}

function mapState(state) {
  const { alert, general } = state;
  return { alert, general };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  getAllPropertyActiveLocations: generalActions.getAllPropertyActiveLocations,
  getAllProperties: generalActions.getAllProperties,
  getPromotedProperties: generalActions.getPromotedProperties,
  getPropertyPricing: generalActions.getPropertyPricing,
  getPurchasePendingProperties: generalActions.getPurchasePendingProperties,
  updatePropertyValues: generalActions.updatePropertyValues,
  transferProperty: generalActions.transferProperty,
  deletePropertyById: generalActions.deletePropertyById,
};

const connectedPage = withRouter(
  connect(mapState, actionCreators)(ManagePropertyPage)
);
export { connectedPage as ManagePropertyPage };
