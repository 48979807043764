import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import MessageValidator from "../../components/Form/MessageValidator";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { userService } from "../../_services";

const ContactAgentForm = () => {
  const ref = useRef(null);

  const [data, setData] = useState({
    email: "",
    message: "",
  });

  const [busy, setBusy] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    const res = await userService.sendPropertyContact(
      data.email,
      data.message,
      property.property_id
    );
    setBusy(false);
    if (!res.status) {
      setAlert({
        message: res.message,
        type: "error",
      });
    } else {
      setAlert({
        message: res.message,
        type: "success",
      });
    }
    setTimeout(() => {
      setAlert({
        message: "",
        type: "",
      });
      setData({
        email: "",
        message: "",
      });
    }, 5000);
    closeModal(false);
  };

  const handleChange = (e) => {
    const d = { ...data };
    d[e.target.name] = e.target.value;
    setData(d);
  };
  return (
    <ValidatorForm
      id="property-contact-form"
      onSubmit={handleSubmit}
      ref={ref}
      style={{ marginTop: "10px", padding: "0", marginBottom: "20px" }}
      autoComplete="off"
    >
      <TextValidator
        fullWidth
        value={data.email || ""}
        onChange={handleChange}
        name="email"
        id="outlined-name"
        label="Email Address"
        margin="normal"
        variant="outlined"
        validators={["required", "isEmail"]}
        errorMessages={["this field is required", "Email address is not valid"]}
      />
      <MessageValidator
        fullWidth
        value={data.message || ""}
        onChange={handleChange}
        name="message"
        id="outlined-name"
        label="Your message"
        margin="normal"
        variant="outlined"
        validators={[
          "required",
          "isString",
          "minStringLength:15",
          "maxStringLength:1000",
        ]}
        errorMessages={[
          "this field is required",
          "Message must be a string",
          "Minimum length of a message is 15 characters",
          "Maximum length of a message is 1000",
        ]}
      />
      {busy ? (
        <Box sx={{ display: "flex", padding: "34px 0" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Button type="submit" variant="outlined" color="default">
          Submit
        </Button>
      )}
    </ValidatorForm>
  );
};

export default ContactAgentForm;
