import React, { useState, useEffect } from "react";
import "./styles.scss";
import { generalService } from "../../_services/general.service";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import YachtFilters from "./YachtFilters";
import YachtSearchField from "./Filters/YachtSearchField";
import YachtListSkeletonLoader from "./Loaders/YachtListSkeletonLoader";
import { generateFilterList, removePageParam } from "../helpers/helpers";

const YachtList = () => {
  const [yachts, setYachts] = useState([]);
  const [pagination, setPagination] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params, setParams] = React.useState(false);
  console.log("params: ", params);

  const navigate = useNavigate();

  const fetchYachts = (scrapeUrl) => {
    setLoading(true);
    generalService
      .scrapeYachts(scrapeUrl)
      .then((response) => {
        setYachts(response.data);
        setPagination(response.pagination);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching yachts");
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryString = window.location.search; // Get query string from the URL
    console.log("queryString", queryString);
    fetchYachts(`${queryString}`); // Send query string to backend

    // Create a URLSearchParams object
    const urlParams = new URLSearchParams(queryString);

    // Convert the URLSearchParams object into a plain object
    setParams(Object.fromEntries(urlParams.entries()));
  }, [window.location.search]);

  // Function to handle pagination click
  const handlePaginationClick = (url) => {
    const decodedUrl = decodeURIComponent(url); // Decode the URL components
    window.history.pushState(null, "", decodedUrl); // Update the URL
    fetchYachts(url); // Fetch data based on the new page
  };

  if (loading) return <YachtListSkeletonLoader />;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ marginBottom: "32px" }}>
      <section className="yacht-hero-section">
        <div className="yacht-hero-container">
          <h3>Search Yachts For Sale</h3>
          <div className="yacht-filters-wrapper">
            <YachtFilters params={params} />
          </div>
          <YachtSearchField />
        </div>
      </section>

      {/* Render Breadcrumbs */}
      {window.location.search ? (
        <section className="breadcrumbs-container">
          <span>Showing results for </span>{generateFilterList(params)}
        </section>
      ) : null}

      {/* Render Pagination */}
      <section className="pagination-container">
        <div
          className="pagination-wrapper"
          dangerouslySetInnerHTML={{ __html: pagination }}
          onClick={(e) => {
            if (e.target.tagName === "A") {
              e.preventDefault();
              const url = e.target.getAttribute("href");
              // Find the index of the last '=' character
              const lastEqualIndex = url.lastIndexOf("=");
              // Extract the substring after the last '='
              const lastNumber = url.substring(lastEqualIndex + 1);
              // Handle pagination click
              handlePaginationClick(
                // Remove and re add page parameter
                `${removePageParam(window.location.search)}&page=${lastNumber}`
              );
            }
          }}
        />
      </section>

      {/* Render Yacht List */}
      <section className="yacht-list">
        <div className="yacht-list-container">
          <Grid container spacing={2}>
            {yachts.map((yacht, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  onClick={() =>
                    navigate(`/yacht/${yacht.yacht_id}/${yacht.slug}`)
                  }
                  className="yacht-card"
                >
                  <CardMedia
                    component="img"
                    height="330"
                    image={yacht.thumb_urls.primary}
                    alt={yacht.vessel_name}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      className="yacht-name"
                      component="div"
                    >
                      {yacht.vessel_name}
                    </Typography>
                    <div
                      className="yacht-card-price"
                      dangerouslySetInnerHTML={{ __html: yacht.price_display }}
                    />
                    <div className="yacht-details">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: yacht.length_display_feet,
                        }}
                      />
                      |
                      <span
                        dangerouslySetInnerHTML={{
                          __html: yacht.vessel_manufacturer,
                        }}
                      />
                      |
                      <span dangerouslySetInnerHTML={{ __html: yacht.year }} />
                    </div>
                    <div className="yacht-rooms">
                      {yacht?.cabin_count > 0 && (
                        <span>{yacht?.cabin_count} Staterooms</span>
                      )}
                      {yacht.sleep_count > 0 && (
                        <>
                          |<span>{yacht.sleep_count} Guests</span>
                        </>
                      )}
                      {yacht.crew_sleep_count > 0 && (
                        <>
                          |<span>{yacht.crew_sleep_count} Crew</span>
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
      <section className="pagination-container">
        <div
          dangerouslySetInnerHTML={{ __html: pagination }}
          onClick={(e) => {
            if (e.target.tagName === "A") {
              e.preventDefault();
              const url = e.target.getAttribute("href");
              // Find the index of the last '=' character
              const lastEqualIndex = url.lastIndexOf("=");
              // Extract the substring after the last '='
              const lastNumber = url.substring(lastEqualIndex + 1);
              // Handle pagination click
              handlePaginationClick(
                // Remove and re add page parameter
                `${removePageParam(window.location.search)}&page=${lastNumber}`
              );
            }
          }}
        />
      </section>
    </div>
  );
};

export default YachtList;
