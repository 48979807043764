import { locationConstants } from "../_constants";

export const initialState = {
  locations: {},
  location: {},
  languageCode: "en",
};

export function location(state = initialState, action) {
  switch (action.type) {
    case locationConstants.GET_LOCATIONS_SUCCESS:
      return {
        locations: action.payload,
      };
    case locationConstants.GET_LOCATION_BY_ID_SUCCESS:
      return {
        location: action.payload,
      };
    default:
      return state;
  }
}
