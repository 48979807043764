import React from "react";
import { Grid, Button, Tabs, Tab, Paper } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import { Close } from "@material-ui/icons";
import { TabPanel } from "../../../../components/GeneralComponent/LanguageTabs";
const fileIcon = require("../../../../assets/images/file_icon.png");
import "../../styles.scss";

const RenderFileUploadPart = (
  state,
  handlePropertyDocCapture,
  handlePropertyDocTabValue,
  handlePropertyDocDescChange,
  handleRemovePropertyDoc
) => {
  const { property } = state;

  return (
    <div className="step-wizard-block block-5" id="step_5">
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Button variant="contained" component="label">
            <span>
              {property.property_type === "direct"
                ? "Upload Document"
                : "Upload File"}
            </span>
            <input
              type="file"
              id="property_logo"
              onChange={handlePropertyDocCapture}
              className="upload-doc-file"
              accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
            />
          </Button>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          {property.property_documents.length > 0 &&
            property.property_documents
              .filter((d) => !d.is_delete)
              .map((doc, index) => {
                return (
                  <Paper key={index} className="property-file-item-container">
                    <Grid container spacing={1}>
                      <Grid
                        item
                        md={2}
                        sm={12}
                        xs={12}
                        className="file-icon-container"
                      >
                        <img
                          alt="file-icon"
                          src={fileIcon}
                          className="file-icon"
                        />
                        <p className="file-name">{doc.property_doc_filename}</p>
                      </Grid>
                      <Grid item md={9} sm={10} xs={12}>
                        <TabPanel value={doc.tab_value} index={0}>
                          <TextValidator
                            fullWidth
                            label="File Description"
                            value={doc.file_description_en}
                            name="file_description_en"
                            id="text-file_description_en"
                            onChange={handlePropertyDocDescChange(index)}
                            className="form-control"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </TabPanel>
                      </Grid>
                      <Grid item md={1} sm={2} xs={12}>
                        <Button
                          onClick={handleRemovePropertyDoc(doc)}
                          className="action-button"
                          variant="outlined"
                          size="small"
                          color="secondary"
                        >
                          <Close />
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderFileUploadPart;
