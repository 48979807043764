import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import "./styles.scss";

const usaFlag = require("../../assets/images/usa-flag.png");
const germanyFlag = require("../../assets/images/germany-flag.png");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="no-padding" p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LanguageTabEn() {
  return (
    <div>
      <img alt="language-en" src={usaFlag} className="tab-lable-img" />
      <span className="tab-lable-text"> English </span>
      <Tooltip title="More languages coming soon" placement="right-start">
        <InfoIcon className="tab-lable-info-icon" />
      </Tooltip>
    </div>
  );
}

function LanguageTabGn() {
  return (
    <div>
      <img alt="language-gn" src={germanyFlag} className="tab-lable-img" />
      <span className="tab-lable-text"> German</span>
    </div>
  );
}

export { TabPanel, a11yProps, LanguageTabEn, LanguageTabGn };
