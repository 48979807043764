import React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const YachtListSkeletonLoader = () => {
  const skeletons = [];
  for (let i = 0; i < 12; i++) {
    skeletons.push(
      <Grid item xs={12} sm={6} md={4} key={i}>
        <Card className="yacht-card">
          <Skeleton variant="rect" width="100%" height={330} />
          <CardContent>
            <Skeleton height={30} style={{ marginBottom: 6 }} />
            <Skeleton height={20} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton height={20} width="60%" />
          </CardContent>
        </Card>
      </Grid>
    );
  }
  return (
    <div style={{ marginBottom: "32px" }}>
      <section className="yacht-hero-section">
        <div className="yacht-hero-container">
          <h3>Search Yachts For Sale</h3>
          <div className="yacht-filters-wrapper">
            <div className="yacht-filters">
            <div className="yacht-filters-wrapper">
              <Skeleton variant="rect" style={{background: '#fff', borderRadius: '8px'}} width={80} height={36} />
              <Skeleton variant="rect" style={{background: '#fff', borderRadius: '8px'}} width={80} height={36} />
              <Skeleton variant="rect" style={{background: '#fff', borderRadius: '8px'}} width={80} height={36} />
              <Skeleton variant="rect" style={{background: '#fff', borderRadius: '8px'}} width={80} height={36} />
              <Skeleton variant="rect" style={{background: '#fff', borderRadius: '8px'}} width={80} height={36} />
            </div>
          </div>
          </div>
            <div style={{maxWidth: '464px', width: '100%'}}>
              <Skeleton variant="rect" style={{background: '#fff', borderRadius: '8px'}} width="100%" height={36} />
            </div>
        </div>
      </section>
      <section className="yacht-list">
      <div className="yacht-list-container">
        <Grid container spacing={2}>
          {skeletons}
        </Grid>
      </div>
      </section>
    </div>
  )
};

export default YachtListSkeletonLoader