import React, { useRef, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import removePlusCode from "../../_helpers/removePlusCode";

// Import the icons
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import HouseIcon from "@material-ui/icons/HouseOutlined";

const SearchField = () => {
  const inputRef = useRef();
  const debounceTimeout = useRef(null);
  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event, value) => {
    if (value) {
      if (value.type === "city") {
        const locationString = `${value.valueCity} (${value.valueCountry})`;
        let locationParam = encodeURIComponent(
          JSON.stringify([locationString])
        );
        locationParam = locationParam.replace(/%20/g, "+");

        const countryParam = encodeURIComponent(
          JSON.stringify([value.valueCountry])
        );

        navigate(
          `/marketplace?location=${locationParam}&countries=${countryParam}`
        );
      } else if (value.type === "country") {
        const countryParam = encodeURIComponent(
          JSON.stringify([value.valueCountry])
        );
        navigate(`/marketplace?countries=${countryParam}`);
      } else if (value.type === "address") {
        navigate(`/property/view/${value.property_id}`);
      }
    }
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      setInputValue(value);

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(async () => {
        if (value.length > 3) {
          try {
            const response = await fetch(
              `${
                process.env.REACT_APP_API_URL
              }/find-by-address?q=${encodeURIComponent(value)}`
            );
            const locations = await response.json();

            const processedOptions = processLocations(locations);

            setOptions(processedOptions);
          } catch (error) {
            console.error("Failed to fetch locations", error);
          }
        } else {
          setOptions([]);
        }
      }, 300);
    }
  };

  const processLocations = (locations) => {
    if (!locations || locations.length === 0) {
      return [];
    }

    // Remove duplicates based on address_line1
    const uniqueLocations = locations.reduce((accumulator, current) => {
      if (
        !accumulator.some(
          (item) => item.address_line1 === current.address_line1
        )
      ) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    const processedOptions = [];

    const firstCountry = uniqueLocations[0].address_country;
    const firstCity = uniqueLocations[0].address_city;

    // Count how many options have the same country
    const sameCountryCount = uniqueLocations.filter(
      (loc) => loc.address_country === firstCountry
    ).length;

    // First option is the country name
    processedOptions.push({
      label: `View properties in ${firstCountry}`,
      valueCountry: firstCountry,
      type: "country",
    });

    // If more than 5 options have the same country, add "City / Country" as second option
    if (sameCountryCount > 5) {
      processedOptions.push({
        label: `View properties in ${firstCountry} / ${firstCity}`,
        valueCountry: firstCountry,
        valueCity: firstCity,
        type: "city",
      });
    }

    // Add the rest of the unique locations
    processedOptions.push(
      ...uniqueLocations.map((loc) => ({
        ...loc,
        label: removePlusCode(loc.address_line1),
        value: removePlusCode(loc.address_line1),
        property_id: loc.property_id,
        type: "address",
      }))
    );

    return processedOptions;
  };

  // Custom filter function
  const filterOptions = (options, { inputValue }) => {
    // Always include country and city options
    const firstOptions = options.filter(
      (option) => option.type === "country" || option.type === "city"
    );
    // Filter address options based on inputValue
    const filteredOptions = options.filter((option) => {
      if (option.type === "address") {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      }
      return false;
    });
    return [...firstOptions, ...filteredOptions];
  };

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(option) => option.label || ""}
      onInputChange={handleInputChange}
      onChange={handleChange}
      inputValue={inputValue}
      filterOptions={filterOptions}
      renderOption={(option) => (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Grid item xs>
            {option.label}
          </Grid>
          <Grid item>
            {option.type === "country" || option.type === "city" ? (
              <LocationIcon />
            ) : option.type === "address" ? (
              <HouseIcon />
            ) : null}
          </Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Start typing address, city, ZIP code or state"
          inputRef={inputRef}
        />
      )}
    />
  );
};

export default SearchField;
