import { Container, Box, Divider, Typography } from "@material-ui/core";

export const TermsOfUsePage = () => {
  return (
    <Box sx={{ my: 5 }}>
      <Container>
        <Typography variant="h4" paragraph={true} align="center">
          Terms of Use
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography variant="body1" paragraph={true} align="justify">
            We welcome You to the Immobilium Platform! These Terms of Use govern
            Your access to and use of the www.immobilium.com website and
            applications (the "Website") and services (the "Services") provided
            by Immobilium, Inc., a Delaware corporation ("Immobilium") on the
            "Immobilium Platform" or "Platform".
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            "User" means each person and/or entity ("You") that accesses or uses
            the Website, Services, and/or Platform, whether or not such person
            or entity creates a log in, "User Profile" or "Transaction Account".
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            These Terms of Use are in addition to any other agreements between
            the You the User and Immobilium, as may be specified in a{" "}
            <u>Platform User Agreement</u> in connection with a{" "}
            <u>Residential Listing Agreement</u>,{" "}
            <u>Buyer Representation Agreement</u> and/or a{" "}
            <u>Technology Operating Agreement</u>.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            By accessing the Website, establishing a login on our Website,
            completing a User Profile, establishing a Transaction Account, or
            using any Services, You agree that You have read, understood, and
            accept all of the terms and conditions contained in these Terms of
            Use, including our <u>Real Estate Transaction Risk Disclosures</u>,{" "}
            <u>Privacy Policy</u>, and{" "}
            <u>E-Sign and Electronic Delivery Consent</u>. Your compliance with
            these Terms of Use is a condition to Your access to and use of the
            Website, Services and/or the Platform.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium reserves the right to modify or discontinue the Website,
            Services, or Platform and/or certain features at any time,
            temporarily or permanently, without notice to You. Immobilium will
            have no liability for any change to the Website, Services or
            Platform, or any suspension or termination of Your access to or use
            of the Website, Services or Platform. Immobilium modifies the
            content on the Website from time to time, but makes no
            representation that the content is always complete or up to date. We
            are under no obligation to update such material or provide User
            support.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            We may amend or modify the Terms of Use at any time by posting the
            Terms of Use on the Website. We will notify Users of our updated
            Terms of Use by e-mail, notice on our Website, or similar means. The
            revised Terms of Use shall be effective on the date posted, but will
            not apply retroactively. Your continued access to or use of the
            Website or Services after posting of revised Terms of Use
            constitutes Your acceptance of the revised Terms of Use. If You do
            not agree to the revisions, Your sole and exclusive remedy is to
            terminate Your use of the Immobilium Site and Services.
          </Typography>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>1. Services</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium makes available to Users that are principal sellers and
            principal buyers of real estate, as well as Users that are
            third-party service providers, including, but not limited to, title
            insurance companies, escrow companies, appraisers, lenders, property
            inspectors, and technology service providers, with a comprehensive
            and efficient Platform for selling and buying residential and
            commercial real estate ("Transactions").
          </Typography>
          <ol>
            <li>
              <strong>
                <u>Real Estate Brokerage Services</u>
              </strong>
              . In each state in the United States where Immobilium is
              performing services for or on behalf of a seller or a buyer that
              requires a real estate broker license in connection with
              facilitating Transactions on the Platform, Immobilium holds real
              estate broker license from the appropriate state regulatory
              agency. When acting such real estate broker capacity, Immobilium
              looks to enter into <u>Residential Listing Agreement</u> with a
              seller, or a <u>Buyer Representation Agreement</u> with the buyer,
              specifying the negotiable commission or fee that Immobilium will
              earn upon the closing of a Transaction that utilizes Services on
              the Platform. In some Transactions, pursuant to a written
              disclosure that Immobilium is acting as a Dual Agent, Immobilium
              may simultaneously represent both the seller and buyer in a
              Transaction. Immobilium also typically engages licensed real
              estate agent(s) in such state, that are either employees or
              independent contractors of Immbolium as required under applicable
              state law.
            </li>
            <Typography variant="p" paragraph={true}>
              Similarly, in each country other than the United States where
              Immobilium is performing services requiring a real estate broker
              license, certificate or permit in connection with providing
              Services and facilitating Transactions on the Platform, Immobilium
              holds the requisite real estate broker license certificate or
              permit from the appropriate government regulatory agency.
            </Typography>
            <li>
              <strong>
                <u>Transaction Posting Services</u>
              </strong>
              . Immobilium also makes opportunities available for sellers to
              post their property for sale by themselves (i.e., For Sale by
              Owner, "FSBO"). In such cases, Immobilium will not provide
              Services that require Immobilim to hold any real estate broker
              license, certificate or permit. Immobilium will charge such
              sellers a "Seller Posting Fee" in exchange for enabling them to
              market their property to buyers and, to access the services of
              third-party Users in closing a Transaction by themselves. Such
              flat Seller Posting Fee (not a percentage of the asking price or
              sale price) is due and payable when the seller posts their
              property for sale on the Platform and, is not contingent upon a
              Transaction closing.
            </li>
            <Typography variant="p" paragraph={true}>
              Immobilium also makes opportunities available for buyers to post
              their interest in acquiring particular types of properties,
              according to price, location and feature preference filters
              ("Buyer Profile"). In such cases, Immobilium will not provide
              Services that require Immobilum to hold any real estate broker
              license, certificate or permit. Immobilium will charge such buyers
              a "Buyer Posting Fee" in exchange for enabling them to access
              prospective sellers and, to access the services of third-party
              Users in closing a Transaction by themselves. Such flat Buyer
              Posting Fee (not a percentage of the asking price or sales price)
              is due and payable only when and, is contingent upon, a
              Transaction closing.
            </Typography>
            <li>
              <strong>
                <u>General Platform Services</u>
              </strong>
              . Third-party Users of the Website and Platform that are not
              principal sellers and buyers of residential or commercial real
              estate, may or may not be charged any fees to access the Platform
              and/or to integrate their services with Immobilium's Services on
              the Platform ("Platform Access Fees"). In connection with
              completing a Platform User Agreement, the benefits and risks to
              Immobilium and the third-party in providing collaborative services
              will be considered, when determining whether Platform Access Fees
              will be charged and in what amounts. Categories of third-party
              Users (e.g., escrow companies) will be charged, if any, similar
              Platform Access Fees, unless a particular User has incurred
              expenses to customize the integration of collaborative services.
              Immobilium will not own an interest in any third-party Users that
              will provide any third-party services on the Platform, unless such
              conflicts of interest are properly disclosed and allowable under
              appliable law.
            </li>
            <li>
              <strong>
                <u>Services Not Provided</u>
              </strong>
              . Immobilium does not provide any tax, legal, financial, or
              accounting representations, warranties or advice. You are not
              entitled to rely upon any statements of Immobilium officer,
              director, shareholder, manager, or employee as constituting any
              tax, legal, financial, or accounting representations, warranties
              or advice; any such statements are opinions only. Users that are
              sellers and buyers are highly recommended to seek their own
              independent legal advice from an attorney; tax advice from an
              accountant; and, other financial advice from a financial adviser.
              Immobilium expressly disclaims all tax, legal, financial, or
              accounting liabilities, expenses, fees, costs, or penalties that
              You may incur in connection with a Transaction.
            </li>
          </ol>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>2. Important Notices and Disclosures</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            By Your acceptance of these Terms of Use, You acknowledge receipt of
            these Notices and Disclosures:
          </Typography>
          <ol>
            <li>
              <strong>
                <u>Risks in Real Estate Transactions</u>
              </strong>
              . Please carefully review our Real Estate Transaction Risk
              Disclosures for an overview of important risks that You should
              consider in real estate transactions. You acknowledge and agree
              that Your access to and use of the Website, the Services and the
              Platform is subject to risks, including but not limited to the
              risks described in the risk disclosure and elsewhere on the
              Website and documents related to a specific real estate
              Transaction. The occurrence of any of these or other risks could
              result in losses and damages to You.
            </li>
            <li>
              <strong>
                <u>Privacy Policy</u>
              </strong>
              . Immobilium respects Your privacy and is committed to protecting
              it. Please review our Privacy Policy. Notwithstanding and in
              addition to the foregoing, if You are a third-party User or vendor
              to us, or provide services or goods to Immobilium, You acknowledge
              and agree that we may use and provide Your business identifiable
              information and other nonpublic, confidential information to third
              parties as part of our vendor onboarding to validate payment
              instructions or perform due diligence on You as required by law
              and as otherwise required by us in our reasonable discretion
              including, but not limited to, Anti-Money Laundering ("AML"), Know
              Your Customer ("KYC"), credit checks, and background checks.
            </li>
            <li>
              <strong>
                <u>E-Sign and Electronic Delivery Consent</u>
              </strong>
              . Immobilium provides may Services electronically. You consent to
              the use of electronic signatures and delivery of records in
              electronic format during our relationship with You as set forth in
              our E-Sign and Electronic Delivery Consent.
            </li>
          </ol>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>3. Communications</u>
            </strong>
          </Typography>
          <ol>
            <li>
              <strong>
                <u>Calls and Texts</u>
              </strong>
              . If You provide us with phone number(s), You: (1) represent and
              warrant that the numbers You provide are Your phone numbers, and
              You will promptly notify us if that changes, (2) consent to
              receive calls and texts made to that phone number concerning Your
              User Profile, Transactions, and other operational matters,
              including calls that may be prerecorded or completed with an
              automatic telephone dialing system (automated calls) for such
              operational purposes (including security alerts), or investigating
              or preventing fraud, and (3) consent to receive calls and texts
              made to that phone number for promotional purposes.
            </li>
            <Typography variant="p" paragraph={true}>
              You may opt out at any time from receiving these types of texts or
              calls by opting-out on Your Investing Account profile, advising
              the caller orally, by replying "STOP" to a text, or by e-mail to
              customerservice@immobilium.com. Opting out of receiving
              operational phone calls and texts may impact the functionality
              that the Website, Services and Platform provide to You. Please
              note, even if You opt out, we may still make other calls as
              permitted by law. You may continue to receive calls or text
              messages for a short period while Immobilium processes Your
              request, and You may also receive text messages confirming the
              receipt of Your opt-out request. From time to time, You also may
              have the opportunity to "opt in" to receive additional types of
              promotional calls and texts, and Your selection to receive such
              communications shall be Your consent, which may be revoked at any
              time by changing Your selection in Your User Profile, advising the
              caller orally, by replying "STOP" to a text, or by e-mail to
              customerservice@immobilium.com.
            </Typography>
            <Typography variant="p" paragraph={true}>
              You agree that Immobilium may record and monitor phone calls and
              other electronic communications with You for customer service
              and/or regulatory compliance purposes. Immobilium does not
              guarantee that any particular communication will be recorded
              and/or retained. We may share Your number with third parties that
              provide services to us in connection with any of the foregoing
              purposes, including but not limited to debt collectors and as
              otherwise described in our{" "}
              <a href="https://www.crowdstreet.com/privacy/" target="_blank">
                Privacy Policy
              </a>
              . You understand that message, telephone minute and data rates may
              apply for calls and texts made to a mobile phone number.
            </Typography>
            <li>
              <strong>
                <u>Emails</u>
              </strong>
              . Immobilium may send You emails concerning Your User Profile,
              Transactions, and other operational matters. Immobilium also may
              send You promotional emails regarding our products and services,
              as well as those of third parties. You may opt out of promotional
              emails by following the unsubscribe instructions in the
              promotional email itself.
            </li>
          </ol>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>4. Confidentiality</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            By accessing and using the Website, Services and Platform, You
            acknowledge that You may have access to certain confidential and
            proprietary information of Immobilium, sellers, buyers other
            third-parties. This confidential information may include, but is not
            limited to, appraisal reports, comparative market analysis reports,
            business plans, financial projections, financial results. You agree
            to treat such information as confidential, not use it for any
            purpose other than the potential transactional purposes for which
            the information was provided to You, and not to disclose it to any
            third party, except (i) as necessary to permit Your accountant,
            lawyer, or investment advisor to provide services other than
            affecting like transactions; (ii) in connection with an audit or
            regulatory examination by federal or state regulators; or (iii) as
            may otherwise be legally required or authorized.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>5. Third-Party Information</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            The contents of the Website and Platform include information
            provided by third parties. While Immobilium continually endeavors to
            provide reasonably and materially correct information, Immobilium
            cannot verify or guarantee the accuracy or completeness of all
            information provided by third parties. Immobilium makes no
            representation, warranty, or assurance that any information posted
            on the Website, Platform or through the delivery of Services
            concerning or by third parties is accurate or complete and
            Immobilium has no responsibility or liability for that third-party
            information. Immobilium disclaims all liability and responsibility
            arising from any reliance placed on such third-party materials by
            any User or anyone who may be informed of the content. All
            statements and/or opinions expressed in third party content are
            solely the opinions and the responsibility of the person or entity
            providing the third party content.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>6. User Interactions and Disagreements</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            The Website may allow You to interact with other Users, including,
            sellers, buyers and third-party service providers. You acknowledge
            that such other Users are not affiliated with or controlled by
            Immobilium or its affiliates. YOU AGREE THAT IMMOBILIUM AND ITS
            AFFILIATES WILL NOT BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE
            OF ANY SORT INCURRED AS THE RESULT OF ANY INTERACTIONS OR AGREEMENTS
            BETWEEN YOU AND OTHER USERS OR THIRD PARTIESS. IF YOU HAVE A DISPUTE
            WITH ONE OR MORE USERS OR THIRD PARTIES, YOU IRREVOCABLY AND FOREVER
            RELEASE IMMOBILIUM (AND IMMOBILIUM'S AFFILIATES, OFFICERS,
            DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURES AND EMPLOYEES) FROM
            ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY
            KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
            CONNECTED WITH SUCH DISPUTES.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>7. Indemnity</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            To the fullest extent permitted by law, You are responsible for Your
            use of the Website, Services, and Platform, and You will defend and
            indemnify Immobilium and its officers, directors, employees,
            consultants, affiliates, subsidiaries and agents (together, the
            "Immobilium Entities") from and against every claim, and any related
            liability, damage, loss, and expense, including reasonable
            attorneys' fees and costs, arising out of or connected with: (a)
            Your use of, or misuse of, the Website, Services and Platform; (b)
            Your violation of any portion of these Terms of Use, any
            representation, warranty, or agreement referenced in these Terms of
            Use, or any applicable law or regulation; (c) Your violation of any
            third party right, including any intellectual property right or
            publicity, confidentiality, other property, or privacy right; (d)
            any dispute, issue, or agreement between You and any third party, or
            (e) any interactions between or agreements between You and another
            User or any third party. We reserve the right, at our own expense,
            to assume the exclusive defense and control of any matter otherwise
            subject to indemnification by You (without limiting Your
            indemnification obligations with respect to that matter), and in
            that case, You agree to cooperate with our defense of those claims.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>8. Disclaimers; No Warranties</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            THE WEBSITE, THE SERVICES, THE PLATFORM, AND ALL MATERIALS,
            INFORMATION, AND CONTENT AVAILABLE THROUGH WEBSITE, THE SERVICES,
            THE PLATFORM, ARE PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS.
            IMMOBILIUM DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, RELATING TO THE WEBSITE OR SERVICES AND ALL MATERIALS,
            INFORMATION, AND CONTENT AVAILABLE THROUGH THEM, INCLUDING: (A) ANY
            IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY
            WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE.
            IMMOBILIUM DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE
            WEBSITE, SERVICES, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THEM,
            WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER
            HARMFUL COMPONENTS, AND IMMOBILIUM DOES NOT WARRANT THAT ANY OF
            THOSE ISSUES WILL BE CORRECTED.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
            WEBSITE, THE SERVICES, THE PLATFORM OR IMMOBILIUM OR ANY MATERIALS,
            OR CONTENT AVAILABLE THROUGH THEM WILL CREATE ANY WARRANTY BY
            IMMOBILIUM THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT
            RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE WEBSITE, THE
            SERVICES, THE PLATFORM AND YOUR DEALING WITH ANY OTHER SERVICE USER.
            YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE WEBSITE,
            THE SERVICES, THE PLATFORM AT YOUR OWN DISCRETION AND RISK, AND THAT
            WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING
            YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE
            WEBSITE OR SERVICES) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            YOU ARE SOLELY RESPONSIBLE FOR ANY INVESTMENT DECISIONS YOU MAKE
            BASED ON THE SERVICE. IMMOBILIUM SHALL NOT BE LIABLE FOR ANY
            INVESTMENT DECISIONS MADE BY YOU OR FOR ANY LOSS BASED ON THOSE
            DECISIONS.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO
            THE FULLEST EXTENT PERMITTED BY LAW.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium does not disclaim any warranty or other right that
            Immobilium is prohibited from disclaiming under applicable law.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>9. Limitation of Liability</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE
            IMMOBILIUM ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR
            LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT
            OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
            ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE
            SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
            ANY IMMOBILIUM ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF
            DAMAGE.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF
            THE IMMOBILIUM ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR
            RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE
            SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT,
            OR OTHERWISE, IS LIMITED TO $1,000. NOTWITHSTANDING THE FOREGOING,
            IMMOBILIUM WILL IN NO EVENT BE LIABLE FOR ANY CLAIMS OR DAMAGES
            ARISING OUT OF OR RELATED TO ANY INVESTMENT, OR POTENTIAL
            INVESTMENT, OF ANY USER. YOUR USE OF THE WEBSITE, SERVICES AND ANY
            FINANCIAL DECISIONS YOU MAKE RELATING TO THE SECURITIES ARE AT YOUR
            SOLE RISK.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
            LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
            INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
            THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
            THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
            SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS.
            THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED
            REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>10. Intellectual Property</u>
            </strong>
          </Typography>
          <ol>
            <li>
              <strong>
                <u>Website Content</u>
              </strong>
              . Immobilium grants You a limited right to use the Website,
              Services, the Platform and content provided through them
              ("Content"), including Third Party Content (described below),
              solely for the purpose of real estate Transaction activity through
              the Website, Services and Platform. You may view, download, and
              print the Content solely for personal non-commercial use. No
              license or other right, title or interest in any Content is
              intended or granted. You may not reproduce, publish, distribute,
              display, modify, create derivative works from, sell, license, or
              exploit in any way, in whole or in part, any of the Content or the
              Website. Immobilium reserves all rights with respect to copyright
              and trademark ownership of all Content.
            </li>
            <li>
              <strong>
                <u>Feedback</u>
              </strong>
              . If You choose to provide input, suggestions, or questions, or
              post to forums, chats, or otherwise on the Website or other
              Immobilium forum (collectively, "Feedback"), then You grant
              Immobilium an unrestricted, perpetual, irrevocable, non-exclusive,
              fully-paid, royalty-free right to exploit the Feedback in any
              manner and for any purpose, including to improve the Website,
              Services and Platform and create other products and services.
              Immobilium has no obligation to use or exploit the Feedback in any
              manner.
            </li>
            <li>
              <strong>
                <u>Ownership; Proprietary Rights</u>
              </strong>
              . The Website, Services and Platform are owned and operated by
              Immobilium. The Content, logos, visual interfaces, graphics,
              design, compilation, information, data, computer code (including
              source code or object code), products, software, services, and all
              other elements of the Services ("Materials") provided by
              Immobilium are protected by intellectual property and other laws.
              All Materials included in the Website, Services and Platform are
              the property of Immobilium or its third party Users and licensors.
              Except as expressly authorized by Immobilium, You may not make use
              of the Materials. Immobilium reserves all rights to the Materials
              not granted expressly in these Terms of use.
            </li>
            <li>
              <strong>
                <u>Third Party Content, Services and Linked Websites</u>
              </strong>
              . Third parties provide content, tools, reports, services,
              connectivity, and other material (collectively, "Third Party
              Content") on the Website, the Platform and/or through the
              Services. Immobilium does not prepare or endorse Third Party
              Content, does not guarantee the accuracy, timeliness, completeness
              or usefulness of Third Party Content, and is not responsible or
              liable for any content, advertising, products, or other materials
              on or available from third party sites.
            </li>
          </ol>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>11. DMCA Notification</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            We comply with the provisions of the Digital Millennium Copyright
            Act applicable to Internet service providers (17 U.S.C. § 512, as
            amended). If You have an intellectual property rights-related
            complaint about material posted on the Website, You may contact our
            Designated Agent at the following address:
          </Typography>
          <Box sx={{ ml: 3 }}>
            <Typography variant="body1">
              Immobilium, Inc.
              <br />
              ATTN: Legal Department (Copyright Notification)
              <br />
              415 N. Crescent Drive, Suite ______
              <br />
              Beverly Hills, CA 90210
              <br />
              Email: legal@crowdstreet.com
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>12. Prohibited Conduct</u>
            </strong>
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            You agree that You will not Use the Website, Services, Platform
            Content, or Materials:
          </Typography>
          <ol>
            <li>
              identify and transact business by attempting to close or closing a
              Transaction in a manner that circumvents Immobilium's rights or
              that interferes with or prevents Immobilium from earning or
              receiving a commission or fee, on Your behalf or on behalf of
              anyone else;
            </li>
            <li>
              to advertise or solicit other Users for any purpose, commercial or
              otherwise;
            </li>
            <li>
              for any illegal purpose or in violation of any local, state,
              national, or international law;
            </li>
            <li>
              to harass, threaten, demean, embarrass, or otherwise harm any
              other User, Immobilium employee, or other person, as determined by
              Immobilium in its sole and absolute discretion;
            </li>
            <li>
              in a manner that violates, or encourages others to violate, any
              right of Immobilium or a third party, including by infringing or
              misappropriating any Immobilium or third party intellectual
              property right;
            </li>
            <li>
              in a manner that interferes with security-related features of the
              Website, Services, or Platform, including by: (i) disabling or
              circumventing features that prevent or limit use or copying of any
              content; or (ii) reverse engineering or Otherwise attempting to
              discover the source code of any portion of the Service except to
              the extent that the activity is expressly permitted by applicable
              law;
            </li>
            <li>
              in a manner that interferes with the operation of the Website,
              Services or Platform or any User's enjoyment of the Website,
              Services, or Platform, including by: (i) uploading or otherwise
              disseminating any virus, adware, spyware, worm, or other malicious
              code; (ii) making any offer or advertisement to another User;
              (iii) collecting personal information about another User or third
              party without consent; or (iv) interfering with or disrupting any
              network, equipment, or server connected to or used to provide the
              Website, Services or Platform;
            </li>
            <li>
              to perform any fraudulent activity including impersonating any
              person or entity, claiming a false affiliation, accessing any
              other account without permission, or falsifying Your age or date
              of birth;
            </li>
            <li>
              to sell or otherwise transfer the access granted under these Terms
              of Use or any right or ability to view, access, or use any Content
              or Materials; or
            </li>
            <li>
              to attempt to do any of the acts described in this section or
              assist or permit any person in engaging in any of these acts.
            </li>
          </ol>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>13. Dispute Resolution</u>
            </strong>
          </Typography>
          <ol>
            <li>
              <strong>
                <u>Binding Arbitration</u>
              </strong>
              . Any claim, controversy or dispute (each a "Dispute") arising out
              of or related to the Website, Services, the Platform, or these
              Terms of Use, shall be subject to binding individual arbitration
              administered: (1) if in the United States, by the American
              Arbitration Association in accordance with its Commercial
              Arbitration Rules before a single arbitrator in Los Angeles,
              California, or (2) if outside the United States, by The Hague in
              accordance with The Hague Rules on Business and Human Rights
              Arbitration before a single arbitrator in The Hague, Netherlands.
            </li>
            <li>
              <strong>
                <u>Waiver of Right to Jury</u>
              </strong>
              . You and Immobilium each hereby waive their respective rights to
              (i) have Disputes resolved in a court, (ii) a jury trial, (iii) a
              class arbitration, class action, multi-party, or representative
              proceeding. Each party will notify the other party in writing of
              any dispute within thirty (30) days of the date it arises, so that
              the parties can attempt in good faith to resolve the dispute
              informally.
            </li>
            <li>
              <strong>
                <u>Pre-Arbitration Notice</u>
              </strong>
              . Prior to filing an Arbitration Demand with the American
              Arbitration Association or The Hague, the aggrieved party shall
              send a Notice to the other party. If we cannot agree how to
              resolve the dispute within thirty (30) days after the date notice
              is received by the applicable party, then either party may
              commence an arbitration proceeding. Notice to Immobilium shall be
              sent by email to legal@immobilium.com. Notice to the User shall be
              sent by email to the then-current email address associated with
              the User's Account. Your notice must include (i) Your name, postal
              address, email address, and telephone number, (ii) a description
              in reasonable detail of the nature or basis of the dispute, and
              (iii) the specific relief that You are seeking.
            </li>
            <li>
              <strong>
                <u>Costs; Discovery</u>
              </strong>
              . Each party shall split 50/50 the costs, fees and expenses of the
              arbitration. Each party will, upon written request of the other
              party, promptly provide the other with copies of all relevant
              documents. There shall be no other discovery allowed unless the
              amount in controversy exceeds $250,000 and such discovery is
              ordered by the arbitrator. Judgment on the award rendered by the
              arbitrator may be entered in any court having jurisdiction. The
              arbitration, and all aspects thereof (arguments, testimony,
              evidence, the decision, award, etc.), shall be confidential,
              except when used in the course of a judicial proceeding (e.g., to
              confirm, vacate or modify the award) or regulatory proceeding, as
              may be requested by a governmental entity or as otherwise may be
              required by law.
            </li>
            <li>
              <strong>
                <u>Authority of Arbitrator</u>
              </strong>
              . The arbitrator shall have the power to render an award for money
              damages and/or equitable relief. The arbitration shall also have
              the power to award the recovery and reimbursement of attorneys'
              fees, arbitration fees and other costs to the prevailing party.
              The award may be enforced by any court of competent jurisdiction.
              Notwithstanding these provisions, Immobilium may seek injunctive
              relief in any court having jurisdiction to protect its
              intellectual property rights.
            </li>
          </ol>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" paragraph={true}>
            <strong>
              <u>14. General Terms and Conditions</u>
            </strong>
          </Typography>
          <ol>
            <li>
              <u>Suspension; Termination</u>. Immobilium may at any time, in its
              sole discretion and without prior notice to You: (i) prohibit or
              restrict Your access to the use of the Website or Services; (ii)
              restrict Your ability to engage in one or more investment
              transactions; and/or (iii) terminate Your Investing Account. The
              closing of Your Investing Account will not affect the rights or
              obligations of either party that are incurred prior to the date
              Your Investment Account is closed.
            </li>
            <li>
              <u>Other Agreements</u>. Investments in private equity interests
              of real estate entities involve the execution of numerous
              agreements with third parties in addition to agreements You may
              enter into with Immobilium and its affiliates. When You invest in
              a transaction, You will enter into one or more agreements with the
              Issuer and/or Sponsor, such as a subscription agreement and
              limited liability company operating agreement. These investment
              agreements govern the terms of Your investment. Immobilium
              generally is not a party to Your investment agreements. These
              Terms of Use do not affect Your investment agreements. These Terms
              of Use apply to the Services provided by Immobilium to support and
              facilitate Your investment activities. If You have purchased
              investment advisory services from Immobilium Advisors, those
              advisory services are governed by Your Investment Advisory
              Agreement and not by these Terms of Use. However, You may access
              the Website and use other Services in addition to the advisory
              services, and these Terms of Use shall govern with respect to
              those non-advisory activities.
            </li>
            <li>
              <u>Governing Law; Jurisdiction</u>. These Terms are governed by
              the laws of the State of Texas without regard to conflict of law
              principles. You and Immobilium submit to the personal and
              exclusive jurisdiction of the state courts and federal courts
              located within Travis County, Texas for resolution of any lawsuit
              or court proceeding permitted under these Terms.
            </li>
            <li>
              <u>Additional Terms</u>. Your use of the Website and Services are
              subject to all additional terms, policies, rules, or guidelines
              that we may post on or link to from the Website (the "Additional
              Terms"). All Additional Terms are incorporated by this reference
              into, and made a part of, these Terms.
            </li>
            <li>
              <u>Contact Information</u>. The Website is offered by Immobilium,
              located at 98 San Jacinto Blvd, 4th Floor, Austin, TX 78701. You
              may contact us by emailing us at{" "}
              <a href="mailto:info@immobilium.com">info@immobilium.com</a>
            </li>
            <li>
              <u>Notice to California Residents</u>. If You are a California
              resident, under California Civil Code Section 1789.3, You may
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
              California 95834, or by telephone at (800) 952-5210 in order to
              resolve a complaint regarding the Service or to receive further
              information regarding use of the Service.
            </li>
            <li>
              <u>Binding Effect; Assignment</u>. This Agreement shall bind Your
              heirs, assigns, executors, successors, conservators and
              administrators. You may not assign this Agreement or any rights or
              obligations under this Agreement without first obtaining
              Immobilium's prior written consent. Immobilium may assign, sell,
              or transfer Your Investing Account, the Website, and/or the
              Services and its obligations under these Terms of Use, or any
              portion thereof, at any time, without Your prior consent.
            </li>
            <li>
              <u>Severability</u>. If any provisions or conditions of these
              Terms of Use are or become inconsistent with any present or future
              law, rule, or regulation of any applicable government, regulatory
              or self-regulatory agency or body, or are deemed invalid or
              unenforceable by any court of competent jurisdiction, such
              provisions shall be deemed rescinded or modified, to the extent
              permitted by applicable law, to make these Terms of Use in
              compliance with such law, rule or regulation, or to be valid and
              enforceable, but in all other respects, these Terms of Use shall
              continue in full force and effect.
            </li>
            <li>
              <u>Entirety of Agreement</u>. These Terms of Use, and any
              attachments, disclosures, agreements and policies referred to in
              these Terms of Use contain the entire agreement between Immobilium
              and You and supersede all prior or contemporaneous communications
              and proposals, whether electronic, oral, or written, between
              Immobilium and You with respect to the Website and the Services,
              provided, however, that any and all other agreements between
              Immobilium and You, not inconsistent with these Terms of Use, will
              remain in full force and effect.
            </li>
            <li>
              <u>No Waiver; Cumulative Nature of Rights and Remedies</u>. You
              understand that Immobilium's failure to insist at any time upon
              strict compliance with any term contained in these Terms of Use,
              or any delay or failure on Immobilium's part to exercise any power
              or right given to Immobilium, or a continued course of such
              conduct on Immobilium's part, shall at no time operate as a waiver
              of such power or right, nor shall any single or partial exercise
              preclude any other further exercise. All rights and remedies given
              to Immobilium in these Terms of Use are cumulative and not
              exclusive of any other rights or remedies to which Immobilium is
              entitled.
            </li>
          </ol>
        </Box>
      </Container>
    </Box>
  );
};
