import configureMeasurements, { Convert, area, length } from "convert-units";

const toUnits = {
  "m²": "ft²",
  "km²": "mi²",
  "ft²": "m²",
  "mi²": "km²",
  m: "ft",
  km: "mi",
  ft: "m",
  yd: "m",
  mi: "km",
};
const systems = {
  imperial: ["ft²", "mi²", "ft", "yd", "mi"],
  metric: ["m²", "km²", "m", "km"],
};

function convertUnit(text, toSystem = "imperial") {
  if (!text) return text;
  const regex = /(\d+(\.\d)?)( )?(m²|km²|ft²|mi²|m|km|ft|yd|mi)/gm;
  const matches = text.matchAll(regex);

  const converts = [];
  for (const m of matches) {
    const unit = m[4];
    const system = systems.imperial.indexOf(unit) >= 0 ? "imperial" : "metric";
    if (system === toSystem) continue;

    const value = parseFloat(m[1]);
    const toValue = configureMeasurements(value)
      .from(unit.replace("²", "2"))
      .to(toUnits[unit].replace("²", "2"));

    const converted = `${Math.round(toValue)} ${toUnits[unit]}`;
    converts.push({
      text: converted,
      index: m.index,
      length: m[0].length,
    });
  }

  let newText = text;
  converts.reverse().forEach(({ text, index, length }) => {
    newText = newText.substr(0, index) + text + newText.substr(index + length);
  });

  return newText;
}

export { convertUnit };
