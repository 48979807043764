import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { NavBarComponent as DasboardNavBarComponent } from "../components/DashboardComponent/common/NavBarComponent";

import "./PropertyPage/styles.scss";

export const WalletWarningPage = () => {
  return (
    <div className="main-content manage-property-page">
      <DasboardNavBarComponent pageName="manage-property" />
      <h1>Token wallet missing</h1>
      <Divider />
      <p>
        In order to manage your properties you need to have a token wallet.{" "}
      </p>
      <h3>
        <Link to="/dashboard/tokenwallet">Create token wallet</Link>
      </h3>{" "}
      <br />
    </div>
  );
};
