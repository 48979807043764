import React, { Component } from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import Gallery from "react-grid-gallery";
import "./styles.scss";

class PropertyDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property: [],
    };
    this.galleryRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.property !== state.property) {
      return {
        property: props.property,
      };
    }
    return null;
  }

  handleDataTransformation = (property_images) => {
    return property_images.map((image_item) => {
      return {
        alt: image_item.description.en,
        src: image_item.image_path,
        thumbnailWidth: 500,
        thumbnailHeight: 300,
        thumbnail: image_item.image_path,
      };
    });
  };

  openGalleryModal = () => {
    this.props.onModalOpen();
  };

  closeGalleryModal = () => {
    this.props.onModalClose();
  };

  exploreGalleryModal = (event) => {
    this.galleryRef.current.openLightbox(0, event);
  };

  render() {
    const { property } = this.state;

    return (
      <div className="property-description-box">
        {property &&
          property.property_images &&
          property.property_images.length > 0 && (
            <Grid container>
              <Grid item md={12} xs={12}>
                <Gallery
                  ref={this.galleryRef}
                  margin={10}
                  images={this.handleDataTransformation(
                    property.property_images
                  )}
                  maxRows={2}
                  thumbnailImageComponent={ImageComponent}
                  enableImageSelection={false}
                  showLightboxThumbnails={true}
                  lightboxWillClose={this.closeGalleryModal}
                  lightboxWillOpen={this.openGalleryModal}
                />
              </Grid>
            </Grid>
          )}
        {property &&
          property.property_images &&
          property.property_images.length > 0 && (
            <Link
              className="show-all-photos"
              onClick={this.exploreGalleryModal}
              style={{
                color: "#000",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              <SearchOutlinedIcon
                style={{ color: "#2BAAEA", marginRight: "5px" }}
              />
              <span>
                Explore all{" "}
                <Typography style={{ display: "inline", color: "#2BAAEA" }}>
                  ({property.property_images.length}) photos
                </Typography>
              </span>
            </Link>
          )}
      </div>
    );
  }
}

class ImageComponent extends React.Component {
  render() {
    return (
      <img
        alt="Property"
        className="property-image"
        {...this.props.imageProps}
      />
    );
  }
}

export default PropertyDescription;
