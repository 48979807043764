import { userConstants } from "../_constants";
import { userService, secureStorage } from "../_services";
import { alertActions } from "./";

function parseJwt() {
  var token = secureStorage.getItem(process.env.REACT_APP_LOGIN_KEY);
  if (token && token.split(".").length > 1) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    var tokenData = JSON.parse(jsonPayload);
    var expiryTime = tokenData.exp;
    var currentTime = Math.floor(new Date().getTime() / 1000);

    if (expiryTime > currentTime) {
      return { type: userConstants.PARSE_JWT, status: true, data: tokenData };
    } else {
      userService.logout();
      return { type: userConstants.PARSE_JWT, status: false };
    }
  } else {
    return { type: userConstants.PARSE_JWT, status: false };
  }
}

function login(email_id, password) {
  return (dispatch) => {
    dispatch(request({ email_id }));

    userService.login(email_id, password).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getUserDetails() {
  return (dispatch) => {
    userService.getUserDetails().then(
      (profile) => {
        dispatch(success(profile));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(profile) {
    return { type: userConstants.GET_PROFILE_SUCCESS, payload: profile };
  }
  function failure(error) {
    return { type: userConstants.GET_PROFILE_FAILURE, error };
  }
}

function getSecurityQuestion() {
  return (dispatch) => {
    userService.getSecurityQuestion().then(
      (securityQueRes) => {
        dispatch(success(securityQueRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(securityQueRes) {
    return {
      type: userConstants.GET_SECURITY_QUESTIONS_SUCCESS,
      payload: securityQueRes,
    };
  }
  function failure(error) {
    return { type: userConstants.GET_SECURITY_QUESTIONS_FAILURE, error };
  }
}

const updateUserProfile = (updateObj) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.updateUserProfile(updateObj).then((updateRes) => {
      if (updateRes.status) {
        dispatch(alertActions.success("Profile updated successfully"));
        resolve("resolved");
        if (updateObj.update_form_type === "wallet_address") {
          window.location.reload();
        }
      } else {
        dispatch(alertActions.error(updateRes.message.toString()));
      }
    });
  });
};

const updateProfile = (updateObj) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.updateProfile(updateObj).then((updateRes) => {
      if (updateRes.status) {
        dispatch(alertActions.success("Profile updated successfully"));
        resolve("resolved");
      } else {
        dispatch(alertActions.error(updateRes.message.toString()));
      }
    });
  });
};

const addUserInvestment = (dataObj) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.addUserInvestment(dataObj).then(
      (addInvestmentRes) => {
        if (addInvestmentRes.status === false) {
          dispatch(alertActions.error(addInvestmentRes.message.toString()));
        } else {
          dispatch(alertActions.success("User property successfully invested"));
          resolve("resolved");
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  });
};

function getUserInvestment() {
  return (dispatch) => {
    userService.getUserInvestment().then(
      (userInvestmentRes) => {
        dispatch(success(userInvestmentRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(userInvestmentResult) {
    return {
      type: userConstants.GET_USER_INVESTMENTS_SUCCESS,
      payload: userInvestmentResult,
    };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_INVESTMENTS_FAILURE, error };
  }
}

function getUserCommittedInvestment() {
  return (dispatch) => {
    userService.getUserCommittedInvestment().then(
      (userCommittedInvestmentRes) => {
        dispatch(success(userCommittedInvestmentRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(userCommittedInvestmentResult) {
    return {
      type: userConstants.GET_USER_COMMITTED_INVESTMENTS_SUCCESS,
      payload: userCommittedInvestmentResult,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_USER_COMMITTED_INVESTMENTS_FAILURE,
      error,
    };
  }
}

function getUserLatestInvestment() {
  return (dispatch) => {
    userService.getUserLatestInvestment().then(
      (userLatestInvestmentRes) => {
        dispatch(success(userLatestInvestmentRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(userLatestInvestmentResult) {
    return {
      type: userConstants.GET_USER_LATEST_INVESTMENTS_SUCCESS,
      payload: userLatestInvestmentResult,
    };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_LATEST_INVESTMENTS_FAILURE, error };
  }
}

function getUserPayments() {
  return (dispatch) => {
    userService.getUserPayments().then(
      (userPaymentsRes) => {
        dispatch(success(userPaymentsRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(userPaymentsResult) {
    return {
      type: userConstants.GET_USER_PAYMENTS_SUCCESS,
      payload: userPaymentsResult,
    };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_PAYMENTS_FAILURE, error };
  }
}

function getSettings() {
  return (dispatch) => {
    userService.getSettings().then(
      (settingRes) => {
        dispatch(success(settingRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(settingResult) {
    return { type: userConstants.GET_SETTINGS_SUCCESS, payload: settingResult };
  }
  function failure(error) {
    return { type: userConstants.GET_SETTINGS_FAILURE, error };
  }
}

const addUserOrder = (orderDetails) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.addUserOrder(orderDetails).then(
      (orderAddResponse) => {
        if (orderAddResponse.status) {
          dispatch(alertActions.success("Order placed successfully!"));
          resolve("resolved");
        } else {
          dispatch(alertActions.error(orderAddResponse.message.toString()));
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  });
};

function getUserRecentTransactions(propertyId) {
  return (dispatch) => {
    userService.getUserRecentTransactions(propertyId).then(
      (userRecentOrderTransactionsRes) => {
        dispatch(success(userRecentOrderTransactionsRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(recentTransactionResult) {
    return {
      type: userConstants.GET_USER_RECENT_TRANSACTION_SUCCESS,
      payload: recentTransactionResult,
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USER_RECENT_TRANSACTION_FAILURE,
      error,
    };
  }
}

//all users treading orders
function getUsersOrders(propertyId) {
  return (dispatch) => {
    userService.getUsersOrders(propertyId).then(
      (userOrdersRes) => {
        dispatch(success(userOrdersRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(userOrdersRes) {
    return {
      type: userConstants.GET_USERS_ORDERS_SUCCESS,
      payload: userOrdersRes,
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USERS_ORDERS_FAILURE,
      error,
    };
  }
}
//single user treading orders
function getUserOrders() {
  return (dispatch) => {
    userService.getUserOrders().then(
      (userAllOrdersRes) => {
        dispatch(success(userAllOrdersRes));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(userAllOrdersResult) {
    return {
      type: userConstants.GET_USER_ORDERS_SUCCESS,
      payload: userAllOrdersResult,
    };
  }

  function failure(error) {
    return {
      type: userConstants.GET_USER_ORDERS_FAILURE,
      error,
    };
  }
}

const deleteUserOrder = (order) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.deleteUserOrder(order).then((orderDeleteResponse) => {
      if (orderDeleteResponse.status) {
        dispatch(alertActions.success("Order removed successfully"));
        resolve("resolved");
      } else {
        dispatch(alertActions.error(orderDeleteResponse.message.toString()));
      }
    });
  });
};

const verifyUserShares = (propertyId, investmentDetailObj) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.verifyUserShares(propertyId, investmentDetailObj).then(
      (userSharesVeficationRes) => {
        resolve(userSharesVeficationRes);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  });
};

const getUserUnrealisedProfit = () => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.getUserUnrealisedProfit().then((userUnrealisedProfitRes) => {
      if (userUnrealisedProfitRes.status) {
        dispatch(success(userUnrealisedProfitRes));
      } else {
        dispatch(
          alertActions.error(userUnrealisedProfitRes.message.toString())
        );
      }
    });
  });

  function success(userUnrealisedProfitRes) {
    return {
      type: userConstants.GET_USER_UNREALISED_PROFIT_SUCCESS,
      payload: userUnrealisedProfitRes,
    };
  }
};
const getUserRealisedProfit = () => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.getUserRealisedProfit().then((userRealisedProfitRes) => {
      if (userRealisedProfitRes.status) {
        dispatch(success(userRealisedProfitRes));
      } else {
        dispatch(alertActions.error(userRealisedProfitRes.message.toString()));
      }
    });
  });

  function success(userRealisedProfitRes) {
    return {
      type: userConstants.GET_USER_REALISED_PROFIT_SUCCESS,
      payload: userRealisedProfitRes,
    };
  }
};

const getUserRealisedProfitData = () => (dispatch) => {
  return new Promise(function (resolve, reject) {
    userService.getUserRealisedProfitData().then((userRealisedProfitRes) => {
      if (userRealisedProfitRes.status) {
        dispatch(success(userRealisedProfitRes));
      } else {
        dispatch(alertActions.error(userRealisedProfitRes.message.toString()));
      }
    });
  });

  function success(userRealisedProfitRes) {
    return {
      type: userConstants.GET_USER_REALISED_PROFIT_DATA_SUCCESS,
      payload: userRealisedProfitRes,
    };
  }
};

function sendResetEmail(email) {
  return (dispatch) => {
    userService.sendResetEmail(email).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success(res.message.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(res) {
    return { type: userConstants.GET_RESET_MAIL_SENT, payload: res };
  }
  function failure(error) {
    return {
      type: userConstants.GET_RESET_MAIL_FAILURE,
      payload: { status: false, message: error },
    };
  }
}

function resetPassword(updateObj) {
  return (dispatch) => {
    userService.resetPassword(updateObj).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success(res.message.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(res) {
    return { type: userConstants.GET_RESET_PASSWORD, payload: res };
  }
  function failure(error) {
    return {
      type: userConstants.GET_RESET_PASSWORD_FAILURE,
      payload: { status: false, message: error },
    };
  }
}

function depositMoneyRequest(data) {
  return (dispatch) => {
    userService.depositMoneyRequest(data).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success(res.message.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(res) {
    return {
      type: userConstants.GET_DEPOSIT_MONEY_REQUEST_SUCCESS,
      payload: res,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_DEPOSIT_MONEY_REQUEST_FAILURE,
      payload: { status: false, message: error },
    };
  }
}

function claimTokensRequest(data) {
  return (dispatch) => {
    userService.claimTokensRequest(data).then(
      (res) => {
        console.log("res: ", res);
        dispatch(success(res));
        dispatch(alertActions.success(res.message.toString()));
      },
      (error) => {
        console.log("error: ", error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(res) {
    return {
      type: userConstants.CLAIM_TOKENS_REQUEST_SUCCESS,
      payload: res,
    };
  }
  function failure(error) {
    return {
      type: userConstants.CLAIM_TOKENS_REQUEST_FAILURE,
      payload: { status: false, message: error },
    };
  }
}

function depositMoney(userId, updateObj) {
  return (dispatch) => {
    userService.depositMoney(userId, updateObj).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success(res.message.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(res) {
    return { type: userConstants.GET_DEPOSIT_MONEY_SUCCESS, payload: res };
  }
  function failure(error) {
    return {
      type: userConstants.GET_DEPOSIT_MONEY_FAILURE,
      payload: { status: false, message: error },
    };
  }
}

function withdrawMoney(userId, updateObj) {
  return (dispatch) => {
    userService.withdrawMoney(userId, updateObj).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success(res.message.toString()));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(res) {
    return { type: userConstants.GET_WITHDRAW_MONEY_SUCCESS, payload: res };
  }
  function failure(error) {
    return {
      type: userConstants.GET_WITHDRAW_MONEY_FAILURE,
      payload: { status: false, message: error },
    };
  }
}

export const userActions = {
  login,
  logout,
  register,
  parseJwt,
  getUserDetails,
  updateUserProfile,
  updateProfile,
  sendResetEmail,
  resetPassword,
  depositMoneyRequest,
  claimTokensRequest,
  depositMoney,
  withdrawMoney,
  getSecurityQuestion,
  addUserInvestment,
  getUserInvestment,
  getUserCommittedInvestment,
  getUserLatestInvestment,
  getSettings,
  addUserOrder,
  getUserRecentTransactions,
  getUsersOrders,
  getUserPayments,
  getUserOrders,
  deleteUserOrder,
  verifyUserShares,
  getUserUnrealisedProfit,
  getUserRealisedProfit,
  getUserRealisedProfitData,
};
