import { NavLink, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.png";

const logos = {
  light: logo,
  sea: logo,
  cosmos: logo,
  spring: logo,
  forest: logo,
  butterfly: logo,
  sensual: logo,
  dark: logo,
};

const HeaderLogo = (props) => {
  const location = useLocation();
  const isHomePage = ["/", "/home"].includes(location.pathname);

  const theme = props.users?.profile_data?.theme || "light";

  return (
    <NavLink
      to="/"
      className={[
        "nav-logo",
        props.mobile ? "nav-logo-mobile" : "nav-logo-desktop",
        isHomePage ? "nav-transparent" : "nav-color",
      ].join(" ")}
    >
      <img
        src={logos[theme] || logo}
        style={{
          backgroundColor: props.logobackground,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        alt=""
      />
    </NavLink>
  );
};

export default HeaderLogo;
