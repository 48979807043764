import React from 'react'
import { Skeleton } from '@material-ui/lab';
import { Grid, Card, Typography } from '@material-ui/core';
const YachtSkeletonLoader = () => {
  return (
    <div className='yacht-skeleton-page'>
      <Skeleton variant="rect" width="60%" height={50} />
      <div className='hero-skeleton'>
        <Skeleton variant="rect" width="100%" height={396} />
      </div>
      <div className='yacht-content'>
        <Typography style={{marginBottom: '16px'}} component="div" variant="h3">
          <Skeleton variant="rect" width="40%"  />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          <Typography style={{marginBottom: '10px'}} component="div" variant="body">
            <Skeleton variant="rect"  />
          </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default YachtSkeletonLoader