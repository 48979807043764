import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { userActions, alertActions } from "../../_actions";
import { Button, Grid } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountUp,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { NavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";
import "react-table/react-table.css";
import "./styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import FooterComponent from "../../components/FooterComponent";
import DeleteModal from "../../components/DeleteModal";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalStatus: false,
      deleteOrder: {},
      AllOrders: [],
      SelectedOrder: {},
      allSortedOrders: [],
      isFooterOpen: false,
    };
    this.getOrdersSortedComponent = this.getOrdersSortedComponent.bind(this);
  }

  componentDidMount() {
    this.props.getUserOrders();
  }

  static getDerivedStateFromProps = (props, state) => {
    const { user_orders } = props.users;
    var update = {};
    if (user_orders && user_orders.status === true) {
      update = {
        ...update,
        AllOrders: user_orders.data,
      };
    }

    return update;
  };

  getOrdersSortedComponent(id) {
    let sortInfo = this.state.allSortedOrders.filter((item) => item.id === id);
    if (sortInfo.length) {
      if (sortInfo[0].desc === true)
        return <FontAwesomeIcon icon={faSortAmountDown} />;
      if (sortInfo[0].desc === false)
        return <FontAwesomeIcon icon={faSortAmountUp} />;
    }
    return <FontAwesomeIcon icon={faSortAmountDown} />;
  }

  deleteOrder = () => {
    this.props.deleteUserOrder(this.state.deleteOrder).then(() => {
      this.closeDeleteModal();
      this.props.getUserOrders();
    });
  };

  openDeleteModal = (order) => {
    this.setState({
      deleteModalStatus: true,
      deleteOrder: order,
    });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModalStatus: false });
  };

  toggleFooter = () => {
    this.setState({ isFooterOpen: !this.state.isFooterOpen });
  };

  render() {
    const { getOrdersSortedComponent } = this;
    const { allSortedOrders, AllOrders } = this.state;
    const { alert } = this.props;

    const genericHeaderArrows = () => {
      return {
        Header: (props) => {
          const Sorted = getOrdersSortedComponent(props.column.id);
          return (
            <span>
              {props.column.headerText} {Sorted}
            </span>
          );
        },
        headerStyle: { boxShadow: "none" },
      };
    };

    const OrdersColumns = [
      {
        ...genericHeaderArrows(),
        headerText: "Asset Name",
        accessor: "property_master.title",
        width: 250,
      },
      {
        ...genericHeaderArrows(),
        id: "order_type",
        headerText: "Order type",
        accessor: (row) => row.order_type,
        width: 150,
      },
      {
        ...genericHeaderArrows(),
        headerText: "Price / Share",
        accessor: "price",
        width: 150,
      },
      {
        ...genericHeaderArrows(),
        headerText: "Shares",
        accessor: "quantity",
        width: 150,
      },
      {
        ...genericHeaderArrows(),
        headerText: "Amount",
        accessor: "amount",
        width: 150,
      },
      {
        ...genericHeaderArrows(),
        headerText: "Total amount",
        accessor: "total_amount",
        width: 150,
      },
      {
        headerText: "",
        accessor: "id",
        width: 140,
        Cell: (props) => (
          <Button
            className="btn-order-cancel"
            onClick={() => this.openDeleteModal(props.original)}
          >
            <DeleteOutlineIcon />
          </Button>
        ),
      },
    ];

    return (
      <div className="dashboard-content">
        <NavBarComponent pageName="orders" />
        <main className="main-content orders-page">
          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
            />
          )}
          <p className="widget-block-title">All Orders</p>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <div className="table-widget all-investments-block">
                <Scrollbars
                  style={{ height: 400 }}
                  autoHide
                  autoHideTimeout={1000}
                >
                  <ReactTable
                    data={AllOrders}
                    columns={OrdersColumns}
                    showPagination={false}
                    resizable={false}
                    sortable={true}
                    sorted={allSortedOrders}
                    onSortedChange={(sorted) =>
                      this.setState({ allSortedOrders: sorted })
                    }
                    getTdProps={() => {
                      return {
                        onClick: (e, handleOriginal) => {
                          if (handleOriginal) {
                            handleOriginal();
                          }
                        },
                      };
                    }}
                    minRows={0}
                  />
                </Scrollbars>

                <div className="table-box-shadow"></div>
              </div>
            </Grid>
          </Grid>
          {this.state.isFooterOpen ? (
            <Button
              variant="contained"
              className="btn-footer-action"
              onClick={this.toggleFooter}
            >
              <CloseIcon /> Close
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-footer-action"
              onClick={this.toggleFooter}
            >
              GTC, Privacy policy & more
            </Button>
          )}
        </main>

        {this.state.isFooterOpen ? (
          <div className="footer-block">
            <FooterComponent />
          </div>
        ) : null}
        <DeleteModal
          open={this.state.deleteModalStatus}
          handleClose={this.closeDeleteModal}
          handleDelete={this.deleteOrder}
          content={"order"}
        />
      </div>
    );
  }
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  getUserOrders: userActions.getUserOrders,
  deleteUserOrder: userActions.deleteUserOrder,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(Orders);
