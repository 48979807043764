import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const LengthFilter = ({ params }) => {
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [measurement, setMeasurement] = useState("feet");

  useEffect(() => {
    if (params.min_length) {
      setMinLength(params.min_length);
    }
    if (params.max_length) {
      setMaxLength(params.max_length);
    }
    if (params.metric) {
      setMeasurement(params.metric);
    }
  }, [params]);

  const handleApply = () => {
    const currentUrl = window.location.href; // Get the current URL
    const baseUrl = currentUrl.split("?")[0]; // Get the base URL without query parameters
    const queryParams = new URLSearchParams(window.location.search); // Get existing query parameters

    // Remove page parameter
    queryParams.delete("page");

    // Set the new parameters
    queryParams.set("min_length", minLength);
    queryParams.set("max_length", maxLength);
    queryParams.set("metric", measurement);

    // Construct the new URL
    const newUrl = `${baseUrl}?${queryParams.toString()}`;

    // Redirect to the new URL
    window.location.href = newUrl; // Redirect to the new URL
  };

  const handleReset = () => {
    setMinLength("");
    setMaxLength("");
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <div className="range-header">
        <p>Length Range</p>
        <RadioGroup
          aria-label="currency"
          name="currency"
          value={measurement}
          onChange={(e) => setMeasurement(e.target.value)}
          row
          className="range-radio-group"
        >
          <FormControlLabel value="feet" control={<Radio />} label="Feet" />
          <FormControlLabel value="meters" control={<Radio />} label="Meters" />
        </RadioGroup>
      </div>
      <div className="yacht-filter-form-fields-wrapper">
        <TextField
          className="yacht-filter-fields"
          label="Min length"
          type="number"
          variant="outlined"
          size="small"
          value={minLength}
          onChange={(e) => setMinLength(e.target.value)}
          name="length-min"
        />
        <TextField
          className="yacht-filter-fields"
          label="Max Length"
          type="number"
          variant="outlined"
          size="small"
          value={maxLength}
          onChange={(e) => setMaxLength(e.target.value)}
          name="length-max"
        />
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete">
          Reset
        </Button>
        <Button onClick={handleApply} className="btn-save">
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default LengthFilter;
