export const yachtTypes = [
  "aft-cabin",
  "airboat",
  "antique-and-classics",
  "bass-boats",
  "bay-boats",
  "bowrider",
  "cargo-ship",
  "catamaran",
  "center-cockpit",
  "center-consoles",
  "classic-expedition-motorsailer",
  "commercial-boats",
  "convertible-boats",
  "coupe",
  "cruise-ship",
  "cruiser-racer",
  "cruisers",
  "cuddy-cabin",
  "cutter",
  "daysailers",
  "deck-boats",
  "deck-saloon",
  "downeast",
  "dual-console",
  "expedition-yacht",
  "express-cruiser",
  "flybridge",
  "freshwater-fishing",
  "house-boats",
  "jet-boats",
  "ketch",
  "lobster-boat",
  "long-range-cruiser",
  "motor-yachts",
  "motorsailers",
  "multi-hulls",
  "other",
  "passenger",
  "pilothouse",
  "pontoon-boats",
  "power",
  "racing-high-performance",
  "racing-sailboat",
  "rigid-inflatable-boats",
  "runabouts",
  "sail",
  "saltwater-fishing",
  "schooner",
  "skylounge",
  "sloop",
  "solar-power",
  "sport-fishing",
  "sports-cruiser",
  "submarine",
  "superyacht",
  "tender",
  "trawlers",
  "trideck",
  "trimaran",
  "tug",
  "unspecified",
  "utility-boats",
  "walkarounds",
];

export const regionData = [
  {
    continent: "North America",
    countries: [
      "Antigua and Barbuda",
      "Bahamas",
      "Barbados",
      "Belize",
      "Canada",
      "Cayman Islands",
      "Costa Rica",
      "Dominican Republic",
      "Grenada",
      "Guadeloupe",
      "Jamaica",
      "Martinique",
      "Mexico",
      "Panama",
      "Puerto Rico",
      "Trinidad and Tobago",
      "United States",
    ],
  },
  {
    continent: "Europe",
    countries: [
      "Croatia",
      "Cyprus",
      "Estonia",
      "France",
      "Germany",
      "Greece",
      "Ireland",
      "Italy",
      "Malta",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "Norway",
      "Poland",
      "Portugal",
      "Russian Federation",
      "Spain",
      "Sweden",
      "United Kingdom",
    ],
  },
  {
    continent: "Asia",
    countries: [
      "Bangladesh",
      "British Indian Ocean Territory",
      "China",
      "Georgia",
      "Korea, Republic of",
      "Lebanon",
      "Malaysia",
      "Maldives",
      "Qatar",
      "Singapore",
      "Taiwan",
      "Thailand",
      "Turkey",
      "United Arab Emirates",
    ],
  },
  {
    continent: "South America",
    countries: ["Brazil", "Colombia", "Peru", "Uruguay", "Venezuela"],
  },
  {
    continent: "Oceania",
    countries: [
      "Australia",
      "Fiji",
      "French Polynesia",
      "New Zealand",
      "Vanuatu",
    ],
  },
  {
    continent: "Africa",
    countries: ["Egypt", "Guinea-Bissau", "Seychelles", "South Africa"],
  },
];

export const regionDataNumbers = {
  "Antigua and Barbuda": 10,
  Bahamas: 17,
  Barbados: 20,
  Belize: 23,
  Canada: 39,
  "Cayman Islands": 41,
  "Costa Rica": 53,
  "Dominican Republic": 62,
  Grenada: 86,
  Guadeloupe: 87,
  Jamaica: 109,
  Martinique: 138,
  Mexico: 142,
  Panama: 170,
  "Puerto Rico": 178,
  "Trinidad and Tobago": 221,
  "United States": 231,
  Croatia: 55,
  Cyprus: 57,
  Estonia: 68,
  France: 74,
  Germany: 81,
  Greece: 84,
  Ireland: 105,
  Italy: 108,
  Malta: 136,
  Monaco: 145,
  Montenegro: 147,
  Netherlands: 155,
  Norway: 165,
  Poland: 176,
  Portugal: 177,
  "Russian Federation": 182,
  Spain: 204,
  Sweden: 210,
  "United Kingdom": 230,
  Bangladesh: 19,
  "British Indian Ocean Territory": 32,
  China: 45,
  Georgia: 80,
  "Korea, Republic of": 117,
  Lebanon: 122,
  Malaysia: 133,
  Maldives: 134,
  Qatar: 179,
  Singapore: 197,
  Taiwan: 213,
  Thailand: 216,
  Turkey: 223,
  "United Arab Emirates": 229,
  Brazil: 31,
  Colombia: 48,
  Peru: 173,
  Uruguay: 233,
  Venezuela: 236,
  Australia: 14,
  Fiji: 72,
  "French Polynesia": 76,
  "New Zealand": 158,
  Vanuatu: 235,
  Egypt: 64,
  "Guinea-Bissau": 92,
  Seychelles: 195,
  "South Africa": 202,
};

// numberToRegionName.js
export const numberToRegionName = Object.fromEntries(
  Object.entries(regionDataNumbers).map(([region, number]) => [number, region])
);
