import React, { Component } from "react";
import { connect } from "react-redux";
import { locationActions, alertActions } from "../../_actions";
import { Grid, Card, CardContent, MenuItem } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { TabPanel } from "../../components/GeneralComponent/LanguageTabs";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { withRouter } from "../../_helpers";
import countries from "../../data/countries";
import SelectValidator from "../../components/SelectValidatorElement";
import Editor from "../../components/Editor";

class EditLocationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location_heading_tabindex: 0,
      location_description_tabindex: 0,
      location: {
        id: 0,
        country: "",
        city_name: "",
        heading_en: "",
        description_en: "",
      },
      receivedData: false,
    };
  }

  componentDidMount() {
    this.props.clearAlerts();
    this.props.getLocationById(this.props.params.id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location } = nextProps.location;
    if (
      location &&
      Object.keys(location).length &&
      location.id !== prevState.location.id
    ) {
      return {
        location: {
          id: location.id,
          city_name: location.city_name,
          country: location.country,
          heading_en: location.location_heading["en"],
          description_en: location.location_description["en"],
        },
        location_heading_tabindex: prevState.location_heading_tabindex
          ? prevState.location_heading_tabindex
          : 0,
        location_description_tabindex: prevState.location_description_tabindex
          ? prevState.location_description_tabindex
          : 0,
        receivedData: !prevState.receivedData,
      };
    }
    return null;
  }

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleLocationDescEditorChange = (event) => {
    var fieldName = event.target.id.split("-")[1];
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleLocationTabChange = (locationInputName) => (event, newValue) => {
    this.setState({
      [locationInputName]: newValue,
    });
  };

  handleLocationDescEditorChange = (event) => {
    var fieldName = event.target.id.split("-")[1];
    var fieldValue = event.target.getContent();
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        [fieldName]: fieldValue,
      },
    }));
  };

  submit = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.props
          .updateLocationById(this.state.location, this.props.location.location)
          .then((value) => {
            setTimeout(() => {
              this.props.clearAlerts();
              this.props.navigate("/dashboard/locations");
            }, 2000);
          });
      }
    });
  };

  handleSubmit = () => {
    this.form.submit();
  };

  render() {
    const { alert } = this.props;
    const {
      location,
      location_heading_tabindex,
      location_description_tabindex,
      receivedData,
    } = this.state;

    return (
      <div className="main-content edit-location-page">
        <DasboardNavBarComponent pageName="manage-location" />
        <Card>
          <CardContent>
            {alert.message && (
              <NotificationBox
                open={true}
                variant={alert.type}
                message={alert.message}
              />
            )}
            <ValidatorForm
              className="default-form location-form edit-location-form"
              onSubmit={this.handleSubmit}
              ref={(r) => {
                this.form = r;
              }}
              autoComplete="off"
              instantValidate
            >
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextValidator
                    fullWidth
                    value={location.city_name}
                    onChange={this.handleChange}
                    id="text-name"
                    name="city_name"
                    className="form-control"
                    label="Location City *"
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <SelectValidator
                    name="country"
                    value={location.country}
                    onChange={this.handleChange}
                    fullWidth
                    displayEmpty
                    className="form-control country-select"
                    validators={["required"]}
                    errorMessages={"Country is required!"}
                  >
                    <MenuItem value="" disabled>
                      Country *
                    </MenuItem>
                    {countries.length > 0 &&
                      countries.map((country, index) => (
                        <MenuItem key={index} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TabPanel value={location_heading_tabindex} index={0}>
                    <TextValidator
                      fullWidth
                      value={location.heading_en}
                      onChange={this.handleChange}
                      id="text-heading_en"
                      name="heading_en"
                      className="form-control"
                      label="Location Heading"
                    />
                  </TabPanel>
                  <TabPanel value={location_heading_tabindex} index={1}>
                    <TextValidator
                      fullWidth
                      value={location.heading_de}
                      onChange={this.handleChange}
                      id="text-heading_de"
                      name="heading_de"
                      className="form-control"
                      label="Location Heading DE"
                    />
                  </TabPanel>
                </Grid>
                <Grid item md={12} xs={12}>
                  <p className="location-description-lable">
                    Location Description
                  </p>
                  <TabPanel value={location_description_tabindex} index={0}>
                    <Editor
                      label="Description *"
                      name="description_en"
                      id={"text-description_en_"}
                      value={location.description_en}
                      onChange={this.handleLocationDescEditorChange}
                      plugins="autolink link image lists preview"
                      toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                      height={250}
                    />
                  </TabPanel>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.submit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapState(state) {
  const { alert, location } = state;
  return { alert, location };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  getLocationById: locationActions.getLocationById,
  updateLocationById: locationActions.updateLocationById,
};

const connectedPage = withRouter(
  connect(mapState, actionCreators)(EditLocationPage)
);
export { connectedPage as EditLocationPage };
