import { Box } from "@material-ui/core";
import React from "react";
import CountUp from "react-countup";

const CounterUp = ({ start, end }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <CountUp
        start={start}
        end={end}
        duration={2.75}
        // separator=" "
        // decimals={4}
        // decimal=","
        // prefix="EUR "
        // suffix=" left"
        // onEnd={() => console.log("Ended!")}
        // onStart={() => console.log("Started!")}
      >
        {/* {({ countUpRef, start }) => (
          <div>
            <span ref={countUpRef} />
            <button onClick={start}>Start</button>
          </div>
        )} */}
      </CountUp>
    </Box>
  );
};

export default CounterUp;
