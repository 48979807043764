import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import "../styles.scss";

class DeleteModal extends Component {
  render() {
    const { open } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="modal-delete">
            <Typography
              align="left"
              color="initial"
              variant="h5"
              className="modal-delete-title"
            >
              {`Delete ${this.props.content}`}
            </Typography>

            <Typography
              align="center"
              color="initial"
              variant="body1"
              className="modal-delete-content"
            >
              {`Are you sure you want to delete this ${this.props.content}?`}
            </Typography>

            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              className="bttn-group"
            >
              <Button
                className="bttn bttn-cancel"
                onClick={this.props.handleClose}
              >
                Cancel
              </Button>
              <Button
                className="bttn bttn-delete"
                onClick={this.props.handleDelete}
              >
                Delete
              </Button>
            </ButtonGroup>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default DeleteModal;
