import { Box, Link, Typography } from "@material-ui/core";
import { Fragment } from "react";
import style from './style.module.scss';

const WelcomeBuyer = () => {

    return (
        <Fragment>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'flex-start', alignItems: 'flex-start'}}>   
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="h4" component="h1">GET STARTED - BUYER</Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="p">Welcome to <b>Immobilium</b>, where your success is our priority!</Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Discover Your Dream Property</Typography>
                    <Typography variant="p" component="p">Immobilium offers a global marketplace for buyers seeking diverse real estate options. 
                        Our platform provides access to properties worldwide, from residential homes to commercial spaces, ensuring you find the perfect fit for your needs.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Efficient and Secure Transactions</Typography>
                    <Typography variant="p" component="p">Experience a seamless buying process with our blockchain-powered platform. Transactions are fast, secure, 
                        and compliant, allowing you to purchase property with confidence using both fiat and cryptocurrencies.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Expert Guidance</Typography>
                    <Typography variant="p" component="p">Our network of real estate professionals is here to assist you. From property valuations to navigating legal 
                        requirements, our experts provide valuable insights and support throughout your buying journey.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Global Network</Typography>
                    <Typography variant="p" component="p">With hubs in 46 countries and expanding, Immobilium connects you to a wide range of international properties. 
                        Benefit from our extensive network and discover opportunities in top global real estate markets. 
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="p">Discover your perfect property</Typography>
                </Box>
                <Box m={0} pt={3}>
                <Link href="/marketplace" color="default" className={style.btn}>
                    BUY NOW
                </Link>
                </Box>
            </Box>
        </Fragment>
    );
}

export default WelcomeBuyer;