import React, { useEffect } from "react";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import PriceFilter from "./Filters/PriceFilter";
import LengthFilter from "./Filters/LengthFilter";
import YearFilter from "./Filters/YearFilter";
import RegionFilter from "./Filters/RegionFilter";
import BoatTypeFilter from "./Filters/BoatTypeFilter";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const YachtFilters = ({ params }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState(null);

  const handleClick = (filter) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => activeFilter !== filter || !prev);
    setActiveFilter(filter);
  };

  return (
    <div className="yacht-filters">
      <div className="yacht-filters-wrapper">
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "price" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("price")}
        >
          price
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "length" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("length")}
        >
          length
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "year" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("year")}
        >
          year
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "region" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("region")}
        >
          region
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "type" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("type")}
        >
          boat type
        </Button>
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {/* <ClickAwayListener onClickAway={handleClickAway}> */}
            <Paper>
              {activeFilter === "price" && <PriceFilter params={params} />}
              {activeFilter === "length" && <LengthFilter params={params} />}
              {activeFilter === "year" && <YearFilter params={params} />}
              {activeFilter === "region" && <RegionFilter params={params} />}
              {activeFilter === "type" && <BoatTypeFilter params={params} />}
            </Paper>
            {/* </ClickAwayListener> */}
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default YachtFilters;
