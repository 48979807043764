import React from "react";
import BuyHome from "../../assets/images/buy-home-icon.svg";
import SellHome from "../../assets/images/sell-home.svg";
import InvestHome from "../../assets/images/invest-home.svg";

const IntroSection = () => {
  return (
    <section className="intro-section">
      <div className="intro-container">
        <h2>Discover how we can help</h2>
        <p>
          Explore the world's largest selection of home listings, with the most
          reliable information. All at your fingertips.
        </p>
        <div className="intro-row">
          <div className="intro-col">
            <div className="intro-icon">
              <img src={BuyHome} alt="buy icon" />
            </div>
            <h4 className="intro-title">Buy a home</h4>
            <p className="intro-content">
              Find diverse homes, exclusive deals, expert advice, and smooth
              transactions.
            </p>
          </div>

          <div className="intro-col">
            <div className="intro-icon">
              <img src={SellHome} alt="sell icon" />
            </div>
            <h4 className="intro-title">Sell a home</h4>
            <p className="intro-content">
              Sell your home with confidence, backed by our comprehensive
              support and expertise.
            </p>
          </div>

          <div className="intro-col">
            <div className="intro-icon">
              <img src={InvestHome} alt="invest icon" />
            </div>
            <h4 className="intro-title">Invest</h4>
            <p className="intro-content">
              Invest wisely with expert guidance and tailored strategies for
              successful home investments.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
