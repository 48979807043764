import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import Lottie from "react-lottie";
import greenHouse from "../assets/animations/green-house-in-circle.json";
import dollarHouseCoin from "../assets/animations/dollar-house-coin.json";
import assembledHouse from "../assets/animations/assembled-house.json";
import magnifier from "../assets/animations/magnifier.json";
import bigMobile from "../assets/animations/big-mobile.json";
import ChatGptParagraph from "../components/ChatGptParagraph";
import createLottieOptions from "../_helpers/lottieOptions";

const HowItWorks = () => {
  const greenHouseOptions = createLottieOptions(greenHouse);
  const dollarHouseCoinOptions = createLottieOptions(dollarHouseCoin);
  const assembledHouseOptions = createLottieOptions(assembledHouse);
  const magnifierOptions = createLottieOptions(magnifier);
  const bigMobileOptions = createLottieOptions(bigMobile);
  return (
    <Box sx={{ my: 10 }} className="page-box">
      <Container>
        <Typography variant="h4" paragraph={true} align="center">
          How It Works
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Our Listing Is Your Listing
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={greenHouseOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                On Immobilium, agents and property owners can post properties
                for sale.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Unlike traditional real estate agencies, where agents can only
                sell properties that belong to their own company, on Immobilium,
                agents and owners can post any property they want for buyers to
                see and purchase. This means that even if you are an agent from
                one company, you can still make money by helping a buyer
                purchase a property that's listed by another agent or company on
                the Immobilium platform. We call this feature "our listings are
                your listings". This makes it easier for buyers to find the
                property they want, and it gives agents more opportunities to
                earn money by helping buyers find and purchase the right
                property.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ChatGptParagraph
          title="this feature"
          text="So, you know how sometimes people want to buy houses, right? On
          Immobilium, there are lots of houses and other buildings for
          sale. And, if someone wants to buy a house that's on Immobilium,
          they can ask any person who works with Immobilium to help them
          buy it. It doesn't matter if the house is owned by someone else
          or if it's being sold by a different company. The person who
          helps the buyer buy the house will still get some money, even if
          they don't work for the company that's selling the house. It's
          like everyone is helping each other sell and buy houses, so that
          everyone can be happy!"
        />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Immobilium Finance, financing Your Property Purchase in the USA
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie
                  options={dollarHouseCoinOptions}
                  height={300}
                  width={300}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                Immobilium is committed to helping buyers from all over the
                world find and purchase properties on our platform. If you're
                interested in purchasing a property in the USA, we can help you
                access financing options that might not be available elsewhere.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                One of these options is a hard money loan, which is a short-term
                loan secured by the property you're buying. Hard money loans are
                typically provided by private lenders who are willing to take on
                the higher risk associated with these loans in exchange for
                higher interest rates and fees.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Here are some benefits of using a hard money loan to finance
                your property purchase in the USA:
                <List className="bullet-list">
                  <ListItem>
                    Fast approval: Hard money loans can often be approved in
                    just a few days, which can be especially beneficial if you
                    need to move quickly to secure a property.
                  </ListItem>
                  <ListItem>
                    Flexible terms: Hard money lenders are often willing to be
                    flexible with their terms, allowing you to customize your
                    loan to meet your specific needs.
                  </ListItem>
                  <ListItem>
                    No credit score requirements: Hard money lenders are
                    primarily concerned with the value of the property you're
                    buying, rather than your credit score. This means that even
                    if you have poor credit, you may still be able to qualify
                    for a hard money loan.
                  </ListItem>
                  <ListItem>
                    More purchasing power: Because hard money loans are based on
                    the value of the property, rather than your income or credit
                    score, you may be able to qualify for a larger loan than you
                    would with a traditional mortgage.
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                If you're interested in exploring your financing options for a
                property purchase in the USA, please contact us to learn more.
                We work with authorised and reputable service providers to help
                you access the financing you need to make your property dreams a
                reality.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ChatGptParagraph
          title="Property Purchase in the USA"
          text="When you want to buy a toy, you usually need to have money to pay
          for it. But sometimes, you might not have enough money to buy the
          toy you want. So, you ask someone who has more money to lend you
          some money. This is called a loan. When grown-ups want to buy a house, they also need money. Sometimes,
          they might not have enough money to buy the house they want. So,
          they can also ask someone who has more money to lend them some
          money. This is called a home loan or a mortgage. Now, in the USA, there are some special loans called hard money
          loans that can help people buy a house even if they can't get a
          traditional home loan from a bank. They can help people buy a house
          faster, without needing to go through a bank, and they can also help
          people who might not have a good credit score."
        />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Off Market Properties
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie
                  options={assembledHouseOptions}
                  height={300}
                  width={300}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                Off-market properties are real estate properties that are not
                publicly advertised or listed for sale. Instead, they are
                typically only known to a select group of individuals, such as
                real estate agents, investors, or a specific group of buyers.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Owners of off-market properties may choose to keep the sale of
                their property private for various reasons, such as privacy
                concerns, or to test the market before making a public listing.
                As a result, off-market properties are often exclusive and can
                be difficult to find without insider knowledge or access to
                specialized networks.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                For buyers, off-market properties can offer a unique opportunity
                to find a property that is not widely available on the market.
                These properties may be less competitive to purchase and could
                potentially offer better value or greater investment potential.
                However, it is important for buyers to have a trusted source of
                information and due diligence when considering off-market
                properties, as the lack of public information may pose
                additional risks or challenges.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ChatGptParagraph
          title="off market properties"
          text="Off-market properties are like secret properties that people don't
          talk about a lot. They're not for sale in a store or on a website
          like normal properties, so you have to look in special places to
          find them. Sometimes people don't want to sell their property to
          just anyone, so they only tell their friends and some special agents
          that they're willing to sell. These secret properties can be very
          special and unique, but you have to know the right people to find
          them."
        />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Property Inspections
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={magnifierOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                A property inspection is a service that provides a thorough
                examination of a property before someone buys it.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The inspection is usually performed by a licensed professional
                who is trained to look for any potential problems or issues with
                the property. They'll inspect the structure, foundation, roof,
                plumbing, electrical, and more to make sure everything is
                working properly and there are no safety concerns.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The inspection report provides a detailed description of the
                property's condition, listing any issues found and their
                severity. This helps the buyer make an informed decision about
                whether or not to purchase the property, and can also help
                negotiate the price based on any repairs or maintenance needed.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                In the USA, property inspections are typically not required by
                law, but they are highly recommended by real estate
                professionals. They can help protect buyers from unexpected
                costs and potential hazards, and can also give them peace of
                mind knowing that they are making a sound investment.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                At Immobilium, we organize property inspections for buyers as
                part of our commitment to transparency and ensuring that buyers
                have all the information they need to make an informed decision.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ChatGptParagraph
          title="Property inspections"
          text="When you go to the store to buy toys, you want to make sure that the
          toy you get is in good shape and that it works well. In the same
          way, when someone wants to buy a house, they want to make sure that
          the house is in good shape and that it doesn't have any problems.
          So, before they buy the house, they have someone called an inspector
          check the house to make sure it's okay. The inspector checks
          everything from the roof to the basement, and they make a report for
          the person who wants to buy the house. That way, the buyer can be
          sure that they're getting a good house."
        />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            1031 Exchange
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={bigMobileOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                A 1031 exchange is a tax-deferred exchange that allows real
                estate investors to defer paying taxes on capital gains when
                they sell one property and use the proceeds to buy another
                "like-kind" property.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The term "1031" refers to Section 1031 of the United States
                Internal Revenue Code, which outlines the rules for this type of
                exchange. The basic idea behind a 1031 exchange is that the
                investor can sell their existing property and use the proceeds
                to purchase a replacement property of equal or greater value,
                without paying any capital gains taxes on the sale of the
                original property.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Instead, the taxes are deferred until the investor sells the
                replacement property, at which point they can use another 1031
                exchange to defer the taxes again.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                There are certain rules and guidelines that must be followed in
                order to qualify for a 1031 exchange, and investors must work
                with a qualified intermediary to facilitate the exchange.
                Additionally, not all types of property are eligible for 1031
                exchanges, and there are strict timelines and deadlines that
                must be met in order to successfully complete the exchange.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Overall, a 1031 exchange can be a powerful tool for real estate
                investors to build and manage their portfolios, while minimizing
                their tax liabilities and maximizing their returns.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ChatGptParagraph
          title="1030 Exchange"
          text="Sometimes when you have something, like a toy or a candy, you might
          want to trade it for something else. This is called an exchange.The 1031 exchange is when grown-ups do a special kind of exchange,
          but with houses or buildings they own. It's called '1031' because
          it's a special rule in the grown-up law that has that number.When they do a 1031 exchange, they can sell their old house or
          building, and use the money to buy a new house or building without
          having to pay a lot of taxes on the money they earned from selling
          the old one. It's like trading your toy for a new one, but without
          having to give away some of your candy."
        />
      </Container>
    </Box>
  );
};

export default HowItWorks;
