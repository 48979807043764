import { Box } from "@material-ui/core";

export const SectionLineComponent = (props) => {
  const { mt = 7, mb = 7, mr = 0, ml = 0 } = props;

  const styles = {
    mt,
    mb,
    mr,
    ml,
    height: "7px",
    backgroundColor: "#ECF9FF",
  };

  return <Box sx={styles}></Box>;
};
