import { useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MessageValidator from "../Form/MessageValidator";
import { Box, Button, CircularProgress } from "@material-ui/core";

import "./style.scss";
import { userService } from "../../_services";
import NotificationBox from "../GeneralComponent/NotificationBox";

const ContactFormComponent = () => {
  const ref = useRef(null);
  const refTimeout = useRef(null);
  const msgTimeout = 5000;

  const [data, setData] = useState({
    email: "",
    message: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });

  const [busy, setBusy] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    const res = await userService.sendContactForm(
      data.email,
      data.message,
      data.phone,
      data.firstName,
      data.lastName
    );
    setBusy(false);
    if (!res.status) {
      setAlert({
        message: res.message,
        type: "error",
      });
    } else {
      setAlert({
        message: res.message,
        type: "success",
      });
      setData({
        email: "",
        message: "",
      });
      ref.current.resetValidations();
    }

    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }

    refTimeout.current = setTimeout(() => {
      setAlert({
        message: "",
        type: "",
      });
    }, msgTimeout + 2000);
  };

  const handleChange = (e) => {
    const d = { ...data };
    d[e.target.name] = e.target.value;
    setData(d);
  };

  useEffect(() => {
    return () => {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
      }
    };
  }, []);

  return (
    <div className="contact-property">
      <div className="contact-property__info contact__form">
        <ValidatorForm
          className="form"
          id="property-contact-form"
          onSubmit={handleSubmit}
          ref={ref}
          style={{ marginTop: "10px", padding: "0" }}
          autoComplete="off"
        >
          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
              timeout={msgTimeout}
              style={{ zIndex: 1000000 }}
            />
          )}
          <div className="coctactformGroup">
            <TextValidator
              fullWidth
              value={data.firstName || ""}
              onChange={handleChange}
              name="firstName"
              className="form-control"
              id="outlined-name"
              label="First name"
              margin="dense"
              variant="outlined"
              validators={["required"]}
              errorMessages={[
                "this field is required",
                "First name is not valid",
              ]}
            />
            <TextValidator
              fullWidth
              value={data.firstName || ""}
              onChange={handleChange}
              name="lastName"
              className="form-control"
              id="outlined-name"
              label="Last name"
              margin="dense"
              variant="outlined"
              validators={["required"]}
              errorMessages={[
                "this field is required",
                "Last name is not valid",
              ]}
            />
          </div>
          <TextValidator
            fullWidth
            value={data.email || ""}
            onChange={handleChange}
            name="email"
            className="form-control"
            id="outlined-name"
            label="Email"
            margin="dense"
            variant="outlined"
            validators={["required", "isEmail"]}
            errorMessages={[
              "this field is required",
              "Email address is not valid",
            ]}
          />
          <TextValidator
            fullWidth
            value={data.email || ""}
            onChange={handleChange}
            name="phone"
            className="form-control"
            id="outlined-name"
            label="Phone"
            margin="dense"
            variant="outlined"
            validators={["required"]}
            errorMessages={[
              "this field is required",
              "Phone number is not valid",
            ]}
          />
          <MessageValidator
            fullWidth
            value={data.message || ""}
            onChange={handleChange}
            name="message"
            className="form-control"
            id="outlined-name"
            label="Message"
            margin="dense"
            variant="outlined"
            validators={[
              "required",
              "isString",
              "minStringLength:15",
              "maxStringLength:1000",
            ]}
            errorMessages={[
              "this field is required",
              "Message must be a string",
            ]}
          />
          {busy ? (
            <Box sx={{ display: "flex", padding: "34px 0" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              variant="outlined"
              color="default"
              // className="btn-primary btn-filled-out btn-submit"
              style={{
                backgroundColor: "#2BAAEA",
                borderRadius: "4px",
                color: "#fff",
                border: "none",
                fontSize: "16px",
                textTransform: "capitalize",
                width: "119px",
                height: "48px",
                margin: "5px 0 25px",
                fontWeight: 500,
              }}
            >
              Submit
            </Button>
          )}
        </ValidatorForm>
      </div>
    </div>
  );
};

export default ContactFormComponent;
