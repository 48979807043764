import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Popper,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { userActions } from "../../../_actions";
import "../styles.scss";
import HeaderLogo from "./HeaderLogo";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOpen: false,
      profile_data: {},
    };

    this.loggedUser = false;

    var tokenInfo = this.props.parseJwt();
    if (tokenInfo && tokenInfo.status) {
      this.loggedUser = tokenInfo.data;
    }
    this.anchorRef = createRef();
  }

  componentDidMount() {
    const { page } = this.props;
    if (page !== "profile") this.props.getUserDetails();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.users &&
      nextProps.users.profile_data &&
      nextProps.users.profile_data !== prevState.profile_data
    ) {
      return {
        profile_data: nextProps.users.profile_data,
      };
    }

    return null;
  }

  logOut = () => {
    this.props.logOut();
  };

  handleToggle = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
  };

  handleClose = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({ dropDownOpen: false });
  };

  composeEmail = () => {
    const location = window.location;

    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const currentDate = `${day}/${month}/${year}`;
    const currentTime = date.toLocaleTimeString();

    const email = process.env.REACT_APP_INFO_EMAIL;
    let subject = `Website inquiry (${currentDate} ${currentTime})`;
    let body = "";

    const propertyViewPattern = /\/property\/view\/\d{1,}/;
    if (propertyViewPattern.test(location.pathname)) {
      subject = `Property inquiry (${currentDate} ${currentTime})`;
      body = location.href;
    }

    window.open(`mailto:${email}?subject=${subject}&body=${body}`);
  };


  render() {
    const { profile_data } = this.state;
    const isProfileData = profile_data && Object.keys(profile_data).length;

    return (
      <List className="header-links">
        <ListItem className="nav-link">
          <ListItem>
            <HeaderLogo user={this.props.users} />
          </ListItem>
          <ListItemText>
            <NavLink to="/marketplace" className="nav-text">
              Buy
            </NavLink>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <NavLink to="/faq">FAQ</NavLink>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </ListItemText>
        </ListItem>
        <ListItem
          ref={this.anchorRef}
          className="nav-link"
          aria-controls="menu-list-grow"
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <div>
            {isProfileData && profile_data.profile_image ? (
              <div>
                <img
                  alt="user-avtar"
                  className="user-avtar-img"
                  src={this.state.profile_data.profile_image}
                />{" "}
              </div>
            ) : (
              <div className="user-avtar-img">
                <PersonIcon color="secondary" />
              </div>
            )}
          </div>
          <span className="text-username">{this.loggedUser.first_name}</span>

          <Popper
            className="custom-dropdown"
            open={this.state.dropDownOpen}
            anchorEl={this.anchorRef.current}
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem>
                        <NavLink to="/dashboard/profile">
                          <span>Profile</span>
                        </NavLink>
                      </MenuItem>
                      <MenuItem onClick={this.logOut}>
                        <Link underline="none">
                          <span>Logout</span>
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ListItem>
      </List>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  parseJwt: userActions.parseJwt,
  getUserDetails: userActions.getUserDetails,
  logOut: userActions.logout,
};

const connectedHeaderLinks = connect(mapState, actionCreators)(HeaderLinks);
export { connectedHeaderLinks as HeaderLinks };
