import { Box } from "@material-ui/core";
import React from "react";
import CountUp from "react-countup";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Counter = ({ start, end }) => {
  const matches = useMediaQuery("(min-width:660px)");

  return (
    <Box
      sx={{
        textAlign: "left",
        fontSize: matches ? "60px" : "48px",
        fontWeight: 600,
      }}
    >
      <CountUp
        start={start}
        end={end}
        duration={2.75}
        // separator=" "
        // decimals={4}
        // decimal=","
        // prefix="EUR "
        // suffix=" left"
        // onEnd={() => console.log("Ended!")}
        // onStart={() => console.log("Started!")}
      >
        {/* {({ countUpRef, start }) => (
          <div>
            <span ref={countUpRef} />
            <button onClick={start}>Start</button>
          </div>
        )} */}
      </CountUp>
    </Box>
  );
};

export default Counter;
