import { Container, Box, Divider, Typography, Grid } from "@material-ui/core";
import Lottie from "react-lottie";
import buildingImg from "../assets/images/buildings.png";
import lock from "../assets/animations/lock.json";
import realEstate from "../assets/animations/real-estate.json";
import houseForSale from "../assets/animations/house-for-sale.json";
import ChatGptParagraph from "../components/ChatGptParagraph";
import createLottieOptions from "../_helpers/lottieOptions";

const AboutUs = () => {
  const lockOptions = createLottieOptions(lock);
  const realEstateOptions = createLottieOptions(realEstate);
  const houseForSaleOptions = createLottieOptions(houseForSale);
  return (
    <Box sx={{ my: 10 }} className="page-box">
      <Container>
        <Typography variant="h4" paragraph={true} align="center">
          What Is Immobilium?
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What Is Immobilium?
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Immobilium is a cutting-edge technology platform designed to
            transform the real estate industry by providing a streamlined and
            efficient process for buying and selling properties. The platform is
            built on a proprietary blockchain ecosystem that enables instant and
            secure asset-to-asset transactions, using both fiat and
            cryptocurrencies.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Traditionally, real estate transactions can be time-consuming and
            expensive due to the many layers of regulations, documentation,
            certifications, and third-party fees that are involved. Immobilium
            aims to solve these challenges by providing a one-stop-shop for all
            the necessary services, including escrow, title insurance, property
            management, and compliance services, such as KYC and AML.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            By integrating these services, Immobilium offers a seamless and
            smooth user experience that is both compliant and secure. With
            Immobilium, you can complete a real estate transaction in minutes,
            instead of weeks, and with significantly reduced fees.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Overall, Immobilium is a game-changer for the real estate industry,
            as it provides a global, compliant, and secure platform for buying
            and selling properties, making it accessible to everyone, from
            individuals to institutional investors.
          </Typography>
          <img src={buildingImg} alt="Buildings" className="responsive-img" />
        </Box>
        <ChatGptParagraph
          title="Immobilium"
          text="Immobilium is like a big toy store where people can buy and sell
                houses from all over the world. It's a special toy store because
                it uses special technology to make buying and selling houses
                much easier and faster. So, instead of waiting for a long time
                to buy or sell a house, you can do it quickly and easily on the
                Immobilium platform. And don't worry, it's a safe and secure toy
                store too because they have special tools to make sure
                everything is okay."
        />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Security And Comfort Based Real Estate Transactions
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={lockOptions} height={350} width={350} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                Realizing the importance of a Human factor, in order to transact
                with International properties, we have put together and created
                a unique global real estate network.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Our Network involves as a group of real estate professionals and
                companies from around the world that collaborate and share
                resources to facilitate real estate transactions across borders.
                So far we have Hubs and Headquarters in 24 countries and
                growing, projected to be in 40 Countries this0 summer, and 80
                top global Real Estate markets by the end of 2023. The
                Immobilium Hub network is built on top of our centralized
                platform system, for managing property listings, transactions,
                and communication between members.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The main goal of the Immobilium global real estate network is to
                provide a seamless and efficient way for buyers, sellers, and
                agents to access a wider pool of real estate opportunities
                worldwide. This could involve connecting buyers with
                international properties that meet their criteria or helping
                sellers market their properties to a global audience. Each of
                our Hubs is represented and supported by the partnering teams,
                lawyers, Notary public, and all the other necessary local area
                collaborators. The protocols, rules, and regulations in each
                Country are very different, therefore we have adjusted our
                processes based on their requests which serve as a more credible
                transaction concept.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            We Give Power To The Agents
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={realEstateOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                Seeing what other platforms did to minimize the exposure of
                Agents, such are Redfin, Zillow, etc. we made sure to do the
                exact opposite and embrace all of you.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Transparency and acknowledgment that the Real Estate industry
                can not thrive without Agents, made us emphasize you guys even
                more, instead of hiding the access to you all. This gives
                Immobilium another sense of Credibility or as we say an
                additional "Stamp of Approval". To make this possible, the
                network has established a set of standards and best practices
                for real estate transactions across different countries and
                regions. This has involved addressing legal and regulatory
                issues, currency exchange, and cultural differences that may
                affect how real estate deals are negotiated and completed. We
                work and follow the local governing rules and regulations.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Overall, our real estate network provides significant benefits
                to both buyers and sellers, as well as real estate agents and
                brokers, by expanding their access to a wider pool of
                opportunities and resources and streamlining the real estate
                transaction process across different countries and regions.
              </Typography>
              <Typography>
                We are here to ADDAPT and not DISRUPT the system.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What Else Do We Offer?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie
                  options={houseForSaleOptions}
                  height={300}
                  width={300}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                The Immobilium Network has been created around the most
                sophisticated Real Estate platform on the market today.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The network connects real estate professionals and property
                owners across the world, enabling them to easily find and
                transact with one another by offering them Security and Comfort
                at the same time.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>
                  <u>Listings:</u>
                </strong>{" "}
                A Global Real Estate network would feature property listings
                from around the world, allowing potential buyers and tenants to
                search for properties that match their criteria. These listings
                could include everything from apartments and houses to
                commercial buildings and land.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>
                  <u>Agents:</u>
                </strong>{" "}
                The network would likely include a directory of real estate
                agents and brokers from various countries who specialize in
                helping buyers and sellers navigate the local real estate
                market. These agents could provide valuable insights and
                guidance on everything from property valuations to legal and
                regulatory issues.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>
                  <u>Tools and Resources:</u>
                </strong>{" "}
                A Global Real Estate network would likely offer a range of tools
                and resources to help users with their property transactions.
                These could include online property valuation tools, mortgage
                calculators, legal and financial resources, and more.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>
                  <u>Security and Privacy:</u>
                </strong>{" "}
                A Global Real Estate network would need to ensure that user data
                is secure and that transactions are conducted safely and
                securely. This could involve using advanced encryption
                technologies, multi-factor authentication, and other security
                measures to protect user data.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Overall, a Global Real Estate network would be a powerful tool
                for anyone looking to buy, sell, or rent property anywhere in
                the world. By connecting real estate professionals and property
                owners from around the globe, such a network could make it
                easier and more efficient to conduct real estate transactions
                across borders and across different languages and cultures.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;
