import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import "./styles.scss";

class RegistrationSuccessModal extends Component {
  render() {
    const { open } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="modal-success">
            <Typography align="center" color="initial" variant="body2">
              Thanks for registering with Immobilium! We sent you an email to
              the email address you provided, please click the link in that
              email to verify your email address before you can log in.
            </Typography>

            <Typography align="center" variant="body2">
              <Link to="/login">
                <Button
                  variant="outlined"
                  color="default"
                  className="btn-primary btn-login btn-width-300"
                >
                  Ok, I understand
                </Button>
              </Link>
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default RegistrationSuccessModal;
