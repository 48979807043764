import React, { useEffect } from "react";
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "react-google-autocomplete";
import "../styles.scss";

const DirectPropertyDetailEdit = ({ property, onUpdate, onUpdateLocation }) => {
  useEffect(() => {
    // Add the custom rule in a useEffect or componentDidMount hook
    ValidatorForm.addValidationRule("isPositive", (value) => {
      return parseFloat(value) > 0;
    });
  }, []);

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    onUpdate({
      [fieldName]: fieldValue,
    });
  };

  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;
    let address_postcode;
    let address_city;
    let address_country;
    addressComponents.forEach((component) => {
      if (component.types.includes("locality")) {
        address_city = component.long_name;
      } else if (component.types.includes("postal_code")) {
        address_postcode = component.long_name;
      } else if (component.types.includes("country")) {
        address_country = component.long_name;
      }
    });

    onUpdate({
      address_line1: place.formatted_address || "",
      address_postcode: address_postcode || "",
      address_city: address_city || "",
      address_country: address_country || "",
    });

    if (place && place.geometry && place.geometry.location) {
      var location_cords = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };

      onUpdateLocation && onUpdateLocation(location_cords);
    }
  };

  const handleGoogleAutocompleteChange = (event) => {
    onUpdate({
      address_line1: event.target.value,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.title}
            onChange={handleChange}
            id="text-name"
            name="title"
            className="form-control"
            label="Property Title *"
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Property type</InputLabel>
            <Select
              id="text-property_category_type"
              name="property_category_type"
              value={property.property_category_type}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="apartment">Apartment</MenuItem>
              <MenuItem value="condo">Condo</MenuItem>
              <MenuItem value="penthouse">Penthouse</MenuItem>
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="maisonette">Maisonette</MenuItem>
              <MenuItem value="land">Land</MenuItem>
              <MenuItem value="hotel">Hotel</MenuItem>
              <MenuItem value="building">Building</MenuItem>
              <MenuItem value="villa">Villa</MenuItem>
              <MenuItem value="duplex">Duplex</MenuItem>
              <MenuItem value="office space">Office Space</MenuItem>
              <MenuItem value="garage">Garage</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
          <TextValidator
            fullWidth
            value={property.property_price || ""}
            onChange={handleChange}
            id="text-property_price"
            name="property_price"
            className="form-control"
            label="Property Price *"
            validators={["required", "isFloat", "isPositive"]}
            errorMessages={[
              "This field is required",
              "This field only contains numbers",
              "This field cannot be 0",
            ]}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Currency</InputLabel>
            <Select
              id="text-property_currency"
              name="property_currency"
              value={property.property_currency || "USD"}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="USD">$ USD</MenuItem>
              <MenuItem value="EUR">&#8364; EUR</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <label className="autocomplete-form-label">Address Line</label>
            <Autocomplete
              className="autocomplete-input"
              value={property.address_line1}
              onPlaceSelected={onPlaceSelected}
              onChange={handleGoogleAutocompleteChange}
              options={{
                fields: [
                  "address_components",
                  "adr_address",
                  "formatted_phone_number",
                  "icon",
                  "name",
                  "photos",
                  "vicinity",
                  "geometry.location",
                  "place_id",
                  "formatted_address",
                ],
                types: ["geocode"],
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.address_postcode}
            onChange={handleChange}
            id="text-address_postcode"
            name="address_postcode"
            className="form-control"
            label="Address Postcode"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            disabled
            value={property.address_city}
            id="text-address_city"
            name="address_city"
            className="form-control"
            label="Address City"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            disabled
            value={property.address_country}
            id="text-address_country"
            name="address_country"
            className="form-control"
            label="Address Country"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DirectPropertyDetailEdit;
export { DirectPropertyDetailEdit };
