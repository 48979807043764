import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import RoomIcon from "@material-ui/icons/Room";
import PhotoIcon from "@material-ui/icons/Photo";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { useSelector } from "react-redux";
import removePlusCode from "../../_helpers/removePlusCode";
import soldRibbonImg from "../../assets/images/sold-4v2.svg";
import BtcIcon from "../../assets/images/btn.svg";
import EthIcon from "../../assets/images/eth.svg";
import MapIcon from "../../assets/images/place.svg"

const responsive = {
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const PropertyImagesSlider = (props) => {
  const { property_images, propertyId, propertySold } = props;

  return (
    <div className="carousel-wrapper" style={{ position: "relative" }}>
      {propertySold && (
        <img className="ribbon-img" src={soldRibbonImg} alt="Property Sold" />
      )}
      <Carousel responsive={responsive}>
        {property_images.map((image, index) => {
          return (
            <div className="property-slider-container" key={index}>
              <Link to={"/property/view/" + propertyId}>
                <div
                  className="property-image"
                  style={{
                    backgroundImage:
                      `url('` +
                      image.image_path.replace("_thumbnail", "") +
                      `')`,
                  }}
                ></div>
              </Link>
            </div>
          );
        })}
      </Carousel>
      <div className="property-image-count">
        <PhotoIcon style={{ color: "white" }} /> {props.count}
      </div>
    </div>
  );
};

const PropertyFinancialBlock = (props) => {
  const { property, variant } = props;
  const coinRate = useSelector(({ general: { coinRate } }) => coinRate);
  const currencyRates = useSelector(
    ({ general: { currencyRates } }) => currencyRates
  );

  function formatPrice(val) {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "," + parts[1] : "");
      }
      val = numberWithCommas(val);

      return val;
    }
  }

  const formatCoinPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "," + parts[1] : "");
        val = val.slice(0, Math.min(val.length, 10));
      }
      return val;
    }
  };

  const formatCurrencyPrice = (propertyPrice, currencyRate) => {
    if (
      currencyRate !== undefined &&
      currencyRate !== null &&
      !isNaN(currencyRate)
    ) {
      currencyRate *= propertyPrice;
      const parts = currencyRate.toString().split(".");
      parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2).split(".")[1];
      if (parts.length > 1) {
        currencyRate =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "," + parts[1] : "");
      }
      return currencyRate;
    }
    return "-";
  };

  function numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return x;
  }

  return (
    <>
      <div className="property-financial-block">
        {variant === "primary" ? (
          <>
            <div>
              <p className="txt-value">
                &#36; {numberWithCommas(property.financing_volume)}
              </p>
              <p className="txt-title">Property Price</p>
            </div>
            <div>
              <p className="txt-value">
                &#36; {formatPrice(property.price_per_share)}
              </p>
              <p className="txt-title">Min. Investment</p>
            </div>
            <div>
              <p className="txt-value">
                {formatPrice(property.profit_payout_percent)} %
              </p>
              <p className="txt-title">Net rental return</p>
            </div>
          </>
        ) : property.property_type === "direct" ? (
          <>
            <div>
              <p className="txt-value">
                {property.property_currency === "USD" ? (
                  <>
                    <span> &#36; </span> {formatPrice(property.property_price)}{" "}
                    | <span> &euro; </span>{" "}
                    {formatCurrencyPrice(
                      property.property_price,
                      currencyRates.EUR
                    )}
                  </>
                ) : (
                  <>
                    <span> &#36; </span>{" "}
                    {formatCurrencyPrice(
                      property.property_price,
                      1 / currencyRates.EUR
                    )}
                    | <span> &euro; </span>
                    {formatPrice(property.property_price)}{" "}
                  </>
                )}
              </p>
            </div>
            <div>
              <p className="txt-value">
                {property.property_currency === "USD" ? (
                  <>
                    <span>BTC</span>{" "}
                    {coinRate?.BTC
                      ? formatCoinPrice(property.property_price / coinRate.BTC)
                      : "-"}
                  </>
                ) : (
                  <>
                    <span>BTC</span>{" "}
                    {coinRate?.BTC
                      ? formatCoinPrice(
                          (property.property_price * (1 / currencyRates.EUR)) /
                            coinRate.BTC
                        )
                      : "-"}
                  </>
                )}
              </p>
            </div>
            <div>
              <p className="txt-value">
                {property.property_currency === "USD" ? (
                  <>
                    <span>ETH</span>{" "}
                    {coinRate?.ETH
                      ? formatCoinPrice(property.property_price / coinRate.ETH)
                      : "-"}
                  </>
                ) : (
                  <>
                    <span>ETH</span>{" "}
                    {coinRate?.ETH
                      ? formatCoinPrice(
                          (property.property_price * (1 / currencyRates.EUR)) /
                            coinRate.ETH
                        )
                      : "-"}
                  </>
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              <p className="txt-value">
                &#36;{" "}
                {numberWithCommas(
                  (
                    property.current_price_per_share *
                    property.total_share_count
                  ).toFixed(0)
                )}
              </p>
              <p className="txt-title">Market Value</p>
            </div>
            <div>
              <p className="txt-value">
                &#36; {formatPrice(property.current_price_per_share)}
              </p>
              <p className="txt-title">Price per Share</p>
            </div>
            <div>
              <p className="txt-value">
                {formatPrice(
                  (
                    (property.disdivibution_surplus /
                      (property.current_price_per_share *
                        property.total_share_count)) *
                    100
                  ).toFixed(2)
                )}{" "}
                %
              </p>
              <p className="txt-title">Net rental return</p>
            </div>
          </>
        )}
      </div>
      <div className="property-location-card-container">
        {/* <RoomIcon /> */}
        <img src={MapIcon} alt="location" />
        <p className="property-location">
          <span>{removePlusCode(property.address_line1)}</span>
        </p>
      </div>
    </>
  );
};

const PropertyBoxComponent = ({
  type = "grid",
  variant,
  property,
  updateFilters,
  search,
}) => {
  const { user_id, first_name, last_name } = property.user_property_master;
  const navigate = useNavigate();
  const goToMarketplace = () => {
    // from homepage, just navigate to marketplace with agent param
    if (!(typeof updateFilters === "function")) {
      navigate(`/marketplace/?agent=${user_id}`);
    } else {
      // already on marketplace, check for agent param
      if (!search.agent) {
        updateFilters({ ...search, agent: user_id });
        const newParams = new URLSearchParams();
        const searchObj = search;
        const figuresWithValues = {};
        // remove keyFigures with both min, max 0
        searchObj["keyFigures"] &&
          Object.keys(searchObj["keyFigures"]).forEach((key) => {
            if (
              searchObj["keyFigures"][key]["min"] ||
              searchObj["keyFigures"][key]["max"]
            ) {
              figuresWithValues[key] = searchObj["keyFigures"][key];
            }
          });

        Object.keys(searchObj).forEach((key) => {
          const value = searchObj[key];
          if (typeof value === "object" && value !== null) {
            if (key !== "keyFigures") {
              newParams.append(key, JSON.stringify(value));
            } else if (Object.keys(figuresWithValues).length) {
              newParams.append(key, JSON.stringify(figuresWithValues));
            }
          } else {
            newParams.append(key, value);
          }
        });

        window.history.pushState(
          {},
          "",
          `?${newParams.toString()}&agent=${user_id}`
        );
      }
    }
  };
  return (
    <Card className={type + "-view " + variant + "-type property-card-box"}>
      <div className="property-images">
        <PropertyImagesSlider
          propertyId={property.property_id}
          property_images={(property.property_images &&
          property.property_images.length > 0
            ? property.property_images
            : []
          ).slice(0, 5)}
          count={
            property.property_images && property.property_images.length
              ? property.property_images.length
              : 0
          }
          propertySold={property.is_sold}
        />
      </div>

      <CardContent className="property-content">
        <Link to={"/property/view/" + property.property_id}>
          <PropertyFinancialBlock property={property} variant={variant} />
        </Link>
          <div className="property-details">
            <Link to={"/property/view/" + property.property_id}>
              <p className="property-name">{property.title}</p>
            </Link>
          </div>
        {/* <p className="uploaded-by">
          Property uploaded by:{" "}
          <span onClick={() => goToMarketplace()}>
            {first_name} {last_name}
          </span>
        </p> */}
      </CardContent>
    </Card>
  );
};

export default PropertyBoxComponent;
