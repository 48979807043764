import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import Lottie from "react-lottie";
import dollarHouseCoin from "../assets/animations/dollar-house-coin.json";
import createLottieOptions from "../_helpers/lottieOptions";
import hardMoneyLoans from "../assets/images/hard_money_loans.jpg";

const Loans = () => {
  const dollarHouseCoinOptions = createLottieOptions(dollarHouseCoin);
  return (
    <Box sx={{ my: 10 }} className="page-box">
      <Container>
        <Typography
          variant="h4"
          paragraph={true}
          align="center"
          className="loans-title"
        >
          Immobilium Enabled Finance
        </Typography>
        <Typography variant="h6" paragraph={true} align="center">
          Financing for transactions in the USA
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Why USA?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Strong economy:</strong> The USA has a strong and
                    diverse economy, with many industries and sectors that are
                    performing well and providing a stable foundation for real
                    estate investment. This helps to provide a stable
                    environment for real estate investment and contributes to
                    the long-term growth of the real estate market.
                  </ListItem>
                  <ListItem>
                    <strong>High demand:</strong> The US real estate market
                    continues to experience high demand, particularly in certain
                    segments and regions. This demand can drive up property
                    values and rental income, and provides a strong market for
                    investment.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Strong financial infrastructure:</strong> The USA
                    has a well-developed and accessible financial
                    infrastructure, with a wide range of financing options
                    available to real estate investors. This includes low
                    interest rates, various mortgage products, and a range of
                    financial institutions that are eager to lend to real estate
                    investors. This favorable lending environment helps to make
                    real estate investment more accessible and affordable,
                    providing a competitive advantage for those looking to
                    invest in the US real estate market.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            The financing problem for international buyers
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    Even with same name, Banks internationally operate as
                    different legal entities with different rules and
                    regulations
                  </ListItem>
                  <ListItem>
                    No track record with local (US) banks, making buyers
                    ineligible for bank financing
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    Legal and tax issues may be challenging for buyers to solve
                    before they can purchase a property
                  </ListItem>
                  <ListItem>
                    Language, culture and goals are other common challenges for
                    understanding and solving financing challenges for
                    international buyers
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            The Solution - Hard Money Loans
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie
                  options={dollarHouseCoinOptions}
                  height={300}
                  width={300}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                Hard money loans are a type of real estate financing that is
                secured by collateral, typically the property being purchased or
                renovated. They are often used by real estate investors who need
                quick access to funds or who cannot qualify for traditional bank
                loans.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>
                      Brief history of hard money loans in the USA:
                    </strong>{" "}
                    Hard money loans have been used in the US since the 1950s,
                    but they became more popular in the 1980s and 1990s as real
                    estate investing became more widespread.
                  </ListItem>
                  <ListItem>
                    <strong>
                      Benefits of using hard money loans for real estate
                      financing:
                    </strong>{" "}
                    Hard money loans can offer several benefits over traditional
                    bank loans, including faster access to funds, more
                    flexibility in loan terms, and financing for non-traditional
                    properties or borrowers who may not qualify for conventional
                    loans.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            How it works?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Overview of the lending process:</strong> Hard money
                    loans are typically issued by private lenders or small
                    lending firms. Borrowers apply for a loan and provide
                    information about the property they plan to purchase or
                    renovate, as well as their own financial history and
                    creditworthiness. Lenders evaluate the loan application and
                    determine the loan amount, interest rate, and other terms.
                  </ListItem>
                  <ListItem>
                    <strong>Eligibility requirements for borrowers:</strong>{" "}
                    Hard money lenders typically have fewer eligibility
                    requirements than traditional banks, but they may require
                    borrowers to have a certain level of equity in the property,
                    a good credit score, or some level of real estate
                    experience.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Collateral requirements:</strong> Hard money loans
                    are secured by the property being purchased or renovated,
                    and lenders may require a certain level of equity in the
                    property or other forms of collateral.
                  </ListItem>
                  <ListItem>
                    <strong>Loan terms and interest rates:</strong> Hard money
                    loans typically have shorter loan terms than traditional
                    bank loans, ranging from a few months to a few years.
                    Interest rates are generally higher than traditional bank
                    loans, but may vary depending on the lender, collateral, the
                    borrower’s informal creditworthiness, and other factors.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What can be financed?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Residential properties:</strong> Hard money loans
                    can be used to finance the purchase or renovation of
                    residential properties such as single-family homes,
                    townhouses, and condos.
                  </ListItem>
                  <ListItem>
                    <strong>Commercial properties:</strong> Hard money loans can
                    also be used to finance commercial properties such as office
                    buildings, retail spaces, and warehouses.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Investment properties:</strong> Hard money loans are
                    often used to finance investment properties, such as rental
                    properties or properties that are being flipped for a
                    profit.
                  </ListItem>
                  <ListItem>
                    <strong>Fix-and-flip properties:</strong> Hard money loans
                    are particularly well-suited for fix-and-flip properties,
                    where investors purchase a property, renovate it, and then
                    sell it for a profit within a short period of time.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What are main advantages?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Speed of funding:</strong> Hard money loans can be
                    approved and funded quickly, sometimes within a matter of
                    days, which is ideal for real estate investors who need to
                    close a deal quickly.
                  </ListItem>
                  <ListItem>
                    <strong>Less stringent eligibility criteria:</strong> Hard
                    money lenders are typically more interested in the value of
                    the property than the borrower’s credit score or financial
                    history. As a result, they may be more willing to lend to
                    borrowers who don’t qualify for traditional bank loans.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Flexibility:</strong> Hard money loans can be
                    customized to meet the borrower’s specific needs. For
                    example, they can be structured with interest-only payments
                    or balloon payments, which may be beneficial for some
                    borrowers.
                  </ListItem>
                  <ListItem>
                    <strong>No prepayment penalties:</strong> Unlike traditional
                    bank loans, hard money loans typically don’t have prepayment
                    penalties, which means borrowers can pay off the loan early
                    without incurring additional fees.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What are main disadvantages?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>High-interest rates:</strong> Hard money loans
                    typically have higher interest rates than traditional bank
                    loans, often in the double digits. This can make them
                    expensive, especially if the loan term is long.
                  </ListItem>
                  <ListItem>
                    <strong>Short loan terms:</strong> Hard money loans usually
                    have short loan terms, typically between 6 and 24 months.
                    This can be challenging for borrowers who need more time to
                    complete a project or refinance the loan.
                  </ListItem>
                  <ListItem>
                    <strong>High fees:</strong> In addition to high-interest
                    rates, hard money loans often come with high fees, such as
                    origination fees, underwriting fees, and appraisal fees.
                    These fees can add up quickly and make the loan more
                    expensive.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    <strong>Collateral requirements:</strong> Hard money lenders
                    require collateral, usually in the form of real estate, to
                    secure the loan. This means that if the borrower defaults on
                    the loan, the lender can foreclose on the property to recoup
                    their investment.
                  </ListItem>
                  <ListItem>
                    <strong>Risk of predatory lending:</strong> Some hard money
                    lenders engage in predatory lending practices, such as
                    charging excessive fees or structuring loans in a way that
                    makes it difficult for the borrower to repay the loan. It is
                    important for borrowers to do their due diligence and work
                    with reputable lenders to avoid falling victim to predatory
                    lending practices.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Examples - Commercial property purpose loans
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong style={{ fontSize: "18px", paddingLeft: "10px" }}>
                  Private Money Loans
                </strong>
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>1, 2, 5, and 15-year programs</ListItem>
                  <ListItem>Rates start at 8.99%</ListItem>
                  <ListItem>Up to 75% loan-to-value (LTV)</ListItem>
                  <ListItem>Investor to owner-user</ListItem>
                  <ListItem>
                    Asset-based or Debt Service Coverage Programs (DSCR)
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong style={{ fontSize: "18px", paddingLeft: "10px" }}>
                  Institutional Loans
                </strong>
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>12-month bridge to 20-year amortization</ListItem>
                  <ListItem>Rates from 7.50% and up</ListItem>
                  <ListItem>Up to 75% loan-to-value (LTV)</ListItem>
                  <ListItem>Vacant to fully stabilized programs</ListItem>
                  <ListItem>Investor to owner-user</ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What are my next steps?
          </Typography>
          <img
            src={hardMoneyLoans}
            alt="Hard Money Loans"
            className="responsive-img"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Loans;
