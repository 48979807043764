import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.scss";

const OverviewWidgetBlock2 = (props) => {
  function formatPrice(n) {
    var val = Math.round(Number(n) * 100) / 100;
    var parts = val.toString().split(".");
    var num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");

    return num;
  }

  function getInvestedAmountPercentage(userProfile) {
    var totalAmount = userProfile.balance + userProfile.committed_funds;
    var availableAmountPercentage = (100 * userProfile.balance) / totalAmount;
    return availableAmountPercentage;
  }

  const { user_profile } = props;
  const isProfileData = user_profile && Object.keys(user_profile).length > 0;

  return (
    isProfileData && (
      <div className="overview-widget-block-2">
        <div className="left-block">
          <div className="content-box">
            <div className="status-badge color1"></div>
            <p className="txt-value">
              &#36; {formatPrice(user_profile.balance)}
            </p>
            <p className="txt-title">Avilable Funds</p>
          </div>
          <div className="content-box">
            <div className="status-badge color2"></div>
            <p className="txt-value">
              &#36; {formatPrice(user_profile.committed_funds)}
            </p>
            <p className="txt-title">Blocked Balance</p>
          </div>
        </div>
        <div className="right-block">
          <div className="progress-bar-total-funds">
            <CircularProgressbarWithChildren
              value={getInvestedAmountPercentage(user_profile)}
              strokeWidth={4}
            >
              <p className="txt-value">
                &#36;{" "}
                {formatPrice(
                  user_profile.balance + user_profile.committed_funds
                )}
              </p>
              <p className="txt-title">Total Funds</p>
            </CircularProgressbarWithChildren>
          </div>
        </div>

        <div className="clear"></div>
      </div>
    )
  );
};

export default OverviewWidgetBlock2;
