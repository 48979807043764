import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, FormControl, MenuItem, Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ValidatorForm } from "react-material-ui-form-validator";
import { userActions, alertActions } from "../../../_actions";
import SelectValidator from "../../../components/SelectValidatorElement";
import NotificationBox from "../../../components/GeneralComponent/NotificationBox";
import "./styles.scss";

class Verification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        user_id: 0,
        kyc_type: "",
        kyc_document: "",
        is_kyc_verified: 0,
      },
      formData: {
        kyc_type: "",
        kyc_document: "",
        update_form_type: "verification",
      },
      invalidKycDocErrorMsg: "",
    };
  }

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: fieldValue,
      },
    }));
  };

  static getDerivedStateFromProps(props, state) {
    const { profile_data } = props.users;
    if (profile_data && profile_data.user_id !== state.user.user_id) {
      return {
        user: {
          kyc_type: profile_data.kyc_type,
          kyc_document: profile_data.kyc_document,
          is_kyc_verified: profile_data.is_kyc_verified,
        },
      };
    }
    return null;
  }

  uploadKycDoc = (e) => {
    let file = e.target.files[0];
    this.setState({ invalidKycDocErrorMsg: "" });
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        kyc_document: file,
      },
    }));
  };

  handleVerificationUpdate = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        if (this.state.formData.kyc_document !== "") {
          this.props.updateUserProfile(this.state.formData).then((value) => {
            setTimeout(() => this.props.clearAlerts(), 1000);
          });
        }
      }
    });
  };

  submit = () => {
    if (this.state.formData.kyc_document === "") {
      this.setState({ invalidKycDocErrorMsg: "Kyc document is required!" });
    }
    this.form.submit();
  };

  render() {
    const { alert } = this.props;
    const { user, formData, invalidKycDocErrorMsg } = this.state;

    return (
      <div className="verification-tab">
        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}

        <p className="page-block-title">Proof of Identity</p>
        {user.is_kyc_verified === 1 ? (
          <>
            <p className="page-description">
              We take the security of your money seriously - that's why we ask
              you for more information from time to time.
            </p>
            <div className="alert-success-box">
              <p>
                <strong>Successfully Verified!</strong> We have confirmed your
                identity.
              </p>
            </div>
          </>
        ) : (
          <>
            {user.kyc_type !== "" ? (
              <>
                <p className="page-description">
                  We take the security of your money seriously - that's why we
                  ask you for more information from time to time.
                </p>
                <div className="alert-success-box">
                  <p>
                    <strong>Verification is in progress!"</strong> please wait,
                    we will inform you when verified.
                  </p>
                </div>
              </>
            ) : (
              <>
                <p className="page-description">
                  Due to legal requirements concerning the verification of the
                  identity of new customers In order to prevent money
                  laundering, we need a scanned copy of your ID. Therefore, we
                  kindly ask you to upload a scanned copy of either a passport
                  or both sides of a national ID or driving license. Of course,
                  we treat all data in the strictest confidence. All documents
                  are hieing encrypted before they are saved on our PCI-DSS
                  secured servers. For the registration of companies, we also
                  need a scanned copy of an excerpt from the commercial register
                  as well as the company's statutes.
                </p>
                <div className="user-profile-form">
                  <hr className="hr-line" />
                  <ValidatorForm
                    className="default-form"
                    onSubmit={this.handleVerificationUpdate}
                    ref={(r) => {
                      this.form = r;
                    }}
                    autoComplete="off"
                    instantValidate
                  >
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12}>
                        <label className="form-label">KYC *</label>
                        <FormControl variant="outlined" fullWidth>
                          <SelectValidator
                            name="kyc_type"
                            value={formData.kyc_type}
                            onChange={this.handleChange}
                            fullWidth
                            displayEmpty
                            className="form-control"
                            validators={["required"]}
                            errorMessages={["Kyc type is required!"]}
                          >
                            <MenuItem value="" disabled>
                              Select ID Type
                            </MenuItem>
                            <MenuItem value={"Passport"}>Passport</MenuItem>
                            <MenuItem value={"Identity card"}>
                              Identity card
                            </MenuItem>
                          </SelectValidator>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <div className="custom-upload-button">
                          <Button className="btn btn-upload btn-secondary">
                            <CloudUploadIcon />{" "}
                            <span className="btn-label">Upload Id</span>
                          </Button>
                          <input
                            type="file"
                            onChange={this.uploadKycDoc}
                            className="custom-file-input"
                            accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,image/*"
                          />
                          {formData.kyc_document && (
                            <p className="kyc-document-name">
                              {formData.kyc_document.name}
                            </p>
                          )}
                          {invalidKycDocErrorMsg !== "" && (
                            <p className="kyc-document-error">
                              {invalidKycDocErrorMsg}
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <div className="form-action">
                      <Button
                        className="btn btn-secondary"
                        onClick={this.submit}
                        variant={"contained"}
                      >
                        Update Settings
                      </Button>
                      <Button className="btn" variant={"contained"}>
                        Cancel
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  updateUserProfile: userActions.updateUserProfile,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(Verification);
