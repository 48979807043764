import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: grey[800],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ProgressiveButton(props) {
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={props.loading}
        onClick={props.handleButtonClick}
        className={props.className}
        {...props.rest}
        style={{ width: "100%" }}
      >
        <span style={{ opacity: !props.loading ? "1.0" : "0.3" }}>
          {props.children}
        </span>
        {props.loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </>
  );
}
