import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ReactHtmlParser from "react-html-parser";

export default class PropertyDocuments extends Component {
  getLink = (hrefLink, text) => {
    if (!hrefLink) {
      return <a>{text}</a>;
    }
    return (
      <a href={hrefLink} target="_blank">
        {text}
      </a>
    );
  };

  render() {
    const { documents, showTitle } = this.props;

    return (
      <div className="investment-details">
        {showTitle ? (
          <h2 className="txt-title txt-title-secondary">Documents</h2>
        ) : (
          ""
        )}
        {documents.length > 0 ? (
          <table className="table table-striped table-bordered table-document">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Last Updated</th>
                <th>Download Link</th>
                <th>Transaction Link</th>
              </tr>
            </thead>
            <tbody>
              <>
                {documents.map((document, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{document.file_path}</td>
                      <td>{document.description}</td>
                      <td>{document.updated_date}</td>
                      <td>
                        {this.getLink(document.download_link, "Download")}
                      </td>
                      <td>
                        <Tooltip
                          title={
                            "This document is notarized in 0bsnetwork blockchain"
                          }
                          arrow
                        >
                          {this.getLink(
                            document.blockchain_link,
                            "View in Blockchain Explorer"
                          )}
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        ) : (
          <p className="content-text">{ReactHtmlParser("Nothing to show")}</p>
        )}
      </div>
    );
  }
}
