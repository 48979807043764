import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
  },
  partnerImage: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginRight: theme.spacing(2),
  },
}));

const PartnerList = ({ partners }) => {
  const classes = useStyles();

  return (
    <List className={`${classes.root} partner-list-root`}>
      {partners.map((partner, index) => {
        const partner_name = partner?.partner_master?.name["en"] || "Unknown";
        const partner_logo = partner?.partner_master?.logo
          ? `${process.env.REACT_APP_URL}partner-logos/${partner?.partner_master?.logo}`
          : "";
        const partner_description =
          partner?.partner_master?.description["en"] || "";

        return (
          <div key={index} className="list-item-container">
            <ListItem className="list-item" alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  className={classes.partnerImage}
                  alt={partner_name}
                  src={partner_logo}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <p>
                      <strong>{partner_name}</strong>
                    </p>
                  </>
                }
                secondary={
                  <div className="text-secondary">
                    <p className="txt-description">
                      {ReactHtmlParser(partner_description)}
                    </p>
                  </div>
                }
              />
            </ListItem>
            <Divider
              className="list-item-divider"
              variant="inset"
              component="div"
            />
          </div>
        );
      })}
    </List>
  );
};

const PartnerDetails = ({ property }) => {
  const hasPartners = property?.property_project_partners?.length > 0;
  const propertyPartners = property.property_project_partners || [];

  return (
    <div className="partners-details">
      <h2 className="title-text">Partners</h2>
      {hasPartners ? (
        <PartnerList partners={propertyPartners} />
      ) : (
        <Grid container>
          <Grid item md={3} xs={12}>
            <p>Not available</p>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default PartnerDetails;
