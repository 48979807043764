import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import ReactHtmlParser from "react-html-parser";
import RoomIcon from "@material-ui/icons/Room";
import { Avatar } from "@material-ui/core";
import "./styles.scss";

const PropertyMarker = () => (
  <div className="marker">
    <RoomIcon />
  </div>
);

class LocationDetails extends Component {
  getPropertyLocation = (property) => {
    return {
      lat: parseFloat(property.location_coords.lat),
      lng: parseFloat(property.location_coords.lng),
    };
  };

  render() {
    const { property } = this.props;

    return (
      <div className="location-details" id="property-location">
        {property &&
          property.macrolocation_heading !== "" &&
          property.macrolocation_description !== "" && (
            <div>
              <p className="title-text">{property.macrolocation_heading}</p>
              {property.macrolocation_image_path !== "" && (
                <Avatar
                  className="location-image"
                  alt="City Picture"
                  variant="square"
                  src={property.macrolocation_image_path}
                />
              )}
              <div className="content-text">
                {ReactHtmlParser(property.macrolocation_description)}
              </div>
            </div>
          )}
        {property &&
          property.microlocation_heading !== "" &&
          property.microlocation_description !== "" && (
            <div>
              <p className="title-text">{property.microlocation_heading}</p>
              {property.microlocation_image_path !== "" && (
                <Avatar
                  className="location-image"
                  alt="Neighborhood Picture"
                  variant="square"
                  src={property.microlocation_image_path}
                />
              )}
              <div className="content-text">
                {ReactHtmlParser(property.microlocation_description)}
              </div>
            </div>
          )}

        {property &&
          property.location_coords &&
          !isNaN(
            property.location_coords.lat && !isNaN(property.location_coords.lng)
          ) && (
            <div
              style={{
                height: "370px",
                width: "100%",
                borderRadius: "12px",
                overflow: "hidden",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultZoom={parseInt(process.env.REACT_APP_MAP_ZOOM)}
                center={this.getPropertyLocation(property)}
              >
                <PropertyMarker
                  lat={property.location_coords.lat}
                  lng={property.location_coords.lng}
                />
              </GoogleMapReact>
            </div>
          )}
      </div>
    );
  }
}

export default LocationDetails;
