import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import PropertyBoxComponent from "../../components/PropertyBoxComponent";
import "./styles.scss";

class SearchResultsListings extends Component {
  render() {
    const { properties, search, updateFilters } = this.props;
    function formatLocations(locations) {
      if (!locations || locations.length === 0) {
        return "";
      }

      const cityNames = [];
      let country = null;

      for (let loc of locations) {
        // Use regex to extract city and country from the string
        const match = loc.match(/^(.+?) \((.+?)\)$/);
        if (match) {
          const city = match[1];
          const locCountry = match[2];

          cityNames.push(city);

          if (!country) {
            country = locCountry;
          }
        } else {
          // If the string doesn't match the pattern, include it as is
          cityNames.push(loc);
        }
      }

      // Since the country is always the same
      if (country) {
        return `${cityNames.join(", ")} (${country})`;
      } else {
        // No country information; just join the city names
        return cityNames.join(", ");
      }
    }
    // const pageTitle =
    //   search.agent && properties[0]
    //     ? `Properties by: ${properties[0].user_property_master.first_name} ${properties[0].user_property_master.last_name}`
    //     : "All Properties at a glance";
    // const removeAgentFilter = () => {
    //   // remove agent from filters
    //   const { agent, ...rest } = search;
    //   updateFilters(rest);
    //   // force update url to trigger api call
    //   const newParams = new URLSearchParams();
    //   const searchObj = rest;
    //   const figuresWithValues = {};
    //   // remove keyFigures with both min, max 0
    //   searchObj["keyFigures"] &&
    //     Object.keys(searchObj["keyFigures"]).forEach((key) => {
    //       if (
    //         searchObj["keyFigures"][key]["min"] ||
    //         searchObj["keyFigures"][key]["max"]
    //       ) {
    //         figuresWithValues[key] = searchObj["keyFigures"][key];
    //       }
    //     });

    //   Object.keys(searchObj).forEach((key) => {
    //     const value = searchObj[key];
    //     if (typeof value === "object" && value !== null) {
    //       if (key !== "keyFigures") {
    //         newParams.append(key, JSON.stringify(value));
    //       } else if (Object.keys(figuresWithValues).length) {
    //         newParams.append(key, JSON.stringify(figuresWithValues));
    //       }
    //     } else {
    //       newParams.append(key, value);
    //     }
    //   });

    //   window.history.pushState({}, "", `?${newParams.toString()}`);
    // };

    let pageTitle = "All properties at a glance";
    if (search.countries?.length && !search.location?.length) {
      pageTitle = `Viewing  properties in ${search.countries[0]}`;
    }
    if (search.location?.length) {
      pageTitle = `Viewing  properties in ${formatLocations(search.location)}`;
    }
    console.log("search: ", search);
    return (
      properties.length > 0 && (
        <div className="search-lising-section">
          <h2 className="block-title">{pageTitle}</h2>
          {/* {search.agent && properties[0] ? (
            <Button
              variant="outlined"
              className="btn-primary btn-filled-out"
              onClick={removeAgentFilter}
            >
              Browse All
            </Button>
          ) : null} */}
          <Grid container spacing={3}>
            {properties.map((property, index) => {
              return (
                <Grid key={index} item md={4} xs={12}>
                  <PropertyBoxComponent
                    variant={property.property_type}
                    property={property}
                    updateFilters={updateFilters}
                    search={search}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )
    );
  }
}

export default SearchResultsListings;
