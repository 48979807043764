import { Box, Container, Grid, Typography } from "@material-ui/core";
import { Fragment } from "react";
import ContactFormComponent from "../../components/Contact/contact";
import { SITE } from "../../_constants/site.constants";
import style from './style.module.scss';

const ContactPage = () => {

    return (
        <Fragment>
            <Container>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'flex-start', alignItems: 'flex-start', paddingBottom: 40}}>
                    <Box sx={{padding: '20px 0 0 0'}}>
                        <Typography variant="h4" component="h2">Contact Us</Typography>
                    </Box>
                    <Typography variant="h5" component="h2">
                        If you have any questions or need help, please contact us with the form below
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <ContactFormComponent />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Box sx={{paddingBottom:20, paddingTop: 40}}>
                                    <Typography variant="h5" component="h2">Contact Information</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection: 'column', gap:10}}>
                                    <Typography component="p" className={style.contactItem}>
                                        <strong>Phone:</strong> {SITE.phone}
                                    </Typography>
                                    <Typography component="p" className={style.contactItem}>
                                        <strong>Email:</strong>
                                        <a href={`mailto:${SITE.email}`}>{SITE.email}</a>
                                    </Typography>
                                    <Typography component="p" className={style.contactItem}>
                                        <strong>Address:</strong> {SITE.address}
                                    </Typography>
                                    <figure className={style.logo}>
                                        <img src="https://immobiliumnetwork.com/wp-content/uploads/2023/03/immobilium_logo_black.png" alt="Immobilium Real Estate" />
                                    </figure>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Fragment>
    )

}


export default ContactPage;
