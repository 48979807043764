import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import Lottie from "react-lottie";
import sandClock from "../assets/animations/sand-clock.json";
import promotion from "../assets/animations/promotion.json";
import documents from "../assets/animations/documents.json";
import bagOfMoneyInHand from "../assets/animations/bag-of-money-in-hand.json";
import createLottieOptions from "../_helpers/lottieOptions";

const Listing = () => {
  const sandClockOptions = createLottieOptions(sandClock);
  const promotionOptions = createLottieOptions(promotion);
  const bagOfMoneyInHandOptions = createLottieOptions(bagOfMoneyInHand);
  const documentsOptions = createLottieOptions(documents);
  return (
    <Box sx={{ my: 10 }} className="page-box">
      <Container>
        <Typography variant="h4" paragraph={true} align="center">
          Our Listing Is Your Listing!
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Our Listing Is Your Listing!
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            As a new agent without a track record or without any listings, it
            can be difficult to convince a seller to let you represent their
            properties. We’re here to help by creating your first global
            portfolio, which will give you credibility and make you more
            relevant in the real estate industry.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            Unlike traditional real estate agencies, where agents can only sell
            properties that belong to their own company, on Immobilium, agents
            and owners can post any property they want for buyers to see and
            purchase.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            This means that even if you are an agent from one company, you can
            still make money by helping a buyer purchase a property that’s
            listed by another agent or company on the Immobilium platform.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            We call this feature “our listings are your listings”. This makes it
            easier for buyers to find the property they want, and it gives
            agents more opportunities to earn money by helping buyers find and
            purchase the right property.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            How Does It Work?
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            To start, we analyze every agent individually to provide the most
            beneficial and lective listings.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            There is no point, for example, in offering properties from Brazil
            to German agents who are not of Latin American origins or somehow
            connected to Brazilian communities. Therefore, based on our
            questionnaire, we will be able to pick the properties from our
            global portfolio and assign them to you.
          </Typography>
          <Typography variant="body1" paragraph={true} align="justify">
            You will have your own Agent’s page with all your contact
            information (of your choosing). That means we are not hiding you; we
            are embracing you as an agent. Our service does not interfere with
            your agency’s work since you are not promoting your competitors
            (unless you wish to do so).
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            What Else Can We Do To Sell Your Properties Faster?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={sandClockOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                In order to find the perfect buyer for your property, we offer a
                range of services including:
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <List className="bullet-list">
                  <ListItem>
                    KYC/AML (Know Your Customer/Anti-Money Laundering): running
                    background checks on international buyers
                  </ListItem>
                  <ListItem>Proof of funds</ListItem>
                  <ListItem>
                    Dealing with Ambacies, power of attorneys, Notaries and all
                    other international transaction processes, all based on
                    regional rules and regulations.
                  </ListItem>
                  <ListItem>Setting up banking procedures</ListItem>
                  <ListItem>Promoting your listing in other countries</ListItem>
                  <ListItem>
                    Offering global escrow services to make transactions more
                    secure
                  </ListItem>
                  <ListItem>
                    Providing 15-minute crypto exchange solutions for up to 100
                    million dollars.
                  </ListItem>
                  <ListItem>
                    NOT wasting your time, and delivering you a buyer on a
                    silver plate.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Will My Listings Be Promoted?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={promotionOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                Yes, what's fair is fair.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                As this is a network-based process, if you have your own
                listings and would like some fellow agents from a different
                country to promote them for you, all you have to do is upload
                them for free on Immobilium, and we will assign your property to
                a suitable agent abroad.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            How Much Will I Earn And How Will I Be Paid?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie
                  options={bagOfMoneyInHandOptions}
                  height={300}
                  width={300}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                As an Immobilium Network Partner, you automatically become a
                buyer's agent, regardless of how and where the buyer comes from
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The market is tough, and sometimes your buyer’s list may be
                interested in owning properties in locations outside of your
                local area.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                By promoting properties through your marketing channels, such as
                social media, network events, or even through casual
                conversations, you have the opportunity to unlock another level
                of income by referring potential buyers to us for international
                property purchases. We will take care of the rest.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Your referral compensation is set at 20% of the final
                commission. What does that mean? Each country has its own rules
                and regulations regarding commissions. In Australia, for
                example, the commission could be as low as 1.5% of the property
                price, while in Mexico, the Caribbean Islands, and other
                locations, it can be as high as 8-10%. On a global scale, the
                average commission ranges from 2.5% to 4%.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                We will pay you via money wire, check, or even PayPal, depending
                on your location as an agent.
                <br />
                If you have any more questions, feel free to ask.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            How Can I Know That My Sales Referral Will Be Recorded?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie options={documentsOptions} height={300} width={300} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                As you can see, the whole process is simple and transparent, and
                we have built our reputation and credibility on the basis of
                trust. We have not hidden your information
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                To ensure that your sales referral is recorded, Immobilium has
                implemented a robust tracking and monitoring system. Here’s how
                it works:
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>1. Promo Page:</strong> You will have your own dedicated
                promo page on the Immobilium platform, which will feature your
                picture, name, agency, and contact information. This page serves
                as a point of contact for potential buyers, allowing them to
                reach out to you directly.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>2. Communication:</strong> Buyers who are interested in
                purchasing properties will have the option to contact you first
                before approaching Immobilium. This enables you to establish a
                direct connection with the buyer and initiate the sales process.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>3. Tracking and Updates:</strong> Throughout the sales
                process, Immobilium’s offices in 27 countries will represent the
                sales transactions. They will track and update you on the
                progress of the referral. You can expect regular communication
                and updates regarding the status of the sale.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                <strong>4. Payment:</strong> Once the sales process is
                completed, closed, and funded, Immobilium will arrange for your
                payment. The referral compensation, set at 20% of the final
                commission, will be provided to you through a preferred method
                such as money wire, check, or PayPal, depending on your location
                as an agent.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Rest assured that Immobilium values transparency and trust. By
                providing your own promo page, direct communication with buyers,
                and regular updates, the platform aims to ensure that your sales
                referrals are recorded and properly accounted for.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                By the way, feel free to test us by sending a decoy buyer our
                way and see how we react. We are confident in our reliability
                and professionalism.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Listing;
