import React, { Component } from "react";
import { connect } from "react-redux";
import { NavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { userActions } from "../../_actions";
import GeneralInformation from "./profile/GeneralInformation";
import Verification from "./profile/Verification";
import Security from "./profile/Security";
import Settings from "./profile/Settings";
import PaymentSource from "./profile/PaymentSource";
import { Box, Tabs, Tab, Button } from "@material-ui/core";
import { history } from "./../../_helpers";
import "./styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import FooterComponent from "../../components/FooterComponent";

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      modalStatus: true,
      isFooterOpen: false,
    };

    history.listen((location, action) => {
      var searchPage = location.search;
      this.selectTabIndex(searchPage);
    });
  }

  componentDidMount() {
    var searchPage = history.location.search;
    this.selectTabIndex(searchPage);
    this.handleFetchUserProfile();
  }

  handleFetchUserProfile = () => {
    this.props.getUserDetails();
  };

  selectTabIndex = (searchPage) => {
    switch (searchPage) {
      case "?verification":
        this.setState({ tabIndex: 1 });
        break;

      case "?settings":
        this.setState({ tabIndex: 4 });
        break;

      default:
        this.setState({ tabIndex: 0 });
        break;
    }
  };

  handleChangeTab = (event, index) => {
    this.setState({ tabIndex: index });
  };

  openPaymentDetailModal = () => {
    this.setState({ modalStatus: true });
  };

  closePaymentDetailModal = () => {
    this.setState({ modalStatus: false });
  };

  toggleFooter = () => {
    this.setState({ isFooterOpen: !this.state.isFooterOpen });
  };

  render() {
    return (
      <div className="dashboard-content">
        <NavBarComponent pageName="profile" />

        <main className="main-content">
          <div className="profile-page">
            <Tabs
              TabIndicatorProps={{
                style: { left: 0 },
              }}
              className="tab-bar-links"
              orientation="vertical"
              variant="scrollable"
              value={this.state.tabIndex}
              onChange={this.handleChangeTab}
              aria-label="Profile Settings"
            >
              <Tab label="General information" {...a11yProps(0)} />
              <Tab label="Verification" {...a11yProps(1)} />
              <Tab
                label="Payment source"
                {...a11yProps(2)}
                style={{ display: "none" }}
              />
              <Tab label="Security" {...a11yProps(3)} />
              <Tab label="Settings" {...a11yProps(4)} />

              <Tab className="spacing" label="Help center" {...a11yProps(5)} />
              <Tab label="Contact us" {...a11yProps(5)} />
              <Tab label="Close account" {...a11yProps(6)} />
            </Tabs>

            <TabPanel value={this.state.tabIndex} index={0}>
              <GeneralInformation
                handleFetchUserProfile={this.handleFetchUserProfile}
              />
            </TabPanel>

            <TabPanel value={this.state.tabIndex} index={1}>
              <Verification />
            </TabPanel>

            <TabPanel
              style={{ display: "none" }}
              value={this.state.tabIndex}
              index={2}
            >
              <PaymentSource />
            </TabPanel>

            <TabPanel value={this.state.tabIndex} index={3}>
              <Security />
            </TabPanel>

            <TabPanel value={this.state.tabIndex} index={4}>
              <Settings handleFetchUserProfile={this.handleFetchUserProfile} />
            </TabPanel>

            <TabPanel value={this.state.tabIndex} index={5}>
              Help center
            </TabPanel>
            <TabPanel value={this.state.tabIndex} index={6}>
              Contact us
            </TabPanel>
            <TabPanel value={this.state.tabIndex} index={7}>
              Close account
            </TabPanel>
          </div>
          {this.state.isFooterOpen ? (
            <Button
              variant="contained"
              className="btn-footer-action"
              onClick={this.toggleFooter}
            >
              <CloseIcon /> Close
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-footer-action"
              onClick={this.toggleFooter}
            >
              GTC, Privacy policy & more
            </Button>
          )}
        </main>

        {this.state.isFooterOpen ? (
          <div className="footer-block">
            <FooterComponent />
          </div>
        ) : null}
      </div>
    );
  }
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      component="div"
      role="tabpanel"
      className="tab-panel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  getUserDetails: userActions.getUserDetails,
};

export default connect(mapState, actionCreators)(ProfilePage);
