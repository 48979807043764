// import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
// import { Grid, Container } from "@material-ui/core";
import "./styles.scss";
// import logoW from "../../assets/images/logo-w.png";

// class FooterComponent extends Component {
//   render() {
//     // return (
//     //   <footer className="footer">
//     //     <Container>
//     //       <div
//     //         className="logo-white-footer-container"
//     //         style={{ backgroundImage: `url('` + logoW + `')` }}
//     //       />
//     //       <div className="footer-grid-container">
//     //         <Grid container spacing={3}>
//     //           <Grid item md={2} xs={12}>
//     //             <NavLink to="/terms-of-use" className="nav-text">
//     //               Terms Of Use
//     //             </NavLink>
//     //           </Grid>
//     //           <Grid item md={2} xs={12}>
//     //             <a href="https://info.immobilium.io/" target="_blank">
//     //               More Information
//     //             </a>
//     //           </Grid>
//     //         </Grid>

//     //         <div className="footer-bottom">
//     //           <p>
//     //             &copy; {new Date().getFullYear()} Immobilium. All rights
//     //             reserved.
//     //           </p>
//     //         </div>
//     //       </div>
//     //     </Container>
//     //   </footer>
//     <footer>
//         <div className="footer-container">
//           <div className="footer-row">
//             <div className="footer-logo-col">

//             </div>
//             <div className="footer-menu-col">
//               <span className="footer-title">
//                 About us
//               </span>
//               <a href="/">What Is Immobilium</a>
//               <a href="/">How It Works</a>
//               <a href="/">Our Listing Is Your Listing</a>
//               <a href="/">Immobilium Escrow</a>
//               <a href="/">Hard Money Loans</a>
//             </div>
//             <div className="footer-menu-col">
//               <span className="footer-title">
//                Pages
//               </span>
//               <a href="/">Buy</a>
//               <a href="/">FAQ</a>
//               <a href="/">Contact Us</a>
//               <a href="">Terms of Use</a>
//               <a href="">More Information</a>
//             </div>
//           </div>
//         </div>
//     </footer>
//     );
//   }
// }

// export default FooterComponent;

import React from "react";
import LogoImg from "../../assets/images/Immobilium-logo.png";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-logo-col">
            <img src={LogoImg} alt="logo" />
            <p>
              Embark on your journey to find the perfect home with our expert
              guidance and exceptional service.
            </p>
          </div>
          <div className="footer-menu-col">
            <span className="footer-title">About us</span>
            <Link to="/about">What Is Immobilium</Link>
            <Link to="/how-it-works">How It Works</Link>
            <Link to="/listing">Our Listing Is Your Listing</Link>
            <Link to="/escrow">Immobilium Escrow</Link>
            <Link to="/loans">Hard Money Loans</Link>
          </div>
          <div className="footer-menu-col">
            <span className="footer-title">Pages</span>
            <Link to="/marketplace">Buy</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact-us">Contact Us</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
            <a href="https://info.immobilium.io/" target="_blank">
              More Information
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <span>
          &copy; {new Date().getFullYear()} Immobilium. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default FooterComponent;
