import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import data from "../../assets/data/continents.json";
import { useNavigate } from "react-router-dom";

function filterCountries(allCountries, specifiedCountries) {
  const filteredCountries = {};

  // Create a Set of specified country names for quick lookup
  const specifiedCountryNames = new Set(Object.keys(specifiedCountries));

  // Iterate over all continents and countries
  for (const continent in allCountries) {
    const countries = allCountries[continent];
    for (const country of countries) {
      if (specifiedCountryNames.has(country.name)) {
        if (!filteredCountries[continent]) {
          filteredCountries[continent] = [];
        }
        filteredCountries[continent].push(country);
      }
    }
  }

  return filteredCountries;
}

const CountriesSection = ({ getAllLocations }) => {
  const [selectedContinent, SetContinent] = useState("All");
  const { activePropertyLocations } = useSelector((state) => state?.general);
  const navigate = useNavigate();
  const aviableContries = filterCountries(data, activePropertyLocations);
  return (
    <section className="countries-section">
      <div className="countries-container">
        <h4>Explore by country</h4>
        <div className="filters-wrapper">
          <Button
            className={`${selectedContinent === "All" ? "active-filter" : ""}`}
            onClick={() => SetContinent("All")}
          >
            All
          </Button>
          <Button
            className={`${selectedContinent === "Asia" ? "active-filter" : ""}`}
            onClick={() => SetContinent("Asia")}
          >
            Asia
          </Button>
          <Button
            className={`${
              selectedContinent === "Africa" ? "active-filter" : ""
            }`}
            onClick={() => SetContinent("Africa")}
          >
            Africa
          </Button>
          <Button
            className={`${
              selectedContinent === "North America" ? "active-filter" : ""
            }`}
            onClick={() => SetContinent("North America")}
          >
            North America
          </Button>
          <Button
            className={`${
              selectedContinent === "South America" ? "active-filter" : ""
            }`}
            onClick={() => SetContinent("South America")}
          >
            South America
          </Button>
          <Button
            className={`${
              selectedContinent === "Europe" ? "active-filter" : ""
            }`}
            onClick={() => SetContinent("Europe")}
          >
            Europe
          </Button>
          <Button
            className={`${
              selectedContinent === "Australia and Oceania"
                ? "active-filter"
                : ""
            }`}
            onClick={() => SetContinent("Australia and Oceania")}
          >
            Australia and Oceania
          </Button>
        </div>
        <div className="countries-results-wrapper">
          <div className="countries-results-row">
            {aviableContries[selectedContinent]
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((item, index) => {
                if (item) {
                  return (
                    <a
                      href={`/marketplace?countries=${encodeURIComponent(
                        JSON.stringify([item.name])
                      )}`}
                      style={{ cursor: "pointer" }}
                      className="country-card"
                      key={index}
                    >
                      <img
                        src={`/countries/${item.name}.webp`}
                        alt={`${item.name}`}
                      />
                      <span>{item.name}</span>
                    </a>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountriesSection;
