import { Box, Link, Typography } from "@material-ui/core";
import { Fragment } from "react";
import style from './style.module.scss';

const WelcomeSeller = () => {

    return (
        <Fragment>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'flex-start', alignItems: 'flex-start'}}>   
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="h4" component="h1">GET STARTED - SELLER</Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="p">Welcome to <b>Immobilium</b>, where your success is our priority!</Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Streamlined Selling Process</Typography>
                    <Typography variant="p" component="p">At Immobilium, we simplify the selling process using our advanced technology platform. List your property and connect with potential 
                        buyers globally. Our blockchain-based system ensures secure and efficient transactions, minimizing the traditional hassles of documentation and third-party fees.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Global Reach</Typography>
                    <Typography variant="p" component="p">Leverage our extensive network spanning 46 countries and growing. Our platform provides exposure to a vast pool of international 
                        buyers, maximizing your property's visibility and potential for a successful sale.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Support and Resources</Typography>
                    <Typography variant="p" component="p">We offer comprehensive support, including legal, notary, and compliance services, ensuring a smooth transaction process. Our team of experts is dedicated 
                        to helping you navigate the complexities of selling real estate, providing guidance every step of the way.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Transparency and Security</Typography>
                    <Typography variant="p" component="p">Our commitment to transparency and security means you can trust that your transaction is safe and compliant with local regulations. 
                        Immobilium's blockchain technology guarantees a secure and efficient process, giving you peace of mind. 
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="p">List your properties on Immobilium</Typography>
                </Box>
                <Box m={0} pt={3}>
                    <Link href="/register" color="default" className={style.btn}>
                        SELL NOW
                    </Link>
                </Box>
            </Box>
        </Fragment>
    );
}

export default WelcomeSeller;