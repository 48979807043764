import React, { Component } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import { FormControl, MenuItem, Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import SelectValidator from "../../../components/SelectValidatorElement";
import { userActions, alertActions } from "../../../_actions";
import NotificationBox from "../../../components/GeneralComponent/NotificationBox";
import "./styles.scss";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        user_id: 0,
        allow_property_updates: false,
        allow_newsletter: false,
        allow_property_location: false,
        language: "en",
        theme: "light",
        measurement: "metric",
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { profile_data } = props.users;
    if (profile_data && profile_data.user_id !== state.formData.user_id) {
      return {
        formData: {
          user_id: profile_data.user_id,
          allow_property_updates: profile_data.allow_property_updates,
          allow_newsletter: profile_data.allow_newsletter,
          allow_property_location: profile_data.allow_property_location,
          language: profile_data.language,
          theme: profile_data.theme || "light",
          measurement: profile_data.measurement || "metric",
          update_form_type: "settings",
        },
      };
    }
    return null;
  }

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: fieldValue,
      },
    }));
  };

  togglePropertyUpdatesSwitch = (checked) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        allow_property_updates: checked,
      },
    }));
  };

  toggleNewsletterSwitch = (checked) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        allow_newsletter: checked,
      },
    }));
  };

  togglePropertyLocationSwitch = (checked) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        allow_property_location: checked,
      },
    }));
  };

  measurementSwitch = (checked) => {
    const measurement = checked ? "metric" : "imperial";
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        measurement,
      },
    }));
  };

  handleUpdateSettings = () => {
    this.props.updateUserProfile(this.state.formData).then((value) => {
      this.props.handleFetchUserProfile();
      setTimeout(() => this.props.clearAlerts(), 1000);
    });
  };

  getMeasurementStyle = (selectedMeasurement, measurement) => {
    const style = {
      fontSize: "16px",
      color: "#bacfe6",
    };
    if (selectedMeasurement === measurement) {
      style["color"] = "#000";
    }
    return style;
  };

  render() {
    const { formData } = this.state;
    const { alert } = this.props;

    return (
      <ValidatorForm
        className="default-form"
        autoComplete="off"
        instantValidate
        onSubmit={() => console.log("no error")}
      >
        <div className="settings-tab">
          <p className="page-block-title">Theme</p>
          <div className="user-profile-form">
            <FormControl variant="outlined" fullWidth>
              <SelectValidator
                name="theme"
                value={formData.theme}
                onChange={this.handleChange}
                fullWidth
                displayEmpty
                className="form-control"
                validators={["required"]}
              >
                <MenuItem value="" disabled>
                  Select Theme
                </MenuItem>
                <MenuItem value="light">Light</MenuItem>
                <MenuItem value="sea" disabled>
                  Sea
                </MenuItem>
                <MenuItem value="cosmos" disabled>
                  Cosmos
                </MenuItem>
                <MenuItem value="spring" disabled>
                  Springtime
                </MenuItem>
                <MenuItem value="forest" disabled>
                  Dark Forest
                </MenuItem>
                <MenuItem value="butterfly" disabled>
                  Butterflies
                </MenuItem>
                <MenuItem value="sensual" disabled>
                  Sensual
                </MenuItem>
                <MenuItem value="dark" disabled>
                  Dark
                </MenuItem>
              </SelectValidator>
            </FormControl>
          </div>

          <p className="page-block-title">Measurement</p>
          <div
            className="list-item"
            style={{ display: "flex", paddingTop: "20px" }}
          >
            <div
              style={this.getMeasurementStyle(formData.measurement, "imperial")}
            >
              <span>Imperial measurement</span>
            </div>
            <div style={{ margin: "0 20px" }}>
              <Switch
                offColor="#f3f4f6"
                onColor="#f3f4f6"
                offHandleColor="#98a9bb"
                onHandleColor="#98a9bb"
                handleDiameter={18}
                height={27}
                width={50}
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={this.measurementSwitch}
                checked={formData.measurement === "metric"}
                className="btn-toggle-switch"
              />
            </div>
            <div
              style={this.getMeasurementStyle(formData.measurement, "metric")}
            >
              <span>Metric measurement</span>
            </div>
          </div>

          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
            />
          )}
          <p className="page-block-title">Notifications</p>

          <div className="user-profile-form">
            <div className="list-item">
              <div className="item-type">
                <p className="txt-type">Property updates</p>
                <p className="txt-info">
                  Allow property notifications to get updates straight into your
                  inbox.
                </p>
              </div>
              <div className="check-type">
                <Switch
                  offColor="#f3f4f6"
                  onColor="#e52800"
                  offHandleColor="#98a9bb"
                  onHandleColor="#FFF"
                  handleDiameter={18}
                  height={27}
                  width={50}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={this.togglePropertyUpdatesSwitch}
                  checked={formData.allow_property_updates}
                  className="btn-toggle-switch"
                />
              </div>
            </div>

            <div className="list-item">
              <div className="item-type">
                <p className="txt-type">Newsletter</p>
                <p className="txt-info">
                  Subscribe to the Immobilium newsletter and you\'ll stay up to
                  date with our News and Updates.
                </p>
              </div>
              <div className="check-type">
                <Switch
                  offColor="#f3f4f6"
                  onColor="#e52800"
                  offHandleColor="#98a9bb"
                  onHandleColor="#FFF"
                  handleDiameter={18}
                  height={27}
                  width={50}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={this.toggleNewsletterSwitch}
                  checked={formData.allow_newsletter}
                  className="btn-toggle-switch"
                />
              </div>
            </div>

            <div className="list-item">
              <div className="item-type">
                <p className="txt-type">Property location</p>
                <p className="txt-info">
                  Allow location notifications to get information if you are
                  close to an available opportunity.
                </p>
              </div>
              <div className="check-type">
                <Switch
                  offColor="#f3f4f6"
                  onColor="#e52800"
                  offHandleColor="#98a9bb"
                  onHandleColor="#FFF"
                  handleDiameter={18}
                  height={27}
                  width={50}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={this.togglePropertyLocationSwitch}
                  checked={formData.allow_property_location}
                  className="btn-toggle-switch"
                />
              </div>
            </div>

            <div className="form-action">
              <Button
                className="btn btn-primary btn-filled-out"
                onClick={this.handleUpdateSettings}
                variant={"contained"}
              >
                Update Settings
              </Button>
              <Button className="btn btn-primary" variant={"contained"}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </ValidatorForm>
    );
  }
}

function mapState(state) {
  const { users, alert } = state;
  return { users, alert };
}

const actionCreators = {
  updateUserProfile: userActions.updateUserProfile,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(Settings);
