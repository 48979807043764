import React, { Component } from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import "./styles.scss";
import Gallery from "react-grid-gallery";
import { withRouter } from "../../_helpers";

import KingBedOutlinedIcon from "@material-ui/icons/KingBedOutlined";
import BathtubOutlinedIcon from "@material-ui/icons/BathtubOutlined";
import LocalParkingOutlinedIcon from "@material-ui/icons/LocalParkingOutlined";
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

class PropertyKeyFigures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openGallery: false,
    };
  }

  openGalleryModal = (floor_plan) => {
    this.props.onModalOpen();
    this.setState({
      openGallery: true,
    });
  };

  closeGalleryModal = () => {
    this.props.onModalClose();
    this.setState({
      openGallery: false,
    });
  };

  handleDataTransformation = (floor_plan_path) => {
    return floor_plan_path.map(({ image_path }) => {
      return {
        src: image_path,
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        thumbnail: image_path,
      };
    });
  };

  getIcon = (key) => {
    switch (key) {
      case "Bedrooms":
        return (
          <KingBedOutlinedIcon
            style={{ color: "#2BAAEA", marginRight: "10px" }}
          />
        );
      case "Bathrooms":
        return (
          <BathtubOutlinedIcon
            style={{ color: "#2BAAEA", marginRight: "10px" }}
          />
        );
      case "Parking Spaces":
        return (
          <LocalParkingOutlinedIcon
            style={{ color: "#2BAAEA", marginRight: "10px" }}
          />
        );
      case "Sq Feet":
        return (
          <PinDropOutlinedIcon
            style={{ color: "#2BAAEA", marginRight: "10px" }}
          />
        );
      case "Search":
        return (
          <SearchOutlinedIcon
            style={{ color: "#2BAAEA", marginRight: "5px" }}
          />
        );
      default:
        return (
          <FeaturedPlayListOutlinedIcon
            style={{ color: "#2BAAEA", marginRight: "10px" }}
          />
        );
    }
  };

  getKeyFigureName = (key) => {
    switch (key) {
      case "Sq Feet":
        return this.props.user.measurement === "metric" ? "Sq Meters" : key;
      default:
        return key;
    }
  };

  getValue = (key, value) => {
    const squareMeterRate =
      this.props.user.measurement === "metric" ? 0.092903 : 1;
    switch (key) {
      case "Sq Feet":
        const isInteger = /^\d+$/.test(value);
        const val = parseInt(value);
        return (isInteger && val && Math.round(val * squareMeterRate)) || value;
      default:
        return value;
    }
  };

  render() {
    const { property } = this.props;

    return (
      property && (
        <div className="property-key-figures-box">
          <Grid container spacing={3}>
            {property.property_key_figures && (
              <Grid item md={6}>
                <table className="table table-key-figures">
                  <tbody>
                    {property.property_key_figures.length > 0 ? (
                      property.property_key_figures.map((key_kigure, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {this.getIcon(key_kigure.property_key)}
                                <span>
                                  {this.getKeyFigureName(
                                    key_kigure.property_key
                                  )}
                                </span>
                                &nbsp;&nbsp;
                                {key_kigure.property_key === "Sq Feet" ? (
                                  <Tooltip
                                    title={
                                      <React.Fragment>
                                        You can switch measurement unit in your
                                        profile settings. Click here to be taken
                                        to your profile.
                                      </React.Fragment>
                                    }
                                    placement="top"
                                    onClick={() =>
                                      this.props.navigate("/dashboard/profile")
                                    }
                                    arrow
                                  >
                                    <InfoOutlinedIcon
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td>
                              {this.getValue(
                                key_kigure.property_key,
                                key_kigure.property_value
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>Not available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Grid>
            )}
            {property.property_floor_plans?.length > 0 && (
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {[property.property_floor_plans[0]].map((fp, idx) => (
                    <img
                      src={fp.image_path}
                      alt="floor-plan-img"
                      className="floor-plan-img"
                      onClick={this.openGalleryModal}
                    />
                  ))}
                  <Link
                    onClick={this.openGalleryModal}
                    style={{
                      color: "#000",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="show-all-photos"
                  >
                    {this.getIcon("Search")}
                    <span>
                      View floor plans{" "}
                      <Typography
                        style={{ display: "inline", color: "#2BAAEA" }}
                      >
                        ({property.property_floor_plans.length})
                      </Typography>
                    </span>
                  </Link>
                </Box>
              </Grid>
            )}
            <div className="modal-container">
              {property &&
                property.property_floor_plans &&
                property.property_floor_plans.length > 0 &&
                this.state.openGallery && (
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <Gallery
                        margin={0}
                        images={this.handleDataTransformation(
                          property.property_floor_plans
                        )}
                        thumbnailImageComponent={ImageComponent}
                        enableImageSelection={false}
                        showLightboxThumbnails={true}
                        isOpen={true}
                        lightboxWillClose={this.closeGalleryModal}
                      />
                    </Grid>
                  </Grid>
                )}
            </div>
          </Grid>
        </div>
      )
    );
  }
}

class ImageComponent extends React.Component {
  render() {
    return (
      <img
        alt="Property"
        className="property-image"
        {...this.props.imageProps}
      />
    );
  }
}

export default withRouter(PropertyKeyFigures);
