import { Helmet } from "react-helmet-async"
import { SITE } from "../../_constants/site.constants"

/**
 *
 * @param {
 *   title: string,
 *   url: string,
 *   description: string,
 *   image: string,
 *   twitter: boolean,
 *   facebook: boolean,
 *   reddit: boolean,
 *
 * } props
 */
const MetaComponent = (props) => {

    const siteName        = SITE.name;
    const siteImaqe       = SITE.image;
    const siteUrl         = SITE.url;
    const siteDescription = SITE.description;

    const meta = () => {
        const defaultMeta = {
            title: siteName,
            url: siteUrl,
            description: siteDescription,
            image: siteUrl + siteImaqe,
        }

        const title = props.title ? (`${props.title} | ${defaultMeta.title}`) : defaultMeta.title;
        const url = props.url || defaultMeta.url;
        let description = props.description || defaultMeta.description;
        const image = props.image || defaultMeta.image;

        if (description.length > 160) {
            description = description.substring(0, 160);
        }

        return {
            url,
            description,
            image,
            'twitter:site': siteName,
            'twitter:title': title,
            'twitter:description': description,
            'twitter:image': image,
            'og:title': title,
            'og:description': description,
            'og:type': 'website',
            'og:url': url,
            'og:image': image,
            'og:site_name': siteName,
            'og:locale': 'en_US'
        }
    }

    const getHelmet = () => {
        const data = meta();
        const title = props.title ? (`${props.title} | ${siteName}`) : data.title;
        return <Helmet>
            <title>{title}</title>
            <meta name="description" content={data.description} />
            <meta property="og:title" content={data.siteName} />
            <meta property="og:description" content={data.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.url} />
            <meta property="og:image" content={data['og:image']} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:locale" content={data['og:locale']} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={data['twitter:site']} />
            <meta name="twitter:title" content={data['twitter:title']} />
            <meta name="twitter:description" content={data['twitter:description']} />
            <meta name="twitter:image" content={data['twitter:image']} />
        </Helmet>
    }
    return getHelmet();
}

export default MetaComponent;
