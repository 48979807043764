import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { PropertyHeroSection } from "./PropertyHeroSection";
import { SectionComponent } from "./SectionComponent";
import PriceComponent from "./PriceComponent";
import ThePropertyComponent from "./ThePropertyComponent";
import PropertyDocuments from "../../components/PropertyComponent/PropertyDocuments";
import PropertyLegalInfo from "../../components/PropertyComponent/PropertyLegalInfo";
import { PartnersComponent } from "./PartnersComponent";
import { SectionLineComponent } from "./SectionLineComponent";
import HighlightsComponent from "./HighlightsComponent";
import PropertyDescription from "./PropertyDescription";
import PropertyKeyFigures from "./PropertyKeyFigures";
import LocationDetails from "./LocationDetails";
import Loader from "../../components/GeneralComponent/Loader";
import { generalActions } from "../../_actions";
import { withParams } from "../../_helpers/withParams";
// import "./styles_description.scss";
import FooterComponent from "../../components/FooterComponent";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";
import { convertUnit } from "../../utils/convertUnit";
import MetaComponent from "../../components/MetaComponent";
import ContactProperty from "../../components/Contact";
import removePlusCode from "../../_helpers/removePlusCode";
import HeroContainerSection from "../NewPropertyDetailsPage/HeroContainerSection";
import InfoSection from "../NewPropertyDetailsPage/InfoSection";
import AboutSection from "../NewPropertyDetailsPage/AboutSection";
import WhatPlaceOffersSection from "../NewPropertyDetailsPage/WhatPlaceOffersSection";
import PartnersSection from "../NewPropertyDetailsPage/PartnersSection";
import LocationSection from "../NewPropertyDetailsPage/LocationSection";
import DocumentsSection from "../NewPropertyDetailsPage/DocumentsSection";
import LegalSection from "../NewPropertyDetailsPage/LegalSection";

class PropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false,
      message: "",
      investment_block_height: 500,
      property_detail: {},
      propertyId: null,
      isModalOpen: false,
      investHeight: 0,
      priceComponent: "",
      highlightsHeading: "",
      highlightsComponent: "",
      thePropertyHeading: "",
      thePropertyComponent: "",
      keyFiguresComponent: "",
      documentsComponent: "",
      legalInfoComponent: "",
      getPartnersComponent: "",
    };
    this.setInvestHeight.bind(this);
  }

  componentDidMount() {
    const { id: propertyId } = this.props.params;
    this.props.getProperty(propertyId);
    this.setState({ propertyId });

    var _self = this;
    setTimeout(() => {
      if (document.getElementById("investment-box") !== null) {
        const height = document.getElementById("investment-box").clientHeight;
        _self.setState({ investment_block_height: height });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.props.resetProperty();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      property,
      users: { profile_data },
    } = this.props;

    if (property !== prevState.property_detail) {
      if (property && Object.keys(property).length !== 0) {
        const toSystem =
          profile_data && profile_data.user_id
            ? profile_data.measurement
            : "metric";
        if (property.macrolocation_description) {
          property.macrolocation_description = convertUnit(
            property.macrolocation_description,
            toSystem
          );
        }
        if (property.microlocation_description) {
          property.microlocation_description = convertUnit(
            property.microlocation_description,
            toSystem
          );
        }
        if (property.property_sections) {
          property.property_sections = property.property_sections.map(
            ({ description, ...ps }) => ({
              ...ps,
              description: convertUnit(description, toSystem),
            })
          );
        }
        if (property.property_documents) {
          property.property_documents = property.property_documents.map(
            (document) => {
              return {
                file_path: document.file_path,
                description: document.description,
                updated_date: document.updated_date,
                transaction_id: document.transaction_id,
                download_link: `${process.env.REACT_APP_API_URL}/properties/download/${document.file_path}`,
                blockchain_link: `${process.env.REACT_APP_0BSNETWORK_EXPLORER}/${document.transaction_id}`,
              };
            }
          );
        }
        if (property.property_key_figures) {
          property.property_key_figures = property.property_key_figures.map(
            ({ property_value, ...pkf }) => ({
              ...pkf,
              property_value: convertUnit(property_value, toSystem),
            })
          );
        }

        const priceComponent = this.getPriceComponent(
          property.property_id,
          property
        );
        const highlightsHeading = this.getSectionHeading(
          property,
          "the_highlights"
        );
        const highlightsComponent = this.getHighlightsComponent(property);
        const thePropertyHeading = this.getSectionHeading(
          property,
          "the_property"
        );
        const thePropertyComponent = this.getThePropertyComponent(property);
        const keyFiguresComponent = this.getKeyFiguresComponent(property);
        const documentsComponent = this.getDocumentsComponent(
          property.property_documents
        );
        const legalInfoComponent = this.getLegalInfoComponent(
          property.legal_warnings
        );
        const partnersComponent = this.getPartnersComponent(
          property.property_project_partners
        );

        this.setState({
          ...prevState,
          property_detail: property,
          priceComponent,
          highlightsHeading,
          highlightsComponent,
          thePropertyHeading,
          thePropertyComponent,
          keyFiguresComponent,
          documentsComponent,
          legalInfoComponent,
          partnersComponent,
        });
      }
    }
  }

  onModalOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  onModalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  setInvestHeight(height) {
    if (height == 0 || height == this.state.investHeight) return;
    this.setState({
      investHeight: height,
    });
  }

  toggleNotification = (notification, message = "") => {
    this.setState({ notification, message });
  };

  getDescription = (property) => {
    const t1 = property?.title;
    let d1 = property?.macrolocation_description;
    const d2 = property?.address_line1;

    if (d1) {
      d1 = d1.replace(/<[^>]*>?/gm, "");
    }

    let description = `${t1} ${d1} ${d2}`;

    if (description.length > 150) {
      description = description.substring(0, 150) + "...";
    }

    return description;
  };

  getPriceComponent = (propertyId, property) => {
    return (
      <PriceComponent
        property={property}
        propertyId={propertyId}
        onModalOpen={this.onModalOpen}
        onModalClose={this.onModalClose}
        toggleNotification={this.toggleNotification}
        title={property.title}
        description={this.getDescription(property)}
      />
    );
  };

  getSectionHeading = (property, sectionKey) => {
    const section = property?.property_sections
      ? property.property_sections.find(
          (section) => section.section_key === sectionKey
        )
      : "";
    const sectionHeading = section?.heading ? section.heading : "";
    return sectionHeading;
  };

  getHighlightsComponent = (property) => {
    const highlights = property.property_sections
      ? property.property_sections.find(
          (section) => section.section_key === "the_highlights"
        )
      : "";

    return highlights?.description ? (
      <HighlightsComponent highlights={highlights.description} />
    ) : (
      ""
    );
  };

  getThePropertyComponent = (property) => {
    const theProperty = property.property_sections
      ? property.property_sections.find(
          (section) => section.section_key === "the_property"
        )
      : "";

    return theProperty?.description ? (
      <ThePropertyComponent theProperty={theProperty.description} />
    ) : (
      ""
    );
  };

  getKeyFiguresComponent = (property) => {
    return property.property_key_figures.length > 0 ? (
      <PropertyKeyFigures
        user={(this.props.users && this.props.users.profile_data) || {}}
        property={property}
        onModalOpen={this.onModalOpen}
        onModalClose={this.onModalClose}
      />
    ) : (
      ""
    );
  };

  getDocumentsComponent = (documents) => {
    return documents.length > 0 ? (
      <PropertyDocuments documents={documents} />
    ) : (
      ""
    );
  };

  getLegalInfoComponent = (legalInfo) => {
    return legalInfo ? <PropertyLegalInfo legalInfo={legalInfo} /> : "";
  };

  getPartnersComponent = (partners) => {
    return partners.length > 0 ? <PartnersComponent partners={partners} /> : "";
  };

  render() {
    const {
      property_detail,
      priceComponent,
      highlightsHeading,
      highlightsComponent,
      thePropertyHeading,
      thePropertyComponent,
      keyFiguresComponent,
      documentsComponent,
      legalInfoComponent,
      partnersComponent,
    } = this.state;

    return (
      <Fragment>
        <MetaComponent
          title={property_detail.title}
          description={this.getDescription(property_detail)}
        />
        {property_detail && Object.keys(property_detail).length > 0 ? (
          <div className="property-details-page">
            <NotificationBox
              open={this.state.notification}
              variant={"error"}
              message={this.state.message}
            />
            <HeroContainerSection property={property_detail} />
            <InfoSection
              property={property_detail}
              propertyId={property_detail.property_id}
              // onModalOpen={this.onModalOpen}
              // onModalClose={this.onModalClose}
              toggleNotification={this.toggleNotification}
              title={property_detail.title}
              description={this.getDescription(property_detail)}
            />
            <div style={{ maxWidth: "868px", margin: "0 auto 32px auto" }}>
              <WhatPlaceOffersSection property={property_detail} />
              <AboutSection property={property_detail} />
              <LocationSection property={property_detail} />
              <LegalSection property={property_detail} />
              <DocumentsSection property={property_detail} />
              <PartnersSection property={property_detail} />
            </div>
            {/* <PropertyHeroSection property={property_detail} /> */}
            {/* <Container>
              <div className="property-detail-box">
                <SectionComponent
                  sectionTitle={"Price"}
                  component={priceComponent}
                />
                <SectionComponent
                  sectionTitle={highlightsHeading}
                  component={highlightsComponent}
                />
                <SectionComponent
                  sectionTitle={thePropertyHeading}
                  component={thePropertyComponent}
                />
                <PropertyDescription
                  property={property_detail}
                  onModalOpen={this.onModalOpen}
                  onModalClose={this.onModalClose}
                />
                <SectionComponent
                  sectionTitle={"Key Figures"}
                  component={keyFiguresComponent}
                />
                <SectionLineComponent mt={1} mb={1} />
                <SectionComponent
                  sectionTitle={"Documents"}
                  component={documentsComponent}
                />
                <SectionComponent
                  sectionTitle={"Legal Information"}
                  component={legalInfoComponent}
                />
                <SectionComponent
                  sectionTitle={"Contact"}
                  component={<ContactProperty property={property_detail} />}
                ></SectionComponent>
                <LocationDetails property={property_detail} />
                <SectionComponent
                  sectionTitle={"Partners"}
                  component={partnersComponent}
                />
              </div>
            </Container> */}
            <FooterComponent />
          </div>
        ) : (
          <Loader />
        )}
      </Fragment>
    );
  }
}

function mapState(state) {
  const { users, general } = state;
  const { property } = general;
  return { users, property };
}

const actionCreators = {
  getProperty: generalActions.getProperty,
  resetProperty: generalActions.resetProperty,
  propertyFileDownload: generalActions.propertyFileDownload,
};

const connectedPropertyDetail = withParams(
  connect(mapState, actionCreators)(PropertyDetail)
);
export { connectedPropertyDetail as PropertyDetailPage };
