import React, { useState, useEffect } from "react";
import { generalService } from "../../_services/general.service";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  TextField,
  InputBase,
  useMediaQuery,
  SvgIcon,
  Button,
} from "@material-ui/core";
import { alpha, withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { ReactComponent as MySvgIcon } from "../../assets/images/all-photos.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useSelector } from 'react-redux';
import ContactAgentForm from './ContactAgentForm';
import YachtSkeletonLoader from './Loaders/YachtSkeletonLoader';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ShowAllBtn = ({ property_images }) => {
  const [openSlidesGallery, setOpenSlidesGallery] = React.useState(false);
  const slides =
    property_images.length > 0
      ? property_images.map((property_image) => {
          return {
            src: property_image.src,
          };
        })
      : [];

  return (
    <>
      <Button
        onClick={() => setOpenSlidesGallery(true)}
        style={{
          textTransform: "none",
          position: "absolute",
          bottom: "8px",
          right: "60px",
        }}
        variant="outlined"
        color="default"
        startIcon={<SvgIcon component={MySvgIcon} viewBox="0 0 20 20" />}
      >
        Show all photos
      </Button>
      <Lightbox
        open={openSlidesGallery}
        close={() => setOpenSlidesGallery(false)}
        slides={slides}
      />
    </>
  );
};

const Yacht = () => {
  const { coinRate, currencyRates, users, authentication } = useSelector(
    (state) => ({
      coinRate: state.general.coinRate,
      currencyRates: state.general.currencyRates,
      users: state.users,
      authentication: state.authentication,
    })
  );
  const isDesktop = useMediaQuery("(min-width:900px)");
  const [activeYachtData, setActiveYachtData] = useState("characteristics");
  const [yachtData, setYachtData] = useState("");
  const [title, setTitle] = useState("");
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const fetchYachts = (scrapeUrl) => {
    setLoading(true);
    generalService
      .scrapeYacht(scrapeUrl)
      .then((response) => {
        // setYachtDetails(response.data.yacht_overview_section)
        // setYachtOverview(response.data.yacht_details);
        setGallery(response.data.carousel);
        setTitle(response.data.title);
        setYachtData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Error fetching yachts");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchYachts(`${location.pathname}`); // Send query string to backend
  }, []);

  if (loading) return <YachtSkeletonLoader />;
  if (error) return <div>{error}</div>;

  const BootstrapInput = withStyles((theme) => ({
    root: {
      width: "100%",
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

  const getImage = (index) => {
    return gallery.pics[index] ? gallery.pics[index].src : "/cover-image.webp";
  };
  const formatCurrencyPrice = (propertyPrice, currencyRate) => {
    if (
      currencyRate !== undefined &&
      currencyRate !== null &&
      !isNaN(currencyRate)
    ) {
      currencyRate *= propertyPrice;
      const parts = currencyRate.toString().split(".");
      parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2).split(".")[1];
      if (parts.length > 1) {
        currencyRate =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "," + parts[1] : "");
      }
      return currencyRate;
    }
    return "-";
  };

  return (
    <div className="yacht-page" style={{ marginBottom: "32px" }}>
      <section className="property-details-hero-section">
        <div className="property-details-hero-container">
          <div className="property-details-hero-title">
            <h1>{title}</h1>
          </div>
          <div className="property-details-hero-images-wrapper">
            <div className="main-image-wrapper">
              <div
                className="main-image"
                style={{ backgroundImage: `url('${getImage(0)}')` }}
              />
            </div>
            <div className="grid-images-wrapper">
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(1)}')` }}
              />
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(2)}')` }}
              />
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(3)}')` }}
              />
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(4)}')` }}
              />
              <ShowAllBtn property_images={gallery.pics} />
            </div>
          </div>
          {!isDesktop ? (
            <Carousel
              responsive={{
                mobile: {
                  breakpoint: {
                    max: 900,
                    min: 0,
                  },
                  items: 1,
                },
              }}
            >
              {gallery.pics.map((image, index) => (
                <div
                  key={index}
                  className="property-details-hero-images-carousel"
                >
                  <div
                    style={{
                      backgroundImage: `url('${getImage(index)}')`,
                    }}
                  />
                </div>
              ))}
            </Carousel>
          ) : null}
          <p className="main-price">{yachtData.price}</p>
        </div>
      </section>
      <section className="yacht-overview-section">
        <div
          className="yacht-overview"
          dangerouslySetInnerHTML={{
            __html: yachtData.yacht_overview_section.replace(
              /<br\s*\/?>/gi,
              ""
            ),
          }}
        />
      </section>
      <section className="yacht-detail-section">
        <div
          style={{ backgroundImage: `url(${yachtData.gallery.first.imgurl})` }}
          className="yacht-detail-container"
        >
          <div className="yacht-detail-data">
            <h3>Details</h3>
            <div className="yacht-detail-filters">
              <div
                className={`yacht-detail-filter ${
                  activeYachtData === "characteristics" &&
                  "yacht-detail-filters-active"
                }`}
                onClick={() => setActiveYachtData("characteristics")}
              >
                Characteristics
              </div>
              <div
                className={`yacht-detail-filter ${
                  activeYachtData === "dimensions" &&
                  "yacht-detail-filters-active"
                }`}
                onClick={() => setActiveYachtData("dimensions")}
              >
                Dimensions
              </div>
              <div
                className={`yacht-detail-filter ${
                  activeYachtData === "speed" && "yacht-detail-filters-active"
                }`}
                onClick={() => setActiveYachtData("speed")}
              >
                speed/weight
              </div>
              <div
                className={`yacht-detail-filter ${
                  activeYachtData === "desk" && "yacht-detail-filters-active"
                }`}
                onClick={() => setActiveYachtData("desk")}
              >
                hull/deck
              </div>
            </div>
            <div className="yacht-detail-data-content">
              {activeYachtData === "characteristics" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: yachtData.characteristics,
                  }}
                />
              )}
              {activeYachtData === "dimensions" && (
                <div
                  dangerouslySetInnerHTML={{ __html: yachtData.dimensions }}
                />
              )}
              {activeYachtData === "speed" && (
                <div dangerouslySetInnerHTML={{ __html: yachtData.speed }} />
              )}
              {activeYachtData === "desk" && (
                <div dangerouslySetInnerHTML={{ __html: yachtData.desk }} />
              )}
            </div>
          </div>
          <div className="yacht-detail-data-img" />
        </div>
      </section>
      <section className="contact-section">
        <div className="contact-section-container">
          <h3>Request Information</h3>
          <p>Our experienced sale brokers are here to answer any questions</p>
          <ContactAgentForm />
        </div>
      </section>

      {/* <section className="yacht-overview" dangerouslySetInnerHTML={{__html: yachtOverview}}  />
      <section className="yacht-details" dangerouslySetInnerHTML={{__html: yachtDetails}} /> */}
    </div>
  );
};

export default Yacht;
