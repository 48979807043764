import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { ClientJS } from "clientjs";

const cjs = new ClientJS();
const fp = CryptoJS.SHA256(
  CryptoJS.SHA256(cjs.getFingerprint()) +
    CryptoJS.SHA256(
      cjs.getCPU() +
        cjs.getBrowser() +
        cjs.getDevice() +
        cjs.getOS() +
        cjs.isMobile()
    )
).toString();

const setSecureCookie = (key, value) => {
  const secKey = CryptoJS.MD5(key).toString();
  return Cookies.set(
    secKey,
    CryptoJS.AES.encrypt(value.toString(), fp, {
      iv: fp,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(),
    {
      secure: true,
      sameSite: true,
      expires: 15,
    }
  );
};

const getSecureCookie = (key) => {
  const secKey = CryptoJS.MD5(key).toString();
  const value = Cookies.get(secKey);
  return value
    ? CryptoJS.AES.decrypt(value, fp, {
        iv: fp,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8)
    : value;
};

const removeCookie = (key, options) => {
  const secKey = CryptoJS.MD5(key).toString();
  Cookies.remove(secKey, options);
};

const secureCookie = {
  set: setSecureCookie,
  get: getSecureCookie,
  remove: removeCookie,
};

const setSecureItem = (key, value) => {
  const secKey = CryptoJS.MD5(key).toString();
  return localStorage.setItem(
    secKey,
    CryptoJS.AES.encrypt(value.toString(), fp, {
      iv: fp,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString()
  );
};

const getSecureItem = (key) => {
  const secKey = CryptoJS.MD5(key).toString();
  const value = localStorage.getItem(secKey);
  return value
    ? CryptoJS.AES.decrypt(value, fp, {
        iv: fp,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8)
    : value;
};

const removeItem = (key) => {
  const secKey = CryptoJS.MD5(key).toString();
  localStorage.removeItem(secKey);
};

const secureStorage = {
  setItem: setSecureItem,
  getItem: getSecureItem,
  removeItem,
};

export { secureCookie, secureStorage };
