import { userConstants } from "../_constants";

export const initialState = {
  languageCode: "en",
};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.PARSE_JWT:
      return {
        ...state,
        token_data: action.status === true ? action.data : null,
      };
    case userConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile_data: action.payload.data,
      };
    case userConstants.GET_PROFILE_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_SECURITY_QUESTIONS_SUCCESS:
      return {
        ...state,
        security_questions_data: action.payload.data,
      };
    case userConstants.GET_SECURITY_QUESTIONS_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_USER_INVESTMENTS_SUCCESS:
      return {
        ...state,
        investment_data: action.payload,
      };
    case userConstants.GET_USER_INVESTMENTS_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_USER_COMMITTED_INVESTMENTS_SUCCESS:
      return {
        ...state,
        committed_investment_data: action.payload,
      };
    case userConstants.GET_USER_COMMITTED_INVESTMENTS_FAILURE:
      return {
        error: action.error,
      };

    case userConstants.GET_USER_LATEST_INVESTMENTS_SUCCESS:
      return {
        ...state,
        latest_investment_data: action.payload,
      };
    case userConstants.GET_USER_LATEST_INVESTMENTS_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_USER_PAYMENTS_SUCCESS:
      return {
        ...state,
        user_payments: action.payload,
      };
    case userConstants.GET_USER_PAYMENTS_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        user_orders: action.payload,
      };
    case userConstants.GET_USER_ORDERS_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_USER_RECENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        recent_transactions: action.payload,
      };
    case userConstants.GET_USER_RECENT_TRANSACTION_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_USERS_ORDERS_SUCCESS:
      return {
        ...state,
        users_orders: action.payload,
      };
    case userConstants.GET_USERS_ORDERS_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings_data: action.payload,
      };
    case userConstants.GET_SETTINGS_FAILURE:
      return {
        error: action.payload,
      };
    case userConstants.GET_RESET_MAIL_SENT:
      return {
        ...state,
        reset_email_res: action.payload,
      };
    case userConstants.GET_RESET_MAIL_FAILURE:
      return {
        ...state,
        reset_email_res: action.payload,
      };
    case userConstants.GET_RESET_PASSWORD:
      return {
        ...state,
        reset_pass_res: action.payload,
      };
    case userConstants.GET_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        reset_pass_res: action.payload,
      };
    case userConstants.GET_DEPOSIT_MONEY_REQUEST_SUCCESS:
      return {
        ...state,
        deposit_request_res: action.payload,
      };
    case userConstants.GET_DEPOSIT_MONEY_REQUEST_FAILURE:
      return {
        ...state,
        deposit_request_res: action.payload,
      };
    case userConstants.CLAIM_TOKENS_REQUEST_SUCCESS:
      return {
        ...state,
        tokens_claimed: action.payload,
      };
    case userConstants.CLAIM_TOKENS_REQUEST_FAILURE:
      return {
        ...state,
        tokens_claimed: action.payload,
      };
    case userConstants.GET_DEPOSIT_MONEY_SUCCESS:
      return {
        ...state,
        deposit_res: action.payload,
      };
    case userConstants.GET_DEPOSIT_MONEY_FAILURE:
      return {
        ...state,
        deposit_res: action.payload,
      };
    case userConstants.GET_USER_UNREALISED_PROFIT_SUCCESS:
      return {
        ...state,
        unrealised_profit: action.payload,
      };
    case userConstants.GET_USER_REALISED_PROFIT_SUCCESS:
      return {
        ...state,
        realised_profit: action.payload,
      };
    case userConstants.GET_USER_REALISED_PROFIT_DATA_SUCCESS:
      return {
        ...state,
        realised_profit_data: action.payload,
      };
    default:
      return state;
  }
}
