import React, { Component } from "react";
import Gallery from "react-grid-gallery";
import { Grid, Link } from "@material-ui/core";

class ImageComponent extends React.Component {
  render() {
    return (
      <img
        alt="Property"
        className="property-image"
        {...this.props.imageProps}
      />
    );
  }
}

export default class PropertyImages extends Component {
  handleDataTransformation = (property_pictures) => {
    return property_pictures.map((picture) => {
      return {
        src: picture.property_picture_preview,
        thumbnailWidth: 500,
        thumbnailHeight: 300,
        thumbnail: picture.property_picture_preview,
      };
    });
  };

  render() {
    const { property } = this.props;
    const isPropertyPictures =
      property &&
      property.property_picture &&
      property.property_picture.length > 0;
    const property_pictures = property.property_picture.filter(
      (p) => !p.is_delete
    );

    return (
      <div className="property-description-box">
        {isPropertyPictures && (
          <Grid container>
            <Grid item md={12} xs={12}>
              <Gallery
                margin={10}
                images={this.handleDataTransformation(property_pictures)}
                maxRows={2}
                thumbnailImageComponent={ImageComponent}
                enableImageSelection={false}
                showLightboxThumbnails={true}
              />
            </Grid>
          </Grid>
        )}
        {isPropertyPictures && (
          <Link className="show-all-photos">
            Explore all ({property_pictures.length}) photos {">"}
          </Link>
        )}
      </div>
    );
  }
}
