import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { userActions } from "../../_actions";
import TradeModal from "../../components/DashboardComponent/modals/TradeModal";
import { withRouter } from "../../_helpers";
import { secureStorage } from "../../_services";
import ShareButton from "../../components/Share";
import { Link } from "react-router-dom";

class PriceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      isUserLoggedIn: false,
      order_details: {
        _property_id: props.property.property_id,
        _order_reference_id: null,
        _user_reference_id: 0,
        order_type: "Buy",
        quantity: 0,
        price: 0,
        amount: 0,
        fee: 0,
        fee_amount: 0,
        total_amount: 0,
        payment_method: "account balance",
        order_complete: false,
      },
    };
  }

  componentDidMount() {
    this.setInitialValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.property.property_id !== prevProps.property.property_id) {
      this.setInitialValues();
    }
  }

  componentWillUnmount() {
    this.state.intervalId && clearInterval(this.state.intervalId);
  }

  setInitialValues = () => {
    const tokenInfo = this.props.parseJwt();
    if (tokenInfo && tokenInfo.status) {
      const { property_price } = this.props.property;
      const quantity = 1,
        fee = 2.5;

      let fee_amount = (property_price * fee) / 100;
      if (fee_amount % 1 !== 0) {
        fee_amount = parseFloat(fee_amount.toFixed(2));
      }

      let _user_reference_id = secureStorage.getItem("user_id");
      try {
        if (this.props.users.profile_data.user_id) {
          _user_reference_id = this.props.users.profile_data.user_id;
        }
      } catch (err) {
        console.log("Error occurred", err);
      }

      this.setState({
        isUserLoggedIn: true,
        order_details: {
          ...this.state.order_details,
          quantity,
          fee,
          fee_amount,
          _user_reference_id,
          _property_id: this.props.propertyId,
          price: property_price,
          amount: quantity * property_price,
          total_amount: property_price + fee_amount,
        },
      });
    }
  };

  openOrderBookModal = () => {
    const walletAddress = secureStorage.getItem("wallet_address");
    if (this.state.isUserLoggedIn) {
      if (!walletAddress) {
        this.props.navigate(TOKEN_WALLET_PAGE_URL);
      } else if (this.props.propertyId) {
        this.props.getUserDetails();
        this.props.getSettings();
        this.props.getUserRecentTransactions(this.props.propertyId);
        this.props.getUsersOrders(this.props.propertyId);
        this.setState({ modalStatus: true });
      }
    } else {
      this.props.navigate("/login");
    }
  };

  formatCurrencyPrice = (propertyPrice, currencyRate) => {
    if (
      currencyRate !== undefined &&
      currencyRate !== null &&
      !isNaN(currencyRate)
    ) {
      currencyRate *= propertyPrice;
      const parts = currencyRate.toString().split(".");
      parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2).split(".")[1];
      if (parts.length > 1) {
        currencyRate =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "," + parts[1] : "");
      }
      return currencyRate;
    }
    return "-";
  };

  formatCoinPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      const parts = val.toString().split(".");
      parts[1] = parseFloat(`0.${parts[1]}`).toFixed(8).split(".")[1];
      if (parts.length > 1) {
        val =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "," + parts[1] : "");
      }
      return val;
    }
    return "-";
  };

  formatPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      const parts = val.toString().split(".");
      if (parts.length > 1) {
        val =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          (parts[1] ? "." + parts[1] : "");
      }
      val = this.numberWithCommas(val);
      return val;
    }
    return "-";
  };

  numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  };

  getButton = (userId, propertyOwnerId, isSold) => {
    if (isSold) {
      return this.getButtonComponent(
        "disabled",
        "This property is already sold!"
      );
    } else if (userId === propertyOwnerId) {
      return this.getButtonComponent("disabled", "You own this property");
    } else if (!secureStorage.getItem("is_kyc_verified")) {
      if (!userId) {
        return this.getButtonComponent(
          "disabled",
          "You must Register and be KYC verified in order to purchase property"
        );
      } else {
        return this.getButtonComponent(
          "disabled",
          "You must Register and be KYC verified in order to purchase property",
          true
        );
      }
    } else if (!secureStorage.getItem("wallet_address")) {
      return this.getButtonComponent(
        "disabled",
        "Your token wallet isn't configured properly, please go to the Wallet Page to rectify the issue before you can purchase properties"
      );
    }

    return this.getButtonComponent();
  };

  getButtonComponent = (type, message, kyc = false) => {
    if (type === "disabled") {
      return (
        <>
          <Button
            disabled
            variant="outlined"
            color="primary"
            className="purchase-button"
            style={{
              border: "3.5px solid #2BAAEA",
              width: "163px",
              height: "62px",
              boxShadow: "5px 7px 10px rgba(74, 191, 247, 0.25)",
            }}
            onClick={this.openOrderBookModal}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "800",
                lineHeight: "18px",
                fontSize: "18px",
              }}
            >
              Purchase Property
            </Typography>
          </Button>
          <br />
          <p>
            * {message}
            {kyc ? (
              <>
                <br />
                <Link to={"/users/kyc"}>
                  Get KYC verified, it only takes a couple of minutes
                </Link>
              </>
            ) : null}
          </p>
        </>
      );
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          style={{
            border: "3.5px solid #2BAAEA",
            width: "163px",
            height: "62px",
            boxShadow: "5px 7px 10px rgba(74, 191, 247, 0.25)",
          }}
          className="purchase-button"
          onClick={this.openOrderBookModal}
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "800", lineHeight: "18px", fontSize: "18px" }}
          >
            Purchase Property
          </Typography>
        </Button>
      );
    }
  };

  closeModal = () => {
    this.props.onModalClose();
    this.setState({ modalStatus: false });
  };

  render() {
    const { property } = this.props;

    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" style={{ fontWeight: "800" }}>
              &#36;{this.formatPrice(property.property_price)}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                Price in different currencies
              </Typography>
              <Box>
                <Typography variant="body2" display="inline">
                  EUR{" "}
                </Typography>
                <Typography
                  variant="body2"
                  display="inline"
                  color="primary"
                  style={{ fontWeight: "800" }}
                >
                  {this.props.currencyRates?.EUR
                    ? this.formatCurrencyPrice(
                        property.property_price,
                        this.props.currencyRates.EUR
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Purchase with Bitcoin</Typography>
              <Box>
                <Typography variant="body2" display="inline">
                  BTC{" "}
                </Typography>
                <Typography
                  variant="body2"
                  display="inline"
                  color="primary"
                  style={{ fontWeight: "800" }}
                >
                  {this.props.coinRate?.BTC
                    ? this.formatCoinPrice(
                        property.property_price / this.props.coinRate.BTC
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Purchase with Ether</Typography>
              <Box>
                <Typography variant="body2" display="inline">
                  ETH{" "}
                </Typography>
                <Typography
                  variant="body2"
                  display="inline"
                  color="primary"
                  style={{ fontWeight: "800" }}
                >
                  {this.props.coinRate?.ETH
                    ? this.formatCoinPrice(
                        property.property_price / this.props.coinRate.ETH
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {this.getButton(
              this.state.order_details._user_reference_id,
              property._user_id,
              property.is_sold
            )}
            <ShareButton
              title={this.props.title}
              text={this.props.description}
            />
          </Grid>
        </Grid>
        <TradeModal
          open={this.state.modalStatus}
          orderDetails={this.state.order_details}
          property={this.props.property}
          user={this.props.users.profile_data}
          handleClose={this.closeModal}
          toggleNotification={this.props.toggleNotification}
        />
      </Box>
    );
  }
}

function mapState(state) {
  const {
    general: { coinRate, currencyRates },
    users,
    authentication,
  } = state;
  return { coinRate, currencyRates, users, authentication };
}

const actionCreators = {
  parseJwt: userActions.parseJwt,
  getUserRecentTransactions: userActions.getUserRecentTransactions,
  getUsersOrders: userActions.getUsersOrders,
  getUserDetails: userActions.getUserDetails,
  getSettings: userActions.getSettings,
};

export default withRouter(connect(mapState, actionCreators)(PriceComponent));
