import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import supercluster from "points-cluster";
import RoomIcon from "@material-ui/icons/Room";
import "./styles.scss";

// InfoWindow Component
const InfoWindow = ({ property }) => {
  if (!property || Object.keys(property).length === 0) return null;

  const numberWithCommas = (x) =>
    x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : x;

  return (
    <div className="info-window">
      <NavLink to={`/property/view/${property.property_id}`}>
        <div className="cover-image-container">
          {property.property_images && property.property_images.length > 0 && (
            <div
              className="image"
              style={{
                backgroundImage: `url('${property.property_images[0].image_path}')`,
              }}
            ></div>
          )}
        </div>
        <div className="property-title">
          <span>{property.title}</span>
        </div>
        <div className="property-address">
          {property.address_city && property.address_country && (
            <span>
              {property.address_city}, {property.address_country}
            </span>
          )}
        </div>
        <div className="property-price">
          {property.property_type === "primary" && (
            <span className="txt-value">
              &#36; {numberWithCommas(property.financing_volume)}
            </span>
          )}
        </div>
      </NavLink>
    </div>
  );
};

// Marker Component
const Marker = ({ property }) => (
  <div className="marker">
    <RoomIcon />
    <InfoWindow property={property} />
  </div>
);

// ClusterMarker Component
const ClusterMarker = ({ points }) => (
  <div className="cluster-marker">{points.length}</div>
);

// PropertyMapView Component
const PropertyMapView = ({ properties = [], style }) => {
  const [availableLocationCoords, setAvailableLocationCoords] = useState([]);
  const [mapOptions, setMapOptions] = useState({
    center: {
      lat: parseFloat(process.env.REACT_APP_MAP_LAT),
      lng: parseFloat(process.env.REACT_APP_MAP_LNG),
    },
    zoom: parseInt(process.env.REACT_APP_MAP_ZOOM),
    bounds: null,
  });
  const [clusters, setClusters] = useState([]);

  const mapRef = useRef(null);
  const mapsRef = useRef(null);

  // Effect to update availableLocationCoords when properties change
  useEffect(() => {
    const tempArray = properties
      .map((property, index) => {
        const lat = property.location_coords
          ? parseFloat(property.location_coords.lat)
          : null;
        const lng = property.location_coords
          ? parseFloat(property.location_coords.lng)
          : null;
        if (!isNaN(lat) && !isNaN(lng)) {
          return { lat, lng, id: index };
        }
        return null;
      })
      .filter(Boolean);
    setAvailableLocationCoords(tempArray);
  }, [properties]);

  // Effect to create clusters when availableLocationCoords or mapOptions change
  useEffect(() => {
    if (mapOptions.bounds) {
      const clusters = getClusters();
      setClusters(
        clusters.map(({ wx, wy, numPoints, points }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: `${numPoints}_${points[0].id}`,
          points,
        }))
      );
    }
  }, [availableLocationCoords, mapOptions]);

  // **New useEffect to adjust map bounds when properties change**
  useEffect(() => {
    if (mapRef.current && mapsRef.current && properties.length > 0) {
      const bounds = getMapBounds(mapsRef.current, properties);
      mapRef.current.fitBounds(bounds);
    }
  }, [properties]);

  // Function to get clusters using supercluster
  const getClusters = () => {
    const clusters = supercluster(availableLocationCoords, {
      minZoom: 0,
      maxZoom: 16,
      radius: 30,
    });
    return clusters(mapOptions);
  };

  // Function to handle map changes
  const handleMapChange = ({ center, zoom, bounds }) => {
    setMapOptions((prev) => ({
      ...prev,
      center,
      zoom,
      bounds,
    }));
  };

  // Function to get map bounds based on properties
  const getMapBounds = (maps, properties) => {
    const bounds = new maps.LatLngBounds();
    properties.forEach((property) => {
      if (property.location_coords) {
        const lat = parseFloat(property.location_coords.lat);
        const lng = parseFloat(property.location_coords.lng);
        if (!isNaN(lat) && !isNaN(lng)) {
          bounds.extend(new maps.LatLng(lat, lng));
        }
      }
    });
    return bounds;
  };

  // Function to adjust map when API is loaded
  const apiIsLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;

    if (properties.length > 0) {
      const bounds = getMapBounds(maps, properties);
      map.fitBounds(bounds);

      // Use standard addEventListener
      window.addEventListener("resize", () => {
        map.fitBounds(bounds);
      });
    }
  };

  // Function to find property by coordinates
  const getPropertyDetailByLocationCoords = (lat, lng) => {
    const property = properties.find((property) => {
      const propertyLat = property.location_coords
        ? parseFloat(property.location_coords.lat)
        : null;
      const propertyLng = property.location_coords
        ? parseFloat(property.location_coords.lng)
        : null;
      return propertyLat === lat && propertyLng === lng;
    });
    return property || {};
  };

  return (
    <div style={style} className="property-map-section">
      {properties.length > 0 && (
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultCenter={{
              lat: parseFloat(process.env.REACT_APP_MAP_LAT),
              lng: parseFloat(process.env.REACT_APP_MAP_LNG),
            }}
            defaultZoom={parseInt(process.env.REACT_APP_MAP_ZOOM)}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals={true}
            onChange={handleMapChange}
          >
            {clusters.map((cluster) => {
              if (cluster.numPoints === 1) {
                const { lat, lng } = cluster.points[0];
                const property = getPropertyDetailByLocationCoords(lat, lng);
                return (
                  <Marker
                    key={cluster.id}
                    lat={lat}
                    lng={lng}
                    property={property}
                  />
                );
              }
              return (
                <ClusterMarker
                  key={cluster.id}
                  lat={cluster.lat}
                  lng={cluster.lng}
                  points={cluster.points}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default PropertyMapView;
