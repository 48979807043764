import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  TablePagination,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { generalService } from "../../_services";
import "./styles.scss";

class ManagePartnerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: [],
      page: {
        size: 10,
        page: 0,
        count: 0,
      },
    };
  }

  componentDidMount() {
    this.loadPartners();
  }

  static getDerivedStateFromProps(props, state) {
    const { partners } = props.general;
    if (partners && partners.status === true) {
      return {
        partners: partners.data,
      };
    }
    return null;
  }

  loadPartners() {
    const { page } = this.state;
    this.props.clearAlerts();
    generalService.getPartner(page.page, page.size).then((data) => {
      if (data.status) {
        this.setState({
          ...this.state,
          partners: data.data,
          page: {
            ...this.state.page,
            count: data.pagination.count,
          },
        });
      } else {
        alertError.error(data.message.toString());
      }
    });
  }

  handlePageChange = (page) => {
    this.setState(
      {
        ...this.state,
        page,
      },
      () => this.loadPartners()
    );
  };

  handlePartnerRemove = (id) => {
    this.props.deletePartnerById(id).then((value) => {
      this.loadPartners();
    });
  };

  render() {
    const { alert } = this.props;
    const { partners, page } = this.state;

    return (
      <div className="main-content manage-partner-page">
        <DasboardNavBarComponent pageName="manage-partner" />
        <Box mb={3}>
          <Link to="/dashboard/partners/add">
            <Button variant="contained" color="primary">
              Add Partner
            </Button>
          </Link>
        </Box>

        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}

        <Card>
          <CardContent className="table-scroll">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Logo</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {partners.length > 0 && (
                  <>
                    {partners.map((partner, index) => {
                      return (
                        <tr key={index}>
                          <td align="center">
                            {page.page * page.size + index + 1}
                          </td>
                          <td align="center">{partner.name}</td>
                          <td align="center">
                            <img
                              alt="partner-logo"
                              src={partner.logo}
                              className="partner-logo"
                            />
                          </td>
                          <td align="center">
                            {ReactHtmlParser(partner.description)}
                          </td>
                          <td align="center">
                            <Link to={"/dashboard/partners/edit/" + partner.id}>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                style={{ marginRight: 5 }}
                              >
                                Edit
                              </Button>
                            </Link>
                            <Button
                              variant="outlined"
                              size="small"
                              color="secondary"
                              onClick={() =>
                                this.handlePartnerRemove(partner.id)
                              }
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                <tr>
                  <TablePagination
                    rowsPerPageOptions={[10, 50]}
                    labelRowsPerPage="Items per page:"
                    page={page.page}
                    count={page.count}
                    rowsPerPage={page.size}
                    onRowsPerPageChange={(e) => {
                      this.handlePageChange({
                        ...page,
                        page: 0,
                        size: e.target.value,
                      });
                    }}
                    onPageChange={(e, idx) => {
                      this.handlePageChange({ ...page, page: idx });
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapState(state) {
  const { alert, general } = state;
  return { alert, general };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  alertError: alertActions.error,
  deletePartnerById: generalActions.deletePartnerById,
};

const connectedPage = connect(mapState, actionCreators)(ManagePartnerPage);
export { connectedPage as ManagePartnerPage };
