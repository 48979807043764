import React from "react";
import AgentsImg from "../../assets/images/trust-agent.png";

const SearchingForAgentSection = () => {
  return (
    <section className="searching-for-agent-section">
      <div className="searching-for-agent-container">
        <div className="searching-for-agent-img">
          <img src={AgentsImg} alt="trusted agent" />
        </div>
        <div className="searching-for-agent-content">
          <h4>
            Searching for a <b>trusted real estate agent?</b>
          </h4>
          <p>
            Discover experienced professionals ready to provide expert guidance
            and personalized support for all your home buying, selling and
            investment needs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SearchingForAgentSection;
