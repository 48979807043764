import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Typography,
  Link,
  Dialog,
  DialogContent,
  Button,
} from "@material-ui/core";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { userActions, alertActions } from "../../_actions";
import { history } from "../../_helpers";
import "./styles.scss";
import ConfirmModal from "../../components/ConfirmModal";
import arrow_white_icon from "../../assets/images/arrow-right-white-icon.png";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    var isConfirm = false;
    if (history.location && history.location.search === "?success") {
      isConfirm = true;
    }

    this.state = {
      user: {
        email: "",
        password: "",
      },
      confirmModalStatus: false,
      input_notify: false,
      isConfirm: isConfirm,
      reset_email_res: {},
    };
    this.props.clearAlerts();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.reset_email_res !== prevState.reset_email_res) {
      return {
        ...prevState,
        reset_email_res: nextProps.reset_email_res,
      };
    }
    return null;
  }

  handleChange = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  openConfirmModal = () => {
    const email = this.state.user.email;
    if (!email.match(/.{2,}@{1}.{2,}\..+/)) {
      this.setState({ input_notify: true });
      setTimeout(() => {
        this.setState({ input_notify: false });
      }, 2000);
      return;
    }

    this.setState({ confirmModalStatus: true });
  };

  closeConfirmModal = () => {
    this.setState({ confirmModalStatus: false });
  };

  handleSendEmail = async () => {
    await this.props.sendResetEmail(this.state.user.email);
    this.closeConfirmModal();
  };

  generateToken(length) {
    return Math.random().toString(36).substr(2, length);
  }

  handleSubmit = () => {
    this.props.clearAlerts();

    var email_id = this.state.user.email;
    var password = this.state.user.password;

    if (email_id && password) {
      this.props.login(email_id, password);
    }
    // TODO FIX AUTH
    setTimeout(() => {
      const historyState = history.location.state;
      if (this.props.loggedIn !== undefined && this.props.loggedIn) {
        if (
          historyState &&
          historyState !== undefined &&
          historyState.next &&
          historyState.next !== undefined
        ) {
          window.location = historyState.next;
        } else {
          window.location = "/";
        }
      }
    }, 1000);
  };

  render() {
    const { user } = this.state;
    const { alert } = this.props;

    return (
      <div className="login-hero-banner">
        <div className="login-box">
          <div className="form-rounded-container">
            <img src={arrow_white_icon} alt="arrow-icon" />
          </div>

          <ValidatorForm
            className="form"
            id="register-login-form"
            onSubmit={this.handleSubmit}
            ref="form"
            style={{ marginTop: "10px", padding: "0" }}
            autoComplete="off"
          >
            {alert.message && (
              <NotificationBox
                open={true}
                variant={alert.type}
                message={alert.message}
              />
            )}

            {this.state.input_notify && (
              <NotificationBox
                open={true}
                variant={"error"}
                message={"Mail input incorrect"}
              />
            )}

            <div style={{ margin: "0 52px" }}>
              <TextValidator
                fullWidth
                value={user.email}
                onChange={this.handleChange}
                name="email"
                className="form-control"
                id="outlined-name"
                label="Email Address"
                margin="normal"
                variant="outlined"
                validators={["required", "isEmail"]}
                errorMessages={[
                  "this field is required",
                  "Email address is not valid",
                ]}
              />
              <TextValidator
                fullWidth
                value={user.password}
                onChange={this.handleChange}
                name="password"
                type="password"
                className="form-control"
                id="outlined-name"
                label="Password"
                margin="normal"
                variant="outlined"
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <Button
                type="submit"
                variant="outlined"
                color="default"
                // className="btn-primary btn-filled-out btn-submit"
                style={{
                  backgroundColor: "#2BAAEA",
                  borderRadius: "4px",
                  color: "#fff",
                  border: "none",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  width: "119px",
                  height: "48px",
                  margin: "35px 0 25px",
                }}
              >
                Login
              </Button>
            </div>

            <div
              style={{
                backgroundColor: "#3C4E55",
                padding: "20px 16px 16px",
                borderRadius: "0 0 16px 16px",
              }}
            >
              <Typography
                variant="body2"
                style={{ marginBottom: "15px", color: "#fff" }}
              >
                Forgot password?
                <Link
                  style={{ color: "#fff", fontSize: "15px" }}
                  onClick={this.openConfirmModal}
                >
                  <strong style={{ color: "#2BAAEA" }}> Click here </strong>
                </Link>
                to reset your password
              </Typography>
              <Typography align="center" variant="body2">
                <NavLink
                  to="/register"
                  style={{ color: "#fff", fontSize: "15px" }}
                >
                  Not registered yet? <strong>Create</strong> an account.
                </NavLink>
              </Typography>
            </div>
          </ValidatorForm>

          <ConfirmModal
            open={this.state.confirmModalStatus}
            handleClose={this.closeConfirmModal}
            handleConfirm={this.handleSendEmail}
            email={this.state.user.email}
          />

          <Dialog
            open={this.state.isConfirm}
            onClose={() => this.setState({ isConfirm: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent className="modal-success">
              <Typography align="center" color="initial" variant="body2">
                Thank you for confirming your email address. You can now log in.
              </Typography>

              <Typography align="center" variant="body2">
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => this.setState({ isConfirm: false })}
                  className="btn-primary btn-filled-out btn-login btn-width-300"
                >
                  Ok
                </Button>
              </Typography>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  const { alert, users } = state;
  const { reset_email_res } = state.users;
  return { loggedIn, alert, reset_email_res, users };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  login: userActions.login,
  logout: userActions.logout,
  sendResetEmail: userActions.sendResetEmail,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };

export default LoginPage;
