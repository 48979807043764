import React, { Component } from "react";
import LocationPicker from "react-location-picker";

export default class PropertyMapView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPosition: {
        lat: parseFloat(process.env.REACT_APP_MAP_LAT),
        lng: parseFloat(process.env.REACT_APP_MAP_LNG),
      },
      zoom: parseInt(process.env.REACT_APP_MAP_ZOOM),
    };
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  // If needed to show user current position on map uncomment following code from componentDidMount
  // componentDidMount() {
  //     navigator && navigator.geolocation.getCurrentPosition(position => {
  //         const { latitude, longitude } = position.coords;
  //         console.log(latitude, longitude, "LATLONNNN")
  //         this.setState({
  //             defaultPosition: {
  //                 lat: latitude,
  //                 lng: longitude
  //             }
  //         });
  //     });
  // }

  setLocationMarkerOnChangePlaceFromParent = (position) => {
    const { lat, lng } = position;
    this.setState({
      defaultPosition: {
        lat: lat,
        lng: lng,
      },
      zoom: 15,
    });
  };

  handleLocationChange({ position, address, places }) {
    this.props.handleAddPropertyLocation({ position, address, places });
  }

  render() {
    return (
      <div>
        <LocationPicker
          containerElement={<div style={{ height: "100%", width: "100%" }} />}
          mapElement={<div style={{ height: "668px" }} />}
          defaultPosition={this.state.defaultPosition}
          onChange={this.handleLocationChange}
          radius={-1}
          zoom={this.state.zoom}
        />
      </div>
    );
  }
}
