import { memo, useCallback, useEffect, useState } from 'react';
import ArrowRight from "@material-ui/icons/ArrowForwardIos";
import style from './style.module.scss';

/**
 * @name WelcomeTypesPopup
 * @description This component is used to display the welcome types popup
 * @author Damir M.<dmozar@gmail.com>
 * @returns 
 */
const WelcomeTypesPopup = () => {

    const [open, setOpen] = useState(false);

    /**
     * @name isShow
     * @description This function is used to check if the popup should be shown
     * @author Damir M.<dmozar@gmail.com>
     */
    const isShow = useCallback(() => {
        const isShow = localStorage.getItem('_immo_wtp');
        return isShow === 'true';
    }, [open]);

    /**
     * @name saveSession
     * @description This function is used to save the session in the local storage
     * @author Damir M.<dmozar@gmail.com>
     */
    const saveSession = () => {
        localStorage.setItem('_immo_wtp', 'true');
    };

    /**
     * @name handleClose
     * @description This function is used to handle the close event of the popup and save session. Use stay on the same page when the popup is closed.
     * @author Damir M.<dmozar@gmail.com>
     * @param {*} e 
     * @returns 
     */
    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(false);
        saveSession();
        return false;
    }

    /**
     * @name handleShow
     * @description This function is used to handle the show event of the popup and check if the popup should be shown or not. If the popup should be shown, then open the popup. Otherwise, do nothing.
     * @author Damir M.<dmozar@gmail.com>
     * @returns
     * @returns 
     */
    const handleShow = () => {
        if(isShow())  return;

        const element = document.getElementById('welcome-type-popup');
        if(!element) return;

        const rect = element.getBoundingClientRect();
        if(rect.top < window.innerHeight) {
            setOpen(true);
        }
    }

    /**
     * @name hadleClick
     * @description This function is used to handle the click event of the popup and save session. User is linked to another page.
     * @param {*} e
     * @returns
     * @returns
     */
    const hadleClick = (e) => {
        saveSession();
        setOpen(false);
        return true;
    }

    /**
     * @name useEffect
     * @description Track use scroll and trigger show popup event when the popup is not shown yet. Remove event listener when the component is unmounted.
     * Show is triggered when the popup is in the viewport.
     */
    useEffect(() => {
        if(isShow())  return;
        window.addEventListener('scroll', handleShow);
        return () => {
            window.removeEventListener('scroll', handleShow);
        }
    }, []);

    // Render the component
    return (<div id="welcome-type-popup" className={[style.popup, (open ? style.active : '')].join(' ')}>
        <div className={style.box}>
            <h2>Get Started</h2>
            <div className={style.content}>
                <a href="/get-started/agent" className={style.btn} onClick={hadleClick}>I'm an Agent</a>
                <a href="/get-started/seller" className={style.btn} onClick={hadleClick}>I'm a Seller</a>
                <a href="/get-started/buyer" className={style.btn} onClick={hadleClick}>I'm a Buyer</a>
                <a href="#" onClick={handleClose} className={style.btnLink}>I'm just curious <ArrowRight fontSize="small" /></a>
            </div>
        </div>
    </div>);
}


export default memo(WelcomeTypesPopup);