import React, { Component } from "react";
import { Container, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import "react-input-range/lib/css/index.css";
import main_bg_image from "../../assets/images/main-bg-image-new.jpg";
import saerch_white_icon from "../../assets/images/white-search-icon.png";
import "./styles.scss";
import Filters from "./Filters";

class HeroBanner extends Component {
  constructor(props) {
    super(props);

    this.search_filter = React.createRef();
    this.key_figures_filter = React.createRef();

    this.wordInterval = React.createRef();
    this.sleepTimeout = React.createRef();
    this.sleepDuration = 220;
    this.changeDuration = 4000;

    this.words = [
      "property",
      "villa",
      "apartment",
      "house",
      "mansion",
      "hotel",
      "condo",
      "land",
    ];

    this.state = {
      availableCountries: [],
      availableLocations: [],
      searchFilterOpen: false,
      keyFiguresFilterOpen: false,
      propertyValueRange: { min: 0, max: 330000 },
      discoverWord: "property",

      search: {
        location: [],
        countries: [],
        type: [],
        categoryType: [],
        status: "",
        keyFigures: {
          Bedrooms: {
            min: 0,
            max: 0,
          },
          Bathrooms: {
            min: 0,
            max: 0,
          },
          "Parking Spaces": {
            min: 0,
            max: 0,
          },
          "Sq Feet": {
            min: 0,
            max: 0,
          },
        },
        propertyValue: { min: 0, max: 0 },
        pricePerShareValue: { min: 0, max: 340 },
        dividendYieldValue: { min: 0, max: 4.2 },
        order: "",
        availability: false,
      },
      locations: [],
      properties: {},
    };
  }

  // iterateRandomWord(){

  //   if(this.wordInterval.current){
  //     clearInterval(this.wordInterval.current);
  //   }

  //   const sleep = () => {
  //     return new Promise((resolve, reject) => {
  //       if(this.sleepTimeout.current) clearTimeout(this.sleepTimeout.current);
  //       this.sleepTimeout.current = setTimeout(() => {
  //         resolve();
  //       }, this.sleepDuration);
  //     });
  //   }

  //   this.wordInterval.current = setInterval(async () => {
  //     const randomWord = this.words[Math.floor(Math.random() * this.words.length)];
  //     let x = 0;
  //     let w = '';
  //     for(let i = 0; i < randomWord.length; i++){
  //       w += randomWord[i];
  //       await sleep();
  //       this.setState({discoverWord: w});
  //     }
  //   }, this.changeDuration);
  // }

  componentDidMount() {
    if (
      Object.keys(this.props.activePropertyLocations).length > 0 &&
      this.state.availableCountries.length === 0
    ) {
      const availableCountries = [];
      Object.keys(this.props.activePropertyLocations).forEach((key) => {
        availableCountries.push(key);
      });
      this.setState({
        ...this.state,
        availableCountries: availableCountries,
      });
    }
    ValidatorForm.addValidationRule("belowThan", (value) => {
      return value < this.state.propertyValueRange.max;
    });

    ValidatorForm.addValidationRule("aboveThan", (value) => {
      return value > this.state.propertyValueRange.min;
    });

    // this.iterateRandomWord();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(prevProps.activePropertyLocations).length !==
      Object.keys(this.props.activePropertyLocations).length
    ) {
      const availableCountries = [];
      if (
        Object.keys(this.props.activePropertyLocations).length > 0 &&
        this.state.availableCountries.length === 0
      ) {
        Object.keys(this.props.activePropertyLocations).forEach((key) => {
          availableCountries.push(key);
        });
      }
      this.setState({
        ...this.state,
        availableCountries: availableCountries,
      });
    }
    if (
      prevState.search.countries.length !== this.state.search.countries.length
    ) {
      const removedCountry =
        prevState.search.countries.length > this.state.search.countries.length;
      this.generateAvailableLocationsOptions(removedCountry);
    }
  }

  generateAvailableLocationsOptions(removedCountry) {
    const selectedCountries = [...this.state.search.countries];
    const availableLocations = [];
    if (selectedCountries.length > 0) {
      selectedCountries.forEach((country) =>
        this.props.activePropertyLocations[country].forEach(
          (countryLocation) => {
            availableLocations.push({
              locationName: countryLocation,
              locationCountry: country,
            });
          }
        )
      );
      if (removedCountry) {
        this.setState((prevState) => ({
          search: {
            ...prevState.search,
            location: [],
          },
        }));
      }
    }
    this.setState({ availableLocations });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("belowThan");
    ValidatorForm.removeValidationRule("aboveThan");

    if (this.wordInterval.current) clearInterval(this.wordInterval.current);
    if (this.sleepTimeout.current) clearTimeout(this.sleepTimeout.current);
  }

  handleChange = (event) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [event.target.name]: event.target.value,
      },
    }));
  };

  setFilter = (value, type) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value,
      },
    }));
  };

  toggleSearchFilter = () => {
    this.setState({ searchFilterOpen: !this.state.searchFilterOpen });
  };

  toggleKeyFiguresFilter = () => {
    this.setState({ keyFiguresFilterOpen: !this.state.keyFiguresFilterOpen });
  };

  formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  getSearchQueryParams = () => {
    const { search } = this.state;
    const params = new URLSearchParams();

    if (search["location"].length) {
      params.append("location", JSON.stringify(search["location"]));
    }
    if (search["countries"].length) {
      params.append("countries", JSON.stringify(search["countries"]));
    }
    if (search["type"].length) {
      params.append("type", JSON.stringify(search["type"]));
    }
    if (search["categoryType"].length) {
      params.append("categoryType", JSON.stringify(search["categoryType"]));
    }
    if (search["status"]) {
      params.append("status", search["status"]);
    }
    if (search["propertyValue"]["min"] || search["propertyValue"]["max"]) {
      params.append("propertyValue", JSON.stringify(search["propertyValue"]));
    }

    const keyFigures = {};
    for (const key in search["keyFigures"]) {
      if (
        search["keyFigures"][key]["min"] ||
        search["keyFigures"][key]["max"]
      ) {
        keyFigures[key] = search["keyFigures"][key];
      }
    }

    if (Object.keys(keyFigures).length) {
      params.append("keyFigures", JSON.stringify(keyFigures));
    }

    return params.toString();
  };

  render() {
    const { search, availableCountries, availableLocations } = this.state;
    return (
      <div
        className="hero-banner"
        style={{
          backgroundImage:
            this.props.background != ""
              ? `url(${this.props.background})`
              : `url(${main_bg_image})`,
        }}
      >
        <Container className="hero-search">
          <h2 className="search-title">
            <div>
              Discover your dream{" "}
              {this.state.discoverWord.split("").map((letter, index) => {
                return <span key={index}>{letter}</span>;
              })}
            </div>
            <small>Platform powered by blockchain technology</small>
          </h2>
          <div className="search-form">
            <Filters
              search={search}
              availableCountries={availableCountries}
              availableLocations={availableLocations}
              state={this.state}
              setState={this.setState}
              search_filter={this.search_filter}
              key_figures_filter={this.key_figures_filter}
              handleChange={this.handleChange}
              setFilter={this.setFilter}
              toggleSearchFilter={this.toggleSearchFilter}
              toggleKeyFiguresFilter={this.toggleKeyFiguresFilter}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              marginRight: "10px",
              marginTop: "10px",
            }}
          ></div>
          <div className="hero-search-footer">
            <Link
              to={`/marketplace?${this.getSearchQueryParams()}`}
              state={{
                searchInvestment: this.state.search,
                activePropertyLocations: this.props.activePropertyLocations,
              }}
            >
              <Button variant="contained" className="btn-hero-search">
                Search
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    );
  }
}

export { HeroBanner };
