import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden, Drawer } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import NavBarLinks from "./NavBarLinks";
import { HeaderLinks } from "./HeaderLinks";

import styles from "../Style";
import "../styles.scss";
import HeaderLogo from "./HeaderLogo";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

function NavBarComponent(props) {

  const location = useLocation();
  const isHomePage = ["/", "/home"].includes(location.pathname);

  const classes = useStyles();
  const [isSticky, setIsSticky] = React.useState(false);
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const noStickyClass = "noSticky";
  const handleNavDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const { absolute, pageName } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.absolute]: absolute,
    [classes.fixed]: "true",
  });


  return (
    <div
      className={`app-navbar-container ${classes.root || ''} ${
        isSticky ? "isSticky" : (noStickyClass || '')
      } ${(isHomePage ? 'home-page-nav' : '')}`}
    >
      <AppBar className={appBarClasses}>
        <Toolbar disableGutters className={classes.container}>
          <Hidden mdUp>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleNavDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
          <Hidden smDown implementation="css">
            <HeaderLinks page={pageName} />
          </Hidden>
        </Toolbar>

        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.appDrawerPaper,
            }}
            className="navbarlink-drawer"
          >
            <NavBarLinks page={pageName} />
          </Drawer>
        </Hidden>

        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            open={mobileNavOpen}
            classes={{
              paper: classes.appDrawerPaper,
            }}
            className="navbarlink-drawer"
            onClose={handleNavDrawerToggle}
          >
            {/* <div className={classes.navBarTitle}>
              <HeaderLogo users={props.users} mobile={false} />
            </div> */}

            <NavBarLinks page={pageName} navControl={handleNavDrawerToggle} />
          </Drawer>
        </Hidden>
      </AppBar>
    </div>
  );
}

NavBarComponent.defaultProp = {
  color: "white",
};

NavBarComponent.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
};

function mapState(state) {
  const { users } = state;
  return { users };
}

const NavBar = connect(mapState)(NavBarComponent);

export default NavBar;
export { NavBar as NavBarComponent };
