import React, { useEffect, useState } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import "../styles.scss";

const YearFilter = ({ params }) => {
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");

  useEffect(() => {
    if (params.min_year) {
      setMinYear(params.min_year);
    }
    if (params.max_year) {
      setMaxYear(params.max_year);
    }
  }, [params]);

  const handleApply = () => {
    const currentUrl = window.location.href; // Get the current URL
    const baseUrl = currentUrl.split("?")[0]; // Get the base URL without query parameters
    const queryParams = new URLSearchParams(window.location.search); // Get existing query parameters

    // Remove page parameter
    queryParams.delete("page");

    // Set the new parameters
    queryParams.set("min_year", minYear);
    queryParams.set("max_year", maxYear);

    // Construct the new URL
    const newUrl = `${baseUrl}?${queryParams.toString()}`;

    // Redirect to the new URL
    window.location.href = newUrl; // Redirect to the new URL
  };

  const handleReset = () => {
    setMinYear("");
    setMaxYear("");
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <p>Year Range</p>
      <div className="yacht-filter-form-fields-wrapper">
        <TextField
          className="yacht-filter-fields"
          label="Min Year"
          value={minYear}
          onChange={(e) => setMinYear(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="year-min"
        />
        <TextField
          className="yacht-filter-fields"
          label="Max Year"
          value={maxYear}
          onChange={(e) => setMaxYear(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="year-max"
        />
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete">
          Reset
        </Button>
        <Button onClick={handleApply} className="btn-save">
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default YearFilter;
