import { Box, Link, Typography } from "@material-ui/core";
import { Fragment } from "react";
import style from './style.module.scss';

const WelcomeAgent = () => {

    return (
        <Fragment>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'flex-start', alignItems: 'flex-start'}}>   
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="h4" component="h1">GET STARTED - AGENT</Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="p">Welcome to <b>Immobilium</b>, where your success is our priority! We're excited to partner with you on this journey. <b>Immobilium</b>—where your properties, 
                    our platform, and shared success come together. Let's make every property work for you. Welcome aboard!
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Discover the Power of Sharing with "Our Property is Your Property"</Typography>
                    <Typography variant="p" component="p">At Immobilium, we believe in the power of collaboration. Our unique platform empowers you, the real estate agent, to showcase your expertise 
                        and connect with buyers, even for listings that aren't originally yours. Imagine the possibilities when every property becomes an opportunity for you to thrive.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Earn More with Every Connection</Typography>
                    <Typography variant="p" component="p">Why limit your potential? With Immobilium, you can earn commissions by bringing buyers to any of our listed properties. It's simple: you 
                        find the buyer, we ensure you get rewarded. Expand your portfolio without boundaries and watch your earnings grow.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Tailored Promotional Packages</Typography>
                    <Typography variant="p" component="p">Dive into our promotional packages designed to bring your listings to the forefront. Target the right audience, generate valuable leads, 
                        and close deals faster. Our platform is not just about listings; it's about creating connections that count.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Join the Immobilium Family</Typography>
                    <Typography variant="p" component="p">Become a part of a community that values your growth. Register now and let us show you how Immobilium can revolutionize the way 
                        you do business. Want to learn more? Get in touch with us, and we'll explore together how Immobilium can take your business to new heights.
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="b">Your Next Opportunity Awaits</Typography>
                    <Typography variant="p" component="p">Don't wait. The future of real estate is collaborative, and it starts with you at Immobilium. 
                    </Typography>
                </Box>
                <Box sx={{padding: '20px 0 0 0'}}>
                    <Typography variant="p" component="p">Transform the way you sell real estate forever</Typography>
                </Box>
                <Box m={0} pt={3}>
                <Link href="/register" color="default" className={style.btn}>
                    REGISTER TODAY
                </Link>
                </Box>
            </Box>
        </Fragment>
    );
}

export default WelcomeAgent;