import React from "react";

const LegalSection = ({ property }) => {
  if (property.legal_warnings) {
    return (
      <div className="about-section">
        <div className="about-wrapper">
          <h3>Legal Information</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: property.legal_warnings,
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default LegalSection;
