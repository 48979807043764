// Imports
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { parseISO, addDays, differenceInDays } from "date-fns";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { generalActions, alertActions, userActions } from "../../_actions";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";
import PropertyReview from "../../components/PropertyComponent";
import DasboardNavBarComponent from "../../components/DashboardComponent/common/NavBarComponent";
import {
  generalService,
  blockchainService,
  secureStorage,
} from "../../_services";
import TokenPayModal from "../../components/DashboardComponent/modals/TokenPayModal";
import { arrayMoveImmutable } from "array-move";
import { initialState } from "./components/addPropertyPageParts/initialState";
import { setProperty } from "./components/addPropertyPageParts/utils";
import RenderDetailPart from "./components/addPropertyPageParts/renderDetailPart";
import RenderFinancialPart from "./components/addPropertyPageParts/renderFinancialPart";
import RenderPicturePart from "./components/addPropertyPageParts/renderPicturePart";
import RenderFileUploadPart from "./components/addPropertyPageParts/renderFileUploadPart";
import "./styles.scss";

const AddPropertyPageComponent = (props) => {
  const [state, setState] = useState({ ...initialState });
  const [user, setUser] = useState({
    immoTokenBalance: 0,
    zbsTokenBalance: 0,
  });
  const [disabledButton, setDisabledButton] = useState(false);
  const [uploadModalStatus, setUploadModalStatus] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [uploadImages, setUploadImages] = useState([]);
  const [currentUpload, setCurrentUpload] = useState(0);

  const mapComponentRef = useRef();
  const navigate = useNavigate();
  const params = useParams();

  // Destructure props
  const {
    clearAlerts,
    getAllPartners,
    getPropertyPricing,
    getFinancialInstruments,
    getDividendDistributions,
    getAvailableLocations,
    alert,
    general,
    users,
    addProperty,
    updateProperty,
    hashDocuments,
  } = props;

  // Load initial data
  useEffect(() => {
    const fetchData = async () => {
      clearAlerts();
      getAllPartners();
      getPropertyPricing(state.modalAction);
      getFinancialInstruments();
      getDividendDistributions();
      getAvailableLocations();

      const balanceObj = await blockchainService.getBalances();
      setUser({
        immoTokenBalance: balanceObj.immo,
        zbsTokenBalance: balanceObj.zbs,
      });

      if (params?.id) {
        console.log("edit property");
        loadPropertyById(params.id);
      } else {
        console.log("add property");
        const { property } = state;
        const { measurement } = users.profile_data;
        property.key_figures.forEach((keyFigure) => {
          if (
            keyFigure.property_key_en === "Sq Feet" &&
            measurement === "metric"
          ) {
            keyFigure.property_key_en = "Sq Meters";
          }
        });
        setState((prevState) => ({
          ...prevState,
          property: { ...property },
          loaded: true,
        }));
      }
    };
    fetchData();
  }, []);

  // Load property by ID
  const loadPropertyById = async (id) => {
    try {
      const property = await generalService.getPropertyById(id);
      const tokenData = userActions.parseJwt();
      if (
        window.location.pathname.includes("/edit/") &&
        !secureStorage.getItem("is_admin") &&
        tokenData.data.id !== property._user_id
      ) {
        console.log("prcenzi bato .!.");
        navigate("/marketplace");
      }
      const userMeasurement = users.profile_data.measurement;
      let update = {};

      if (property && state.property.property_id !== property.property_id) {
        const propertyAllSet = setProperty(
          property,
          userMeasurement,
          onUpdatePropertyLocation
        );
        update = {
          property: propertyAllSet,
        };
      } else {
        update.property = state.property;
      }

      setState((prevState) => ({
        ...prevState,
        ...update,
        loaded: true,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  // Update state when props change
  useEffect(() => {
    const {
      all_partners,
      financial_instruments,
      dividend_distributions,
      available_locations,
      propertyPricing,
    } = general;

    let update = {};

    if (all_partners?.status) {
      update.available_partners_list = all_partners.data;
    }

    if (financial_instruments?.status) {
      update.available_financial_instruments = financial_instruments.data;
    }

    if (dividend_distributions?.status) {
      update.available_dividend_distributions = dividend_distributions.data;
    }

    if (
      available_locations &&
      Object.keys(available_locations).length &&
      available_locations !== state.available_locations
    ) {
      update.available_locations = available_locations;
    }

    if (
      propertyPricing &&
      propertyPricing.pricing?.amount !== state.propertyPricing.amount
    ) {
      update.propertyPricing = { ...propertyPricing.pricing };
    }

    if (Object.keys(update).length > 0) {
      setState((prevState) => ({
        ...prevState,
        ...update,
      }));
    }
  }, [general]);

  // Handlers
  const handleEditorValidation = () => {
    const { property } = state;
    const isAllSectionsFilled = property.sections.every(
      (section) => section.section_description_en !== ""
    );
    if (isAllSectionsFilled) {
      return true;
    } else {
      property.sections.forEach((section) => {
        const errorName = `${section.section_key}_error`;
        const errorMsg = `${section.section_heading_en} description is required field`;
        setState((prevState) => ({
          ...prevState,
          [errorName]: section.section_description_en === "" ? errorMsg : "",
        }));
      });
      return false;
    }
  };

  const handleNext = () => {
    formRef.isFormValid(false).then((isValid) => {
      const { activeStep } = state;
      const validAllEditorsVal =
        activeStep === 0 ? handleEditorValidation() : true;
      if (isValid && validAllEditorsVal) {
        setState((prevState) => ({
          ...prevState,
          activeStep: prevState.activeStep + 1,
          the_highlights_error: "",
          the_property_error: "",
          the_investment_error: "",
        }));
      }
    });
  };

  const handleBack = () => {
    const { activeStep } = state;
    const update = {
      activeStep: activeStep - 1,
    };
    if (activeStep === 2) {
      update.property = {
        ...state.property,
        start_date: new Date(),
        end_date: new Date(),
      };
    }
    setState((prevState) => ({
      ...prevState,
      ...update,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        [name]: value,
      },
    }));
  };

  const handleChangePropertyType = (event) => {
    const fieldValue = event.target.value;

    if (fieldValue === "direct") {
      setState((prevState) => {
        const sections = [...prevState.property.sections];
        sections.splice(2, 1);
        return {
          ...prevState,
          property: {
            ...prevState.property,
            property_type: fieldValue,
            sections,
          },
        };
      });
    } else {
      if (state.property.sections.length < 3) {
        handleAddSectionsRow("The Investment", "the_investment");
        setState((prevState) => ({
          ...prevState,
          property: {
            ...prevState.property,
            property_type: fieldValue,
          },
        }));
      }
    }
  };

  const onUpdateProperty = useCallback(
    (update) => {
      setState((prevState) => ({
        ...prevState,
        property: {
          ...prevState.property,
          ...update,
        },
      }));
    },
    [setState]
  );

  const onUpdatePropertyLocation = useCallback(
    (location_cords) => {
      mapComponentRef.current.setLocationMarkerOnChangePlaceFromParent(
        location_cords.position
      );
    },
    [mapComponentRef]
  );

  const handleAddKeyFigureRow = () => {
    const property = {
      property_key_en: "",
      property_value_en: "",
      tab_value_key: 0,
      tab_value_val: 0,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        key_figures: [...prevState.property.key_figures, property],
      },
    }));
  };

  const handleRemoveKeyFigureRow = (index) => () => {
    const key_figures = [...state.property.key_figures];
    key_figures[index] = {
      ...key_figures[index],
      isDelete: 1,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        key_figures,
      },
    }));
  };

  const handleKeyFigureChange = (index) => (event) => {
    const { name, value } = event.target;
    const key_figures = [...state.property.key_figures];
    key_figures[index] = {
      ...key_figures[index],
      [name]: value,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        key_figures,
      },
    }));
  };

  const handleAddSectionsRow = (heading = "", key = "") => {
    const section = {
      section_heading_en: heading,
      section_description_en: "",
      heading_tab_value: 0,
      description_tab_value: 0,
      section_key: key,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        sections: [...prevState.property.sections, section],
      },
    }));
  };

  const handleRemoveSectionsRow = (index) => () => {
    const sections = [...state.property.sections];
    sections.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        sections,
      },
    }));
  };

  const handleSectionsChange = (event) => {
    const { name, value, index } = event.target;
    const sections = [...state.property.sections];
    sections[index] = { ...sections[index], [name]: value };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        sections,
      },
    }));
  };

  const handleEditorChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        [name]: value,
      },
    }));
  };

  const handleAddPartnerRow = () => {
    const partner = {
      partner_id: 0,
      involvement: "",
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        partners: [...prevState.property.partners, partner],
      },
    }));
  };

  const handleRemovePartnerRow = (index) => () => {
    const partners = [...state.property.partners];
    partners[index] = {
      ...partners[index],
      isDelete: 1,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        partners,
      },
    }));
  };

  const handlePartnerChange = (index) => (event) => {
    const { name, value } = event.target;
    const partners = [...state.property.partners];
    partners[index] = {
      ...partners[index],
      [name]: value,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        partners,
      },
    }));
  };

  const handleAddPropertyLocation = (location_props) => {
    const { position, address, places } = location_props;
    let address_postcode;
    let address_city;
    let address_country;
    const addressComponents = places && places[0]?.address_components;
    if (addressComponents) {
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          address_city = component.long_name;
        } else if (component.types.includes("postal_code")) {
          address_postcode = component.long_name;
        } else if (component.types.includes("country")) {
          address_country = component.long_name;
        }
      });
    }
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        address_line1: address,
        property_location: position,
        address_postcode,
        address_city,
        address_country,
      },
    }));
  };

  const handlePropertyPictureTabValue = (index) => (event, newValue) => {
    const property_picture = [...state.property.property_picture];
    property_picture[index] = {
      ...property_picture[index],
      tab_value: newValue,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_picture,
      },
    }));
  };

  const handleAddPropertyPicture = (preview, file) => {
    const pictureObj = {
      property_picture_preview: preview,
      property_images: file,
      image_description_en: "",
      tab_value: 0,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_picture: [...prevState.property.property_picture, pictureObj],
      },
    }));
  };

  const handleRemovePropertyPicture = (picture) => () => {
    const property_picture = [...state.property.property_picture];
    const index = property_picture.findIndex((p) => p === picture);
    if (property_picture[index].id) {
      property_picture[index] = {
        ...property_picture[index],
        is_delete: 1,
      };
    } else {
      property_picture.splice(index, 1);
    }
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_picture,
      },
    }));
  };

  const handlePropertyPictureDescChange = (index) => (event) => {
    const { name, value } = event.target;
    const property_picture = [...state.property.property_picture];
    property_picture[index] = {
      ...property_picture[index],
      [name]: value,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_picture,
      },
    }));
  };

  const handlePropertyPictureCapture = (e) => {
    e.preventDefault();

    const files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        handleAddPropertyPicture(reader.result, file);
      };
      reader.readAsDataURL(file);
    });
  };

  const handlePropertyDocTabValue = (index) => (event, newValue) => {
    const property_documents = [...state.property.property_documents];
    property_documents[index] = {
      ...property_documents[index],
      tab_value: newValue,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_documents,
      },
    }));
  };

  const handlePropertyDocCapture = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      handleAddPropertyDoc(file);
    };
    reader.readAsDataURL(file);
  };

  const handleAddPropertyDoc = (file) => {
    const fileObj = {
      property_doc_file: file,
      property_doc_filename: file.name.toLowerCase(),
      file_description_en: "",
      tab_value: 0,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_documents: [...prevState.property.property_documents, fileObj],
      },
    }));
  };

  const handlePropertyDocDescChange = (index) => (event) => {
    const { name, value } = event.target;
    const property_documents = [...state.property.property_documents];
    property_documents[index] = {
      ...property_documents[index],
      [name]: value,
    };
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_documents,
      },
    }));
  };

  const handleRemovePropertyDoc = (doc) => () => {
    const property_documents = [...state.property.property_documents];
    const index = property_documents.findIndex((d) => d === doc);
    if (property_documents[index].id) {
      property_documents[index] = {
        ...property_documents[index],
        is_delete: 1,
      };
    } else {
      property_documents.splice(index, 1);
    }
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_documents,
      },
    }));
  };

  const handleFloorPlanImageCapture = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setState((prevState) => ({
          ...prevState,
          property: {
            ...prevState.property,
            property_floor_plans: [
              ...prevState.property.property_floor_plans,
              {
                floor_plan_image: file,
                floor_plan_image_preview: reader.result,
              },
            ],
          },
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveFloorPlanPicture = (floorPlan) => () => {
    const floor_plan_pictures = [...state.property.property_floor_plans];
    const index = floor_plan_pictures.findIndex((fp) => fp === floorPlan);
    if (floor_plan_pictures[index].id) {
      floor_plan_pictures[index] = {
        ...floor_plan_pictures[index],
        is_delete: 1,
      };
    } else {
      floor_plan_pictures.splice(index, 1);
    }
    setState((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        property_floor_plans: floor_plan_pictures,
      },
    }));
  };

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      activeStep: 0,
    }));
  };

  const closeUploadModal = () => {
    setUploadModalStatus(false);
    setDisabledButton(false);
  };

  const handlePropertyUpload = async () => {
    setUploadModalStatus(false);
    const res = await blockchainService.spendTokens(
      state.propertyPricing.amount
    );
    if (res) {
      setState((prevState) => ({
        ...prevState,
        property: {
          ...prevState.property,
          upload_transaction_id: res.id,
          is_draft: false,
        },
      }));
      formRef.submit();
    }
  };

  const submit = () => {
    if (state.property.property_id && !state.property.is_draft) {
      // update property part
      formRef.submit();
    } else {
      setDisabledButton(true);
      setUploadModalStatus(true);
    }
  };

  const handleSubmit = () => {
    const property = { ...state.property };
    delete property.macro_location_image_preview;
    delete property.micro_location_image_preview;

    const index = property.key_figures.findIndex((keyFigure) =>
      ["Sq Feet", "Sq Meters"].includes(keyFigure.property_key_en)
    );
    let val = property.key_figures[index].property_value_en;
    // Convert to Sq Feet if measurement is metric
    if (users.profile_data.measurement === "metric") {
      val = `${Math.round(parseInt(val) / 0.092903)}`;
      property.key_figures[index].property_key_en = "Sq Feet";
    }
    property.key_figures[index].property_value_en = val;

    if (state.property.property_id) {
      // Update property
      delete property.upload_transaction_id;
      updateProperty(property).then((res) => {
        setTimeout(() => {
          clearAlerts();
          navigate("/dashboard/properties");
        }, 2000);
        // Hash uploaded documents
        const db_property = res.property;
        hashDocuments(db_property.property_id, db_property.property_documents);
      });
    } else {
      // Add new property
      addProperty(property)
        .then((db_property) => {
          setTimeout(() => {
            clearAlerts();
            setDisabledButton(false);
            navigate("/dashboard/properties");
          }, 2000);
          // Hash uploaded property documents
          hashDocuments(
            db_property.property_id,
            db_property.property_documents
          );
        })
        .catch((error) => {
          setTimeout(() => navigate("/dashboard/properties"), 2000);
        });
    }
  };

  const handleSaveDraft = () => {
    formRef.isFormValid(false).then((isValid) => {
      if (isValid) {
        setDisabledButton(true);
        // save property and redirect to edit page
        const property = { ...state.property };
        delete property.macro_location_image_preview;
        delete property.micro_location_image_preview;

        const index = property.key_figures.findIndex((keyFigure) =>
          ["Sq Feet", "Sq Meters"].includes(keyFigure.property_key_en)
        );
        let val = property.key_figures[index].property_value_en;
        // Convert to Sq Feet if measurement is metric
        if (users.profile_data.measurement === "metric") {
          val = `${Math.round(parseInt(val) / 0.092903)}`;
          property.key_figures[index].property_key_en = "Sq Feet";
        }
        property.key_figures[index].property_value_en = val;
        if (state.property.property_id) {
          // Update property
          delete property.upload_transaction_id;
          updateProperty(property).then((res) => {
            setTimeout(() => {
              clearAlerts();
              setDisabledButton(false);
            }, 2000);
            // Hash uploaded documents
            const db_property = res.property;
            hashDocuments(
              db_property.property_id,
              db_property.property_documents
            );
          });
        } else {
          addProperty(property)
            .then((db_property) => {
              setTimeout(() => {
                clearAlerts();
                setDisabledButton(false);
                window.location = `/dashboard/properties/edit/${db_property.property_id}`;
              }, 2000);
              // Hash uploaded property documents
              hashDocuments(
                db_property.property_id,
                db_property.property_documents
              );
            })
            .catch((error) => {
              setTimeout(() => navigate("/dashboard/properties"), 2000);
            });
        }
      } else {
        console.log("Form not valid");
      }
    });
  };

  const updateSortImages = async (images) => {
    const data = images.map((image, index) => {
      return {
        id: image.id,
        index: index,
      };
    });

    try {
      const res = await generalService.updatePropertySortPictures(data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    try {
      const array = state.property.property_picture || [];
      let newItems = arrayMoveImmutable(array, oldIndex, newIndex);
      newItems = newItems.map((item, index) => {
        item.index = index;
        return item;
      });
      setState((prevState) => ({
        ...prevState,
        property: {
          ...prevState.property,
          property_picture: newItems,
        },
      }));

      updateSortImages(newItems);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateRemainingPromotionDays = (startDate, durationDays = 30) => {
    const start = parseISO(startDate);
    const end = addDays(start, durationDays);
    const now = new Date();
    const remainingDays = differenceInDays(end, now);

    return remainingDays > 0 ? remainingDays : 0;
  };

  const renderReviewPart = () => (
    <div className="step-wizard-block block-6" id="step_6">
      <PropertyReview property={state.property} />
    </div>
  );

  const getSteps = () => {
    const map = {
      direct: [
        {
          name: "Property Details",
          render: () =>
            RenderDetailPart(
              state,
              handleChangePropertyType,
              onUpdateProperty,
              onUpdatePropertyLocation,
              mapComponentRef,
              handleAddPropertyLocation,
              handleAddKeyFigureRow,
              handleKeyFigureChange,
              handleRemoveKeyFigureRow,
              handleSectionsChange,
              handleAddPartnerRow,
              handlePartnerChange,
              handleRemovePartnerRow,
              handleEditorChange
            ),
        },
        {
          name: "Picture Upload",
          render: () =>
            RenderPicturePart(
              state,
              handleFloorPlanImageCapture,
              handleRemoveFloorPlanPicture,
              handlePropertyPictureCapture,
              onSortEnd,
              handlePropertyPictureTabValue,
              handlePropertyPictureDescChange,
              handleRemovePropertyPicture
            ),
        },
        {
          name: "Document Upload",
          render: () =>
            RenderFileUploadPart(
              state,
              handlePropertyDocCapture,
              handlePropertyDocTabValue,
              handlePropertyDocDescChange,
              handleRemovePropertyDoc
            ),
        },
        { name: "Review Page", render: renderReviewPart },
      ],
      default: [
        {
          name: "Property Details",
          render: () =>
            RenderDetailPart(
              state,
              handleChangePropertyType,
              onUpdateProperty,
              onUpdatePropertyLocation,
              mapComponentRef,
              handleAddPropertyLocation,
              handleAddKeyFigureRow,
              handleKeyFigureChange,
              handleRemoveKeyFigureRow,
              handleSectionsChange,
              handleAddPartnerRow,
              handlePartnerChange,
              handleRemovePartnerRow,
              handleEditorChange
            ),
        },
        {
          name: "Financial Details",
          render: () =>
            RenderFinancialPart(
              state,
              handleChange,
              handlePredictedValueTabChange
            ),
        },
        {
          name: "Picture Upload",
          render: () =>
            RenderPicturePart(
              state,
              handleFloorPlanImageCapture,
              handleRemoveFloorPlanPicture,
              handlePropertyPictureCapture,
              onSortEnd,
              handlePropertyPictureTabValue,
              handlePropertyPictureDescChange,
              handleRemovePropertyPicture
            ),
        },
        {
          name: "File Upload",
          render: () =>
            RenderFileUploadPart(
              state,
              handlePropertyDocCapture,
              handlePropertyDocTabValue,
              handlePropertyDocDescChange,
              handleRemovePropertyDoc
            ),
        },
        { name: "Review Page", render: renderReviewPart },
      ],
    };
    const steps = map[state.property.property_type] || map.default;
    return steps;
  };

  const handlePredictedValueTabChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      predicted_value_increase_tabIndex: newValue,
    }));
  };

  const steps = getSteps();
  const { activeStep } = state;

  return (
    <div className="main-content add-property-page">
      <DasboardNavBarComponent pageName="manage-property" />
      <Card>
        <CardContent>
          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
            />
          )}
          <Stepper activeStep={activeStep}>
            {steps.map(({ name }) => (
              <Step key={name}>
                <StepLabel>{name}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <ValidatorForm
            className="default-form property-form add-property-form"
            onSubmit={handleSubmit}
            ref={(r) => setFormRef(r)}
            autoComplete="off"
            instantValidate
          >
            {steps[activeStep] && steps[activeStep].render()}
            <Container>
              {disabledButton && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Uploading property <strong>{state.property.title}</strong>
                  </Typography>
                  <LinearProgress />
                  <br />
                  <br />
                </>
              )}
            </Container>
            <div className="action-buttons">
              <Button
                disabled={activeStep === 0 || disabledButton}
                onClick={handleBack}
              >
                Back
              </Button>
              {state.property.is_draft && (
                <Button
                  variant="contained"
                  className="draft-button"
                  onClick={handleSaveDraft}
                  disabled={disabledButton}
                >
                  Save Draft
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={
                  disabledButton ||
                  !state.property.start_date ||
                  !state.property.end_date
                }
                onClick={activeStep < steps.length - 1 ? handleNext : submit}
              >
                {activeStep === steps.length - 1
                  ? "Accept and Start Campaign"
                  : "Next"}
              </Button>
            </div>
          </ValidatorForm>
        </CardContent>
      </Card>
      <TokenPayModal
        handleClose={closeUploadModal}
        open={uploadModalStatus}
        action={state.modalAction}
        propertyTitle={state.property.title}
        content={`You are about to ${state.modalAction} this property`}
        immoTokenBalance={user.immoTokenBalance}
        zbsTokenBalance={user.zbsTokenBalance}
        payAmount={state.propertyPricing.amount}
        isOpen={uploadModalStatus}
        handleFinish={handlePropertyUpload}
      />
      {uploadImages.length > 0 && (
        <div className="upload-mask">
          <div className="upload-image-container">
            <h3>Upload in progress...</h3>
            <CircularProgress color="secondary" size={24} />
            <div className="upload-images">
              {uploadImages.map((image, index) => {
                let innerClass = "upload-item next-upload";
                if (index === currentUpload) {
                  innerClass = "upload-item current-upload";
                }
                if (index < currentUpload) {
                  innerClass = "upload-item prev-upload";
                }
                return (
                  <img
                    src={image.raw}
                    alt="upload"
                    className={innerClass}
                    key={index}
                  />
                );
              })}
            </div>
            <div>
              <small>
                {currentUpload} / {uploadImages.length}
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Map state and actions
const mapStateToProps = (state) => ({
  alert: state.alert,
  general: state.general,
  users: state.users,
});

const mapDispatchToProps = {
  clearAlerts: alertActions.clear,
  addProperty: generalActions.addProperty,
  updateProperty: generalActions.updateProperty,
  getAllPartners: generalActions.getAllPartners,
  getPropertyPricing: generalActions.getPropertyPricing,
  getFinancialInstruments: generalActions.getFinancialInstruments,
  getDividendDistributions: generalActions.getDividendDistributions,
  getAvailableLocations: generalActions.getAvailableLocations,
  getPropertyById: generalActions.getPropertyById,
  hashDocuments: generalActions.hashDocuments,
};

export const AddPropertyPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPropertyPageComponent);
