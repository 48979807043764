import './style.country.widget.scss';

const CountryWidget = (props) => {

    if(!props.locations || !Object.keys(props.locations).length) {
        return null;
    }

    let countries = Object.keys(props.locations);

    return (
        <div className="MuiContainer-root MuiContainer-maxWidthLg country-widget">
            <h3>Homes for sale from around the globe</h3>
            <p>Dive into our comprehensive database of luxury properties and be one step closer to your dream home.</p>
            <div className="country-widget__list">
                {countries.map((country, index) => {
                    const name = country.split(' ').join('+');
                    return (
                        <a key={index} className="country-widget__item" href={`/marketplace?countries=%5B"${name}"%5D`}>
                            <h4>{country}</h4>
                        </a>
                    );
                })}
            </div>
        </div>
    );
}

export default CountryWidget;
