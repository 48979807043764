import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Box } from "@material-ui/core";
import { generalActions } from "../../_actions";
import { HeroBanner } from "./HeroBanner";
import { PropertiesMarketplace } from "./PropertiesMarketplace";
import "./styles.scss";
import CounterUp from "./CounterUp";
import CountryWidget from "../../components/CountryWidget/country-widget";
import WelcomeTypesPopup from "../../components/WelcomeTypesPopup";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialDataLimit: 10,
      initialPage: 0,
      isPromoted: true,
    };
  }

  componentDidMount() {
    this.props.getProperties(
      this.state.initialDataLimit,
      this.state.initialPage,
      this.state.isPromoted
    );
    this.props.getAllLocations();
    this.props.getAllPropertyActiveLocations();
  }

  componentWillUnmount() {
    this.props.resetPropertyActiveLocations();
  }

  render() {
    const pmProperties = this.props.properties;
    const locations = this.props.locations;
    const activePropertyLocations = this.props.activePropertyLocations;
    return (
      <>
        <HeroBanner
          properties={pmProperties}
          locations={locations}
          background={this.props.background}
          activePropertyLocations={activePropertyLocations}
        />
        <div className="homepage-blue-gradient-header-section">
          <Grid container className="counter-container" alignItems="center">
            <Grid item xs={6} md={3}>
              <CounterUp start={1} end={47} />
              <Box className="counter-text">Countries</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <CounterUp start={1} end={71} />
              <Box className="counter-text">Agencies</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <CounterUp start={1} end={5012} />
              <Box className="counter-text">Agents</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <CounterUp start={1} end={pmProperties.total_marketplace_props} />
              <Box className="counter-text">Properties</Box>
            </Grid>
          </Grid>
        </div>
        <div className="homepage-video-section">
          <div className="video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/W9i3iquJzkY?si=Gmkei8289L_S8agI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="homepage-blue-header-section">
          <Container>
            <h2 className="h2 block-title">
              Explore all Properties on the marketplace
            </h2>
          </Container>
        </div>
        <WelcomeTypesPopup />
        <PropertiesMarketplace
          properties={pmProperties}
          count={pmProperties.total_marketplace_props || 0}
        />

        <CountryWidget locations={activePropertyLocations} />
      </>
    );
  }
}

function mapState(state) {
  const { general } = state;
  const { properties, locations, activePropertyLocations } = general;
  return { properties, locations, activePropertyLocations };
}

const actionCreators = {
  getProperties: generalActions.getProperties,
  getAllLocations: generalActions.getAllLocations,
  getAllPropertyActiveLocations: generalActions.getAllPropertyActiveLocations,
  resetPropertyActiveLocations: generalActions.resetPropertyActiveLocations,
};

export default connect(mapState, actionCreators)(HomePage);
