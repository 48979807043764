import { Fragment, memo, useEffect, useState } from "react";
import { useEditorLoader } from "./editorLoader";

const Editor = ({
  name,
  value,
  onChange,
  toolbar,
  plugins,
  height,
  label,
  id,
  index,
}) => {
  const _height = height || 500;

  const debug_load_tinymce = false;

  const [isLoaded, setIsLoaded] = useState(false);

  const inlineChange = (e) => {
    onChange({
      target: {
        id: id,
        name: name,
        value: e.target.value,
        type: "editor",
        index: index,
      },
    });
  };

  const initialize = () => {
    // destroy previous editor
    try {
      window.tinymce.get(id).remove();
    } catch (e) {
      if (debug_load_tinymce) console.log("No previous editor found");
    }

    if (debug_load_tinymce) console.log("initialize editor", name, label);

    window.tinymce.init({
      selector: `#${id}`,
      height: _height,
      plugins: plugins,
      toolbar: toolbar,
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      menubar: false,
      contextmenu: false,
      preview_styles: false,
      paste_as_text: true,
      branding: false,
      setup: (editor) => {
        editor.on("init", () => {
          editor.setContent(value);
        });
        editor.on("change", () => {
          try {
            const content = editor.getContent();
            onChange({
              target: {
                id: id,
                name: name,
                value: content,
                type: "editor",
                instance: editor,
                index: index,
                getContent: () => editor.getContent(),
              },
            });
          } catch (e) {
            console.log("Error on change", e);
          }
        });
      },
    });
  };

  const { loadTinyMCE } = useEditorLoader({ onLoad: initialize });

  useEffect(() => {
    loadTinyMCE();
  }, []);

  useEffect(() => {
    if (window.tinymce !== undefined && value && !isLoaded) {
      setIsLoaded(true);
      initialize();
    }
  }, [value]);

  return (
    <Fragment>
      {label && (
        <label
          htmlFor={name}
          style={{ margin: "15px 0 10px 0", display: "block", fontSize: 13 }}
        >
          {label}
        </label>
      )}
      <textarea
        id={id || name}
        name={name}
        className="simple-editor"
        value={value}
        onChange={inlineChange}
      />
    </Fragment>
  );
};

export default memo(Editor);
