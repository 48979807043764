import { secureStorage } from "../_services";

export function authHeader() {
  // return authorization header with jwt token
  const token = JSON.parse(
    secureStorage.getItem(process.env.REACT_APP_LOGIN_KEY)
  );
  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}
