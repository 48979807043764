import React from 'react';
import './styles.scss';
import SearchField from './SearchField';

const SearchSection = () => {
  return (
    <div className="search-hero-section">
      <div className="search-form">
        <SearchField />
      </div>
    </div>
  );
};

export default SearchSection;
