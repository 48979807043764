import React, { Component } from "react";
import {
  DialogContent,
  Box,
  Button,
  Avatar,
  Typography,
  Dialog,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import "./styles.scss";

class TransferPropertyModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Dialog
        className="transfer-property-modal wider-modal"
        fullWidth={true}
        maxWidth={"xs"}
        onClose={this.props.handleClose}
        open={this.props.open}
      >
        <DialogContent>
          <div className="block-header">Transfer Property Ownership</div>
          <div className="payment-icon">
            <HomeWorkIcon />
          </div>

          <div className="block-content">
            <p className="txt-type">Payment Reference Number</p>
            <p className="txt-status">{this.props.paymentRefNum}</p>
          </div>

          <div className="block-content">
            <p className="text">
              You are about to transfer ownership of this property from user{" "}
              {this.props.owner.first_name} {this.props.owner.last_name} to user{" "}
              {this.props.buyer.first_name} {this.props.buyer.last_name}. If you
              wish to cancel this request press "Reject" button.
            </p>
          </div>

          <Box
            sx={{
              backgroundColor: "",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "stretch",
              maxWidth: 550,
              mx: "auto",
              mb: 2,
            }}
          >
            <Box
              sx={{
                p: 1,
                pb: 3,
                pt: 2,
                backgroundColor: "",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 200,
                border: "2px solid red",
                borderRadius: "7px",
              }}
            >
              <Avatar
                alt={`${this.props.owner.first_name} ${this.props.owner.last_name}`}
                src={this.props.owner.profile_image}
              />
              <br />
              <Typography variant="button">From:</Typography>
              <Typography variant="body1">
                {this.props.owner.first_name} {this.props.owner.last_name}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowForwardIosIcon />
            </Box>

            <Box
              sx={{
                p: 1,
                pb: 3,
                pt: 2,
                backgroundColor: "",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 200,
                border: "2px solid green",
                borderRadius: "7px",
              }}
            >
              <Avatar
                alt={`${this.props.buyer.first_name} ${this.props.buyer.last_name}`}
                src={this.props.buyer.profile_image}
              />
              <br />
              <Typography variant="button">To:</Typography>
              <Typography variant="body1">
                {this.props.buyer.first_name} {this.props.buyer.last_name}
              </Typography>
            </Box>
          </Box>

          <Box
            my={1}
            mr={3}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <Button className="btn-print" onClick={this.props.handleClose}>
                Close
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                className="btn-print"
                onClick={this.props.handleReject}
                disabled={!this.props.isOpen}
              >
                Reject
              </Button>
              <Button
                className="btn-print"
                onClick={this.props.handleFinish}
                disabled={!this.props.isOpen}
              >
                Accept transfer
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default TransferPropertyModal;
