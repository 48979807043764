import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Button,
  Box,
  TablePagination,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { locationActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import { locationService } from "../../_services";
import "./styles.scss";

class ManageLocationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      page: {
        size: 10,
        page: 0,
        count: 0,
      },
    };
  }

  componentDidMount() {
    this.loadLocations();
  }

  loadLocations() {
    this.props.clearAlerts();
    locationService
      .getLocations(this.state.page.page, this.state.page.size)
      .then((response) => {
        if (response.status) {
          this.setState({
            ...this.state,
            locations: response.data,
            page: {
              ...this.state.page,
              count: response.pagination.count,
            },
          });
        } else {
          this.props.alertError(response.message.toString());
        }
      });
  }

  handlePageChange = (page) => {
    this.setState(
      {
        ...this.state,
        page,
      },
      () => this.loadLocations()
    );
  };

  handleLocationRemove = (locationId) => {
    this.props.deleteLocationById(locationId).then((value) => {
      this.loadLocations();
      setTimeout(() => this.props.clearAlerts(), 1000);
    });
  };

  render() {
    const { alert } = this.props;
    const { locations, page } = this.state;

    return (
      <div className="main-content manage-location-page">
        <DasboardNavBarComponent pageName="manage-location" />
        <Box mb={3}>
          <Link to="/dashboard/locations/add">
            <Button variant="contained" color="primary">
              Add Location
            </Button>
          </Link>
        </Box>

        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}

        <Card>
          <CardContent className="table-scroll">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Country</th>
                  <th>Heading</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {locations.length > 0 && (
                  <>
                    {locations.map((location, index) => {
                      return (
                        <tr key={index}>
                          <td align="center">
                            {page.page * page.size + index + 1}
                          </td>
                          <td align="center">{location.city_name}</td>
                          <td align="center">{location.country}</td>
                          <td align="center">{location.location_heading}</td>
                          <td align="center">
                            {ReactHtmlParser(location.location_description)}
                          </td>
                          <td align="center">
                            <Link
                              to={"/dashboard/locations/edit/" + location.id}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                style={{ marginRight: 5 }}
                              >
                                Edit
                              </Button>
                            </Link>
                            <Button
                              variant="outlined"
                              size="small"
                              color="secondary"
                              onClick={() =>
                                this.handleLocationRemove(location.id)
                              }
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                <tr>
                  <TablePagination
                    rowsPerPageOptions={[10, 50]}
                    labelRowsPerPage="Items per page:"
                    page={page.page}
                    count={page.count}
                    rowsPerPage={page.size}
                    onRowsPerPageChange={(e) => {
                      this.handlePageChange({
                        ...page,
                        page: 0,
                        size: e.target.value,
                      });
                    }}
                    onPageChange={(e, idx) => {
                      this.handlePageChange({ ...page, page: idx });
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapState(state) {
  const { alert, location } = state;
  return { alert, location };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  alertError: alertActions.error,
  deleteLocationById: locationActions.deleteLocationById,
};

const connectedPage = connect(mapState, actionCreators)(ManageLocationPage);
export { connectedPage as ManageLocationPage };
