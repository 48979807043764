import React, { useEffect, useState } from "react";
import { yachtTypes } from "../yacht-types";
import { FormControl, Select, MenuItem, Button } from "@material-ui/core";

const BoatTypeFilter = ({ params }) => {
  const [selectedYachtType, setSelectedYachtType] = useState("");

  useEffect(() => {
    if (params.types) {
      setSelectedYachtType(params.types.replace(/\*/g, ""));
    }
  }, [params]);

  const handleChange = (event) => {
    setSelectedYachtType(event.target.value);
  };

  const handleReset = () => {
    setSelectedYachtType("");
  };

  const handleApply = () => {
    const currentUrl = window.location.href; // Get the current URL
    const baseUrl = currentUrl.split("?")[0]; // Get the base URL without query parameters
    const queryParams = new URLSearchParams(window.location.search); // Get existing query parameters

    // Remove page parameter
    queryParams.delete("page");

    // Set the new parameters
    queryParams.set("types", `${selectedYachtType}`);

    // Construct the new URL
    const newUrl = `${baseUrl}?${queryParams.toString()}`;

    // Redirect to the new URL
    window.location.href = newUrl; // Redirect to the new URL
  };

  const capitalizeType = (type) =>
    type.replace(/-/g, " ").charAt(0).toUpperCase() +
    type.replace(/-/g, " ").slice(1);

  return (
    <FormControl className="yacht-form-control-wrapper">
      <p>Boat Type</p>
      <div className="yacht-filter-form-fields-wrapper">
        <Select
          className="yacht-filter-select-field"
          id="boat-type-select"
          value={selectedYachtType}
          onChange={handleChange}
          size="small"
          variant="outlined"
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Boat Type
          </MenuItem>
          {yachtTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {capitalizeType(type)}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button className="btn-delete" onClick={handleReset}>
          Reset
        </Button>
        <Button className="btn-save" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default BoatTypeFilter;
