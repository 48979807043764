import React from "react";
import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/plugins/captions.css";
// import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Button, SvgIcon, useMediaQuery } from "@material-ui/core";
import { ReactComponent as MySvgIcon } from "../../assets/images/all-photos.svg";
import "yet-another-react-lightbox/styles.css";
import "./styles.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

const responsive = {
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const ShowAllBtn = ({ property_images }) => {
  const [openSlidesGallery, setOpenSlidesGallery] = React.useState(false);
  const slides =
    property_images.length > 0
      ? property_images.map((property_image) => {
          return {
            src: property_image.image_path,
          };
        })
      : [];

  return (
    <>
      <Button
        onClick={() => setOpenSlidesGallery(true)}
        style={{
          textTransform: "none",
          position: "absolute",
          bottom: "8px",
          right: "60px",
        }}
        variant="outlined"
        color="default"
        startIcon={<SvgIcon component={MySvgIcon} viewBox="0 0 20 20" />}
      >
        Show all photos
      </Button>
      <Lightbox
        open={openSlidesGallery}
        close={() => setOpenSlidesGallery(false)}
        slides={slides}
      />
    </>
  );
};

const HeroContainerSection = ({ property }) => {
  const navigate = useNavigate();
  const { property_images, title } = property;
  const isDesktop = useMediaQuery("(min-width:900px)");

  const getImage = (index) => {
    return property_images[index]
      ? property_images[index].image_path
      : "/cover-image.webp";
  };

  const goToCountry = () => {
    const countryParam = encodeURIComponent(
      JSON.stringify([property.address_country])
    );
    navigate(`/marketplace?countries=${countryParam}`);
  };

  const goToLocation = () => {
    // Construct the location string with city and country in parentheses
    const locationString = `${property.address_city} (${property.address_country})`;

    // Encode the location string
    let locationParam = encodeURIComponent(JSON.stringify([locationString]));

    // Replace '%20' with '+' if required
    locationParam = locationParam.replace(/%20/g, "+");

    const countryParam = encodeURIComponent(
      JSON.stringify([property.address_country])
    );

    navigate(
      `/marketplace?location=${locationParam}&countries=${countryParam}`
    );
  };

  return (
    <section className="property-details-hero-section">
      <div className="property-details-hero-container">
        <div className="property-details-hero-title">
          <h1>{title}</h1>
          <p className="property-details-hero-subtitle">
            <span onClick={goToCountry}>{property.address_country}</span> /{" "}
            <span onClick={goToLocation}>{property.address_city}</span>
          </p>
        </div>
        <div className="property-details-hero-images-wrapper">
          <div className="main-image-wrapper">
            <div
              className="main-image"
              style={{ backgroundImage: `url('${getImage(0)}')` }}
            />
          </div>
          <div className="grid-images-wrapper">
            <div
              className="grid-image"
              style={{ backgroundImage: `url('${getImage(1)}')` }}
            />
            <div
              className="grid-image"
              style={{ backgroundImage: `url('${getImage(2)}')` }}
            />
            <div
              className="grid-image"
              style={{ backgroundImage: `url('${getImage(3)}')` }}
            />
            <div
              className="grid-image"
              style={{ backgroundImage: `url('${getImage(4)}')` }}
            />
            <ShowAllBtn property_images={property_images} />
          </div>
        </div>
        {!isDesktop ? (
          <Carousel
            responsive={{
              mobile: {
                breakpoint: {
                  max: 900,
                  min: 0,
                },
                items: 1,
              },
            }}
          >
            {property_images.map((image, index) => (
              <div
                key={index}
                className="property-details-hero-images-carousel"
              >
                <div
                  style={{
                    backgroundImage: `url('${getImage(index)}')`,
                  }}
                />
              </div>
            ))}
          </Carousel>
        ) : null}
      </div>
    </section>
  );
};

export default HeroContainerSection;
