import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import ReactLoading from "react-loading";

import { store } from "./_helpers";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import { ConfirmContextProvider, ConfirmModal } from "./components/Confirm";

import "./assets/css/theme.scss";
import "./assets/css/custom.scss";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans' !important",
  },
  shape: {
    borderRadius: 8,
  },
});

render(
  <Suspense
    fallback={
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type={"bubbles"} color="#4abff7" />
      </div>
    }
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConfirmContextProvider>
          <App />
          <ConfirmModal />
        </ConfirmContextProvider>
      </ThemeProvider>
    </Provider>
  </Suspense>,

  document.getElementById("root")
);
registerServiceWorker();
