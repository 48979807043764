import React, { Component } from "react";
import { connect } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Typography, Button } from "@material-ui/core";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { userActions, alertActions } from "../../_actions";
import "./styles.scss";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email_id: "",
        reset_token: "",
        new_password: "",
        confirm_password: "",
      },
      reset_pass_res: {},
    };
    this.props.clearAlerts();
  }

  handleChange = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const email_id = urlParams.get("email_id");
    const reset_token = urlParams.get("reset_token");

    if (!email_id || !reset_token) {
      console.log("No email_id or reset_token given");
    } else {
      this.setState({
        user: {
          email_id,
          reset_token,
        },
      });
    }

    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.user.new_password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.reset_pass_res !== this.state.reset_pass_res) {
      if (this.state.reset_pass_res.status) {
        setTimeout(() => {
          window.location = "/login";
        }, 2000);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.reset_pass_res !== prevState.reset_pass_res) {
      return {
        ...prevState,
        reset_pass_res: nextProps.reset_pass_res,
      };
    }
    return null;
  }

  handleSubmit = async () => {
    this.props.clearAlerts();

    const email_id = this.state.user.email_id;
    const password = this.state.user.new_password;
    const reset_token = this.state.user.reset_token;

    if (email_id && password) {
      // ADD CONFIRM MODAL HERE
      await this.props.resetPassword({
        email_id,
        password,
        reset_token,
      });
    }
  };

  render() {
    const { user } = this.state;
    const { alert } = this.props;

    return (
      <div className="reset-password-box">
        <Typography variant="h5">Reset password for this account:</Typography>
        <Typography variant="h6">
          <strong>{this.state.user.email_id}</strong>
        </Typography>

        <ValidatorForm
          className="reset-password-form"
          onSubmit={this.handleSubmit}
          ref="form"
          autoComplete="off"
        >
          {alert.message && (
            <NotificationBox
              open={true}
              variant={alert.type}
              message={alert.message}
            />
          )}

          <TextValidator
            fullWidth
            value={user.new_password}
            onChange={this.handleChange}
            name="new_password"
            type="password"
            className="form-control"
            id="outlined-name"
            label="New Password"
            margin="normal"
            variant="outlined"
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <TextValidator
            fullWidth
            value={user.confirm_password}
            onChange={this.handleChange}
            name="confirm_password"
            type="password"
            className="form-control"
            id="outlined-name"
            label="Confirm Password"
            margin="normal"
            variant="outlined"
            validators={["isPasswordMatch", "required"]}
            errorMessages={["this field is required"]}
          />

          <Button
            type="submit"
            variant="outlined"
            color="default"
            className="btn-primary btn-submit"
          >
            Reset password
          </Button>
        </ValidatorForm>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { reset_pass_res } = state.users;
  return { alert, reset_pass_res };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  resetPassword: userActions.resetPassword,
};

const connectedResetPasswordPagePage = connect(
  mapState,
  actionCreators
)(ResetPasswordPage);
export { connectedResetPasswordPagePage as ResetPasswordPage };

export default ResetPasswordPage;
