import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import "./styles.scss";

class PropertyKeyFigures extends Component {
  getLanguageBasedKeyFigure = (keyFigure) => {
    var key =
      this.props.generalState.languageCode === "en"
        ? keyFigure.property_key_en
        : keyFigure.property_key_de;
    var value =
      this.props.generalState.languageCode === "en"
        ? keyFigure.property_value_en
        : keyFigure.property_value_de;
    return {
      key,
      value,
    };
  };

  render() {
    const { property } = this.props;
    const isPropertyKeyFigures = property && property.key_figures;
    const property_key_figures = property.key_figures;

    return (
      property && (
        <div className="property-key-figures-box">
          <Grid container spacing={3}>
            {isPropertyKeyFigures && (
              <Grid item md={6}>
                <p className="title-text">Key Figures</p>
                <table className="table">
                  <tbody>
                    {property_key_figures.length > 0 ? (
                      property_key_figures.map((key_kigure, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {this.getLanguageBasedKeyFigure(key_kigure).key} :
                            </td>
                            <td>
                              {this.getLanguageBasedKeyFigure(key_kigure).value}{" "}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>Not available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Grid>
            )}
            {property && property.property_floor_plans.length > 0 && (
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                  }}
                >
                  {property.property_floor_plans
                    .filter((fp) => !fp.is_delete)
                    .map((fp, idx) => (
                      <img
                        key={idx}
                        src={fp.floor_plan_image_preview}
                        alt="floor-plan-img"
                        className="floor-plan-img"
                      />
                    ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </div>
      )
    );
  }
}

function mapState(state) {
  const { general } = state;
  return { generalState: general };
}

export default connect(mapState)(PropertyKeyFigures);
