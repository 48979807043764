function removePlusCode(address) {
  const plusCodePattern = /\b\w+\+\w+\b/;
  const match = address.match(plusCodePattern);

  if (match) {
    const plusCode = match[0];
    // Remove the plus code and any following space, comma, or both
    const newAddress = address.replace(plusCode, "").trim();
    return newAddress.replace(/^(\s*,\s*|\s*,|\s*)/, "");
  }

  return address;
}

export default removePlusCode;
