import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

export default class PropertyLegalInfo extends Component {
  render() {
    const { legalInfo, showTitle } = this.props;

    return (
      <>
        {showTitle ? (
          <h2 className="txt-title txt-title-secondary">Legal Warnings</h2>
        ) : (
          ""
        )}
        <p className="content-text">
          {legalInfo ? ReactHtmlParser(legalInfo) : "No information"}
        </p>
      </>
    );
  }
}
