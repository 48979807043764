import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { userActions } from "../../_actions";
import {
  List,
  ListItem,
  ListItemText,
  Link,
  Popper,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import "./styles.scss";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import HeaderLogo from "../DashboardComponent/common/HeaderLogo";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOpen: false,
      profile_data: {},
      open: false,
    };

    this.loggedUser = false;
    var tokenInfo = this.props.parseJwt();
    if (tokenInfo && tokenInfo.status) {
      this.loggedUser = tokenInfo.data;
      this.props.getUserDetails();
    }
    this.anchorRef = createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var update = {};
    if (
      nextProps.users &&
      nextProps.users.profile_data &&
      nextProps.users.profile_data !== prevState.profile_data
    ) {
      update = {
        profile_data: nextProps.users.profile_data,
      };
    }

    return update;
  }

  logOut = () => {
    this.props.logOut();
  };

  handleToggle = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
    this.setState({ open: !this.state.open });
  };

  handleClose = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({ dropDownOpen: false });
    this.setState({ open: false });
  };

  composeEmail = () => {
    const location = window.location;

    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const currentDate = `${day}/${month}/${year}`;
    const currentTime = date.toLocaleTimeString();

    const email = process.env.REACT_APP_INFO_EMAIL;
    let subject = `Website inquiry (${currentDate} ${currentTime})`;
    let body = "";

    const propertyViewPattern = /\/property\/view\/\d{1,}/;
    if (propertyViewPattern.test(location.pathname)) {
      subject = `Property inquiry (${currentDate} ${currentTime})`;
      body = location.href;
    }

    window.open(`mailto:${email}?subject=${subject}&body=${body}`);
  };

  handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ open: false });
    } else if (event.key === "Escape") {
      this.setState({ open: false });
    }
  }


  render() {
    return (
      <List className="header-links">
        <ListItem>
          <ListItemText>
            <NavLink to="/marketplace">Buy</NavLink>
          </ListItemText>
        </ListItem>
        {this.loggedUser ? (
          <>
            <ListItem>
              <ListItemText>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/dashboard/properties">Sell</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/faq">FAQ</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <HeaderLogo user={this.props.users} />
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem
              ref={this.anchorRef}
              className="user-avtar"
              aria-controls="menu-list-grow"
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              <div>
                {this.state.profile_data &&
                this.state.profile_data.profile_image ? (
                  <div>
                    <img
                      alt="user-avtar"
                      className="user-avtar-img"
                      src={this.state.profile_data.profile_image}
                    />
                  </div>
                ) : (
                  <div className="user-avtar-img">
                    <PersonIcon color="error" />
                  </div>
                )}
              </div>
              <span className="text-username">
                {this.loggedUser.first_name}
              </span>
              {/*<span className="caret down"></span>*/}

              <Popper
                className="custom-dropdown"
                open={this.state.dropDownOpen}
                anchorEl={this.anchorRef.current}
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper id="menu-list-grow">
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList>
                          <MenuItem>
                            <NavLink to="/dashboard/profile">
                              <span>Profile</span>
                            </NavLink>
                          </MenuItem>
                          <MenuItem onClick={this.logOut}>
                            <Link underline="none">
                              <span>Logout</span>
                            </Link>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemText
                ref={this.anchorRef}
                id="composition-button"
                aria-controls={this.state.open ? "composition-menu" : undefined}
                aria-expanded={this.state.open ? "true" : undefined}
                aria-haspopup="true"
                onClick={this.handleToggle}
                className="nav-link-toggle"
              >
                About
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </ListItemText>
              <Popper
                open={this.state.open}
                anchorEl={this.anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className="header-sublinks-container"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper className="header-sublinks">
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList
                          autoFocusItem={this.state.open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={this.handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => {
                              this.handleToggle();
                              this.props.navigate("/about");
                            }}
                          >
                            What Is Immobilium
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.handleToggle();
                              this.props.navigate("/how-it-works");
                            }}
                          >
                            How It Works
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.handleToggle();
                              this.props.navigate("/listing");
                            }}
                          >
                            Our Listing Is Your Listing
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.handleToggle();
                              this.props.navigate("/escrow");
                            }}
                          >
                            Immobilium Escrow
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.handleToggle();
                              this.props.navigate("/loans");
                            }}
                          >
                            Hard Money Loans
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/faq">FAQ</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <HeaderLogo users={this.props.users} />
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/login" onClick={this.props.navControl}>
                  Login
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/register" onClick={this.props.navControl}>
                  Register
                </NavLink>
              </ListItemText>
            </ListItem>
          </>
        )}
      </List>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  parseJwt: userActions.parseJwt,
  logOut: userActions.logout,
  getUserDetails: userActions.getUserDetails,
};

const connectedHeaderLinks = connect(mapState, actionCreators)(HeaderLinks);
export { connectedHeaderLinks as HeaderLinks };