import { TextField } from '@material-ui/core';
import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

/*
    errorMessages?: any[] | string | undefined;
    validators?: any[] | undefined;
    name: string;
    value: any;
    validatorListener?: ((isValid: boolean) => void) | undefined;
    withRequiredValidator?: boolean | undefined;
    [key: string]: any;
*/

export default class MessageValidator extends ValidatorComponent {
    renderValidatorComponent() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        return (
            <TextField
                {...rest}
                error={!isValid || error}
                size="small"
                minRows={5}
                multiline
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                spellCheck={false}
            />
        );
    }   
}