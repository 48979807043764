import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./styles.scss";

const WalletSeedBackupModal = ({ seed, open, handleClose }) => {
  const [seeds, setSeeds] = useState([]);
  const [step, setStep] = useState(0);
  const [randSeeds, setRandSeeds] = useState([]);
  const [confirmSeeds, setConfirmSeeds] = useState([]);
  const [confirmedSeeds, setConfirmedSeeds] = useState([]);

  useEffect(() => {
    setSeeds((seed || "").split(" "));
  }, [seed]);

  useEffect(() => {
    const array = [...seeds];
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    setRandSeeds(array);
    setConfirmSeeds([...array]);
    setConfirmedSeeds([]);
  }, [seeds]);

  const infoPage = () => {
    return (
      <>
        <h1>No Backup, No Money</h1>
        <div className="detail">
          You must save the secret phrase. It is crucial for accessing your
          account
        </div>
        <div className="action-sheet">
          <Button
            className="btn-secondary"
            variant="contained"
            onClick={() => setStep(1)}
          >
            Back Up Now
          </Button>
          <div className="divider">
            <div>or</div>
          </div>
          <a onClick={handleClose}>Do it later</a>
        </div>
      </>
    );
  };

  const backupPage = () => {
    return (
      <>
        <h1>Save backup phrase</h1>
        <div className="detail">
          Since only you control your money, you'll need to save your backup
          phrase in case this app is deleted or &nbsp;
          <a onClick={() => setStep(0)}>go back</a>
        </div>
        <div>
          Please carefully write down these words or, &nbsp;
          <CopyToClipboard text={seed}>
            <a>copy them</a>
          </CopyToClipboard>
        </div>
        <div className="seed-box">
          {seeds.map((sd) => (
            <div key={sd}>{sd}</div>
          ))}
        </div>
        <div>You will confirm this phrase on the next screen</div>
        <Button
          className="btn-secondary"
          variant="contained"
          onClick={() => {
            resetConfirm();
            setStep(2);
          }}
        >
          I've written it down
        </Button>
      </>
    );
  };

  const putConfirmSeed = (idx) => {
    const sd = confirmSeeds[idx];
    confirmSeeds.splice(idx, 1);
    setConfirmedSeeds([...confirmedSeeds, sd]);
    setConfirmSeeds([...confirmSeeds]);
  };

  const popConfirmSeed = (idx) => {
    const sd = confirmedSeeds[idx];
    confirmedSeeds.splice(idx, 1);
    setConfirmedSeeds([...confirmedSeeds]);
    setConfirmSeeds([...confirmSeeds, sd]);
  };

  const resetConfirm = () => {
    setConfirmedSeeds([]);
    setConfirmSeeds([...randSeeds]);
  };

  const confirmPage = () => {
    return (
      <>
        <h1>Confirm backup</h1>
        <div className="detail">
          Verify your backup phrase or &nbsp;
          <a onClick={() => setStep(1)}>go back</a>
        </div>
        <div className="seed-box seed-box-selector seed-box-confirm">
          {confirmedSeeds.map((sd, idx) => (
            <div key={sd} onClick={() => popConfirmSeed(idx)}>
              {sd}
            </div>
          ))}
        </div>
        <div>Please tap each word in the correct order</div>
        <div className="seed-box seed-box-selector">
          {confirmSeeds.map((sd, idx) => (
            <div key={sd} onClick={() => putConfirmSeed(idx)}>
              {sd}
            </div>
          ))}
        </div>
        <div className="action-sheet">
          <Button
            className="btn-secondary"
            variant="contained"
            onClick={handleClose}
            disabled={confirmedSeeds.join(" ") != seed}
          >
            Confirm
          </Button>
          <div className="divider">
            <div>or</div>
          </div>
          <div>
            <a onClick={resetConfirm}>Clear</a>
            &nbsp; and tap again
          </div>
        </div>
      </>
    );
  };

  const pages = [infoPage, backupPage, confirmPage];

  return (
    <Dialog open={open} className="wallet-seedbackup-container">
      <DialogContent>{pages[step]()}</DialogContent>
    </Dialog>
  );
};

export default WalletSeedBackupModal;
export { WalletSeedBackupModal };
