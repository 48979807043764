import React from "react";
import "./styles.scss";
import SearchSection from "./SearchSections";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-container">
        <h1>Find your dream home & get it for less</h1>
        <SearchSection />
      </div>
    </section>
  );
};

export default HeroSection;
