import React, { Component } from "react";
import { Dialog, DialogContent, Button } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import "./styles.scss";

class PaymentDetailModal extends Component {
  render() {
    return (
      <Dialog
        className="payment-detail-modal"
        fullWidth={true}
        maxWidth={"xs"}
        onClose={this.props.handleClose}
        open={this.props.open}
      >
        <DialogContent>
          <div className="block-header"></div>
          <div className="payment-icon">
            <CreditCardOutlinedIcon />
          </div>

          <div className="block-content">
            <p className="txt-type">Funds withdrawal</p>
            <p className="txt-status">Paid</p>
          </div>
          <div className="list-item">
            <div className="left-block">
              <p className="txt-value">xxxx 8904</p>
              <p className="txt-title">Payment account</p>
            </div>
            <div className="right-block">
              <p className="txt-amount">-$201.50</p>
              <p className="txt-title">6 Aug 215 AM</p>
            </div>
          </div>

          <Button className="btn-print">Print Receipt</Button>
        </DialogContent>
      </Dialog>
    );
  }
}

export default PaymentDetailModal;
