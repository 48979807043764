import React, { Component } from "react";
import { Dialog, DialogContent, Box, Button } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "./styles.scss";

class WithdrawModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isIntNumber", (value) => {
      const regex = /^[1-9]\d*$/;
      return regex.test(value);
    });
  }

  render() {
    return (
      <Dialog
        className="deposit-modal"
        fullWidth={true}
        maxWidth={"xs"}
        onClose={this.props.handleClose}
        open={this.props.open}
      >
        <DialogContent>
          <div className="block-header">Withdraw</div>
          <div className="payment-icon">
            <CreditCardOutlinedIcon />
          </div>

          <div className="block-content">
            <p className="txt-type">How much USD would you like to withdraw?</p>
          </div>

          <ValidatorForm
            className="default-form"
            onSubmit={this.props.nextWithdrawModal}
            ref="form"
            autoComplete="off"
          >
            <Box mx={3} my={1}>
              <TextValidator
                fullWidth
                value={this.props.balance}
                name="balance"
                className="form-control"
                label="Current Balance"
                margin="normal"
                variant="filled"
                disabled
              />
            </Box>

            <Box mx={3} my={1}>
              <TextValidator
                fullWidth
                value={this.props.withdrawAmount}
                onChange={this.props.handleChange}
                name="withdraw"
                className="form-control"
                label="Money Amount ($)"
                margin="normal"
                variant="outlined"
                validators={["required", "isIntNumber"]}
                errorMessages={["This field is required", "Not allowed"]}
              />
            </Box>

            <Button type="submit" className="btn-print">
              Next Step
            </Button>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    );
  }
}

export default WithdrawModal;
