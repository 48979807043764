import React from "react";
import { Navigate } from "react-router-dom";
import { WalletWarningPage } from "../../pages/TokenWalletWarningPage";
import { secureStorage } from "../../_services";

const AuthLayout = ({ children, ...props }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });
  if (secureStorage.getItem(process.env.REACT_APP_LOGIN_KEY)) {
    if (secureStorage.getItem("is_kyc_verified") && !props.kycPage) {
      if (!secureStorage.getItem("is_admin") && props.adminPage) {
        return <Navigate to="/not-found" />;
      }
      if (props.checkWallet && !secureStorage.getItem("wallet_address")) {
        return (
          <div className="dashboard-container">
            <WalletWarningPage />
          </div>
        );
      }
      return <div className="dashboard-container">{childrenWithProps}</div>;
    } else if (props.kycPage) {
      return <div className="dashboard-container">{childrenWithProps}</div>;
    } else {
      return <div className="dashboard-container">{childrenWithProps}</div>;
      // return <Navigate to="/users/kyc" />;
    }
  } else {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
};

export default AuthLayout;
export { AuthLayout };
