import { authHeader } from "../_helpers";
import { userService } from "./index";
import { initialState } from "../_reducers/location.reducer";

export const locationService = {
  addLocation,
  getLocations,
  getLocationById,
  updateLocationById,
  deleteLocationById,
};

function addLocation(formData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/locations`,
    requestOptions
  ).then(handleResponse);
}

function getLocations(page = 0, pageSize = 10) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/locations?languageCode=${initialState.languageCode}&page=${page}&limit=${pageSize}`,
    requestOptions
  ).then(handleResponse);
}

function getLocationById(locationId) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/locations/${locationId}`,
    requestOptions
  ).then(handleResponse);
}

function updateLocationById(updateLocationData, prevLocationData) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ updateLocationData, prevLocationData }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/locations`,
    requestOptions
  ).then(handleResponse);
}

function deleteLocationById(locationId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/locations/${locationId}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    try {
      const data = text && JSON.parse(text);
      if (response.status === 451) {
        window.location = "/users/kyc";
      }
      if (!response.ok) {
        if (response.status === 401) {
          userService.logout();
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    } catch (e) {
      return Promise.reject("Not Found");
    }
  });
}
