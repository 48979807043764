import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { general } from "./general.reducer";
import { location } from "./location.reducer";
import { blockchain } from "./blockchain.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  general,
  alert,
  location,
  blockchain,
});

export default rootReducer;
