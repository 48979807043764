import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import "./styles.scss";
import { Typography } from "@material-ui/core";

class HighlightsComponent extends Component {
  render() {
    const { highlights } = this.props;

    return (
      <Typography variant="body1" style={{ fontSize: "20px" }}>
        {ReactHtmlParser(highlights)}
      </Typography>
    );
  }
}

export default HighlightsComponent;
