import { Container, Box, Divider, Typography, Grid } from "@material-ui/core";
import Lottie from "react-lottie";
import houseInGlass from "../assets/animations/house-in-glass.json";
import ChatGptParagraph from "../components/ChatGptParagraph";
import createLottieOptions from "../_helpers/lottieOptions";

const Escrow = () => {
  const houseInGlassOptions = createLottieOptions(houseInGlass);
  return (
    <Box sx={{ my: 10 }} className="page-box">
      <Container>
        <Typography variant="h4" paragraph={true} align="center">
          Immobilium Escrow
        </Typography>
        <Divider /> <Divider />
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h5"
            paragraph={true}
            className="pages-section-header"
          >
            Immobilium Escrow
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Lottie
                  options={houseInGlassOptions}
                  height={300}
                  width={300}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph={true} align="justify">
                An escrow account is a third-party account that holds funds,
                documents, or other assets on behalf of two or more parties
                involved in a transaction.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                In the context of real estate transactions, an escrow account is
                typically opened by a neutral third party (often an attorney or
                title company) to hold funds and important documents related to
                the sale of the property until all the conditions of the
                transaction are met.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                The purpose of the escrow account is to ensure that both the
                buyer and the seller fulfill their obligations in the
                transaction before any money or documents are exchanged. For
                example, if a buyer agrees to purchase a property, they might
                deposit the funds for the purchase into an escrow account until
                the seller can provide proof that the property has a clear
                title, all necessary inspections have been completed, and any
                other conditions of the sale have been met.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Once all the conditions of the transaction have been met, the
                funds in the escrow account are released to the appropriate
                parties. The escrow company also typically ensures that any
                necessary documents are properly signed and recorded, and that
                any taxes or other fees associated with the transaction are
                paid.
              </Typography>
              <Typography variant="body1" paragraph={true} align="justify">
                Overall, an escrow account helps to protect both the buyer and
                seller by ensuring that all aspects of the transaction are
                completed properly and that funds are not released until all
                obligations have been fulfilled.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ChatGptParagraph
          title="Escrow"
          text={`So, when someone wants to buy a house, they give some money to the person who is selling the house. But sometimes, they are not sure if they really want to buy the house or if the person selling the house is going to give them a good house. So, they ask another person to hold on to the money until they are sure. This other person is called an “escrow agent.” It’s like if you give your friend some candy to hold for you until you are ready to eat it. The escrow agent makes sure that both the person buying the house and the person selling the house are happy before they give the money to the person selling the house.`}
        />
      </Container>
    </Box>
  );
};

export default Escrow;
