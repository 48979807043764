import React, { Component } from "react";
import { Container, Grid, Hidden } from "@material-ui/core";
import PropertyCoverImage from "./PropertyCoverImage";
import PropertyTitle from "./PropertyTitle";
import PropertyInvestmentBox from "./PropertyInvestmentBox";
import PropertyFinancialInformation from "./PropertyFinancialInformation";
import HighlightsComponent from "./HighlightsComponent";
import PropertyImages from "./PropertyImages";
import PropertyKeyFigures from "./PropertyKeyFigures";
import PropertyDocuments from "./PropertyDocuments";
import PropertyLegalInfo from "./PropertyLegalInfo";
import InvestmentDetails from "./InvestmentDetails";
import LocationDetails from "./LocationDetails";
import PatnerDetails from "./PatnerDetails";
import "./styles.scss";

export default class PropertyReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      investment_block_height: 500,
      property_details: this.props.property,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { property } = nextProps;
    if (property !== prevState.property_details) {
      return {
        ...prevState,
        property_details: property,
      };
    }

    return null;
  }

  render() {
    const { property_details } = this.state;
    // console.log("property_details: ", property_details);
    const isProperty = Object.keys(property_details).length;

    const property_documents = property_details.property_documents.map(
      (document) => {
        return {
          file_path: document.property_doc_filename,
          description:
            document.file_description_en || document.file_description_de,
          updated_date: "--/--/----",
          download_link: "",
          blockchain_link: "",
        };
      }
    );

    return (
      isProperty && (
        <div className="property-review-page">
          <PropertyCoverImage property={property_details} />
          <Container>
            <div className="property-detail-box">
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <PropertyTitle property={property_details} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Hidden mdUp>
                  <Grid
                    id="investment-box"
                    className="investment-box"
                    item
                    md={4}
                    sm={12}
                    xs={12}
                  >
                    <PropertyInvestmentBox property={property_details} />
                  </Grid>
                </Hidden>

                <Grid item md={8} sm={12} xs={12}>
                  {property_details.property_type !== "direct" && (
                    <PropertyFinancialInformation property={property_details} />
                  )}
                  <HighlightsComponent property={property_details} />
                </Grid>

                <Hidden smDown>
                  <Grid item md={4} sm={12} xs={12}>
                    <PropertyInvestmentBox
                      isFooter={true}
                      property={property_details}
                    />
                  </Grid>
                </Hidden>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <PropertyImages property={property_details} />
                  <PropertyKeyFigures property={property_details} />
                  <PropertyDocuments
                    documents={property_documents}
                    showTitle={true}
                  />
                  <PropertyLegalInfo
                    legalInfo={property_details.legal_warnings_en}
                    showTitle={true}
                  />
                  {property_details.property_type !== "direct" && (
                    <InvestmentDetails property={property_details} />
                  )}
                  <LocationDetails property={property_details} />
                  <PatnerDetails property={property_details} />
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      )
    );
  }
}
