// EditPartnerPage.js

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Button,
  Avatar,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { TabPanel } from "../../components/GeneralComponent/LanguageTabs";
import { NavBarComponent as DashboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import "./styles.scss";
import { withRouter } from "../../_helpers";
import Editor from "../../components/Editor";

const EditPartnerPage = (props) => {
  const {
    alert,
    general,
    clearAlerts,
    getPartnerById,
    updatePartnerById,
    navigate,
    params,
  } = props;

  const [partner, setPartner] = useState({
    id: 0,
    name_en: "",
    type: "escrow",
    email: "",
    iban: "",
    swift: "",
    description_en: "",
    partner_logoPreview: "",
    partner_logo: "",
  });

  const [tabValue, setTabValue] = useState(0);

  // Clear alerts and fetch partner data on mount
  useEffect(() => {
    clearAlerts();
    getPartnerById(params.id);

    // Cleanup function to reset partner id on unmount
    return () => {
      setPartner((prevPartner) => ({ ...prevPartner, id: null }));
    };
  }, [clearAlerts, getPartnerById, params.id]);

  // Update partner state when general.partner changes
  useEffect(() => {
    const { partner: partnerData } = general;
    if (
      partnerData &&
      partnerData.status === true &&
      partnerData.data.id !== partner.id
    ) {
      const { data } = partnerData;
      setPartner({
        id: data.id,
        name_en: data.name.en,
        type: data.type,
        email: data.email,
        iban: data.iban,
        swift: data.swift,
        description_en: data.description.en,
        partner_logoPreview: data.logo,
        partner_logo: "",
      });
    }
  }, [general.partner, partner.id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPartner((prevPartner) => ({
      ...prevPartner,
      [name]: value,
    }));
  };

  const handleChangeSelect = (event) => {
    setPartner((prevPartner) => ({
      ...prevPartner,
      type: event.target.value,
    }));
  };

  const handleCapture = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setPartner((prevPartner) => ({
          ...prevPartner,
          partner_logo: file,
          partner_logoPreview: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const selectPartnerLogo = () => {
    document.getElementById("partner_logo").click();
  };

  const handleSubmit = () => {
    updatePartnerById(partner).then(() => {
      setTimeout(() => {
        clearAlerts();
        navigate("/dashboard/partners");
      }, 2000);
    });
  };

  return (
    <div className="main-content edit-partner-page">
      <DashboardNavBarComponent pageName="manage-partner" />
      <Grid container>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              {alert.message && (
                <NotificationBox
                  open={true}
                  variant={alert.type}
                  message={alert.message}
                />
              )}
              <ValidatorForm
                className="edit-partner-form partner-form default-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TabPanel value={tabValue} index={0} className="tab-panel">
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                          <label className="form-label">Type</label>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              value={partner.type}
                              onChange={handleChangeSelect}
                            >
                              <MenuItem value="" disabled>
                                Select partner type
                              </MenuItem>
                              <MenuItem value="escrow">Escrow agent</MenuItem>
                              <MenuItem value="listing">Listing agent</MenuItem>
                              <MenuItem value="other">Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label className="form-label">Name *</label>
                          <TextValidator
                            fullWidth
                            value={partner.name_en}
                            onChange={handleChange}
                            name="name_en"
                            placeholder="Enter name"
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label className="form-label">Email</label>
                          <TextValidator
                            fullWidth
                            value={partner.email}
                            onChange={handleChange}
                            name="email"
                            placeholder="Enter email"
                            variant="outlined"
                            validators={["isEmail"]}
                            errorMessages={["Enter a valid email"]}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label className="form-label">IBAN</label>
                          <TextValidator
                            fullWidth
                            value={partner.iban}
                            onChange={handleChange}
                            name="iban"
                            placeholder="Enter IBAN"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label className="form-label">SWIFT/BIC code</label>
                          <TextValidator
                            fullWidth
                            value={partner.swift}
                            onChange={handleChange}
                            name="swift"
                            placeholder="Enter SWIFT/BIC code"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label className="form-label">Description</label>
                          <Editor
                            key={partner.description_en ? "key1" : "key2"}
                            id="description_en"
                            name="description_en"
                            value={partner.description_en}
                            onChange={(e) =>
                              setPartner((prevPartner) => ({
                                ...prevPartner,
                                description_en: e.target.value,
                              }))
                            }
                            plugins="autolink link image lists preview"
                            toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    {/* Remove unused TabPanel if not needed */}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Button variant="contained" component="label">
                      Upload Logo
                      <input
                        type="file"
                        id="partner_logo"
                        onChange={handleCapture}
                        style={{ display: "none" }}
                      />
                    </Button>
                  </Grid>
                  {partner.partner_logoPreview && (
                    <Grid item md={12} xs={12}>
                      <Box component="div" m={2} ml={0}>
                        <Avatar
                          style={{
                            cursor: "pointer",
                            height: 150,
                            width: 150,
                            borderRadius: 6,
                          }}
                          alt="Profile Picture"
                          variant="square"
                          onClick={selectPartnerLogo}
                          src={partner.partner_logoPreview}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item md={12} xs={12}>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="default"
                      className="btn-primary btn-submit"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => {
  const { alert, general } = state;
  return { alert, general };
};

// Map dispatch actions to component props
const mapDispatchToProps = {
  clearAlerts: alertActions.clear,
  getPartnerById: generalActions.getPartnerById,
  updatePartnerById: generalActions.updatePartnerById,
};

// Connect the component and wrap with withRouter
const connectedPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPartnerPage)
);

// Export the connected component as a named export
export { connectedPage as EditPartnerPage };
