export const SITE = {
    name: 'Immobilium',
    logo: '/static/images/logo.png',
    image: '/static/images/site.png', 
    url: 'https://immobilium.io', 
    description: 'What is Immobilium? Revolutionary International Real Estate platform that is reshaping the Industry. Built on innovation and efficiency, Immobilium.io shifts power to real estate agents by transforming properties into highly liquid assets, enabling instant global trading in a compliant and secure manner. With the flexibility to transact in both traditional fiat currencies (Dollars, Euros, etc….)',
    propertyDefaultDescriptionStartText: 'Real Estate Property for Sale in Immobilium.io:', 
    email: 'info@immobilium.io',
    phone: '+ 1 310 873 7520',
    address: '415 N.Crescent dr. Suite #240 Beverly Hills, CA. 90210',
}