import React from "react";
import "./styles.scss";

const OverviewTokenWalletBlock = (props) => {
  const { blockchain } = props;

  return (
    <div className="overview-widget-block-1">
      <div className="value-block">
        {
          <>
            <p className="txt-value widget-block-2">
              <span>{blockchain.balances && blockchain.balances.immo}</span>
              <span>{blockchain.balances && blockchain.balances.zbs}</span>
            </p>
            <p className="txt-title widget-block-2">
              <span>Immobilium Token</span>
              <span>ZBS Token</span>
            </p>
          </>
        }
      </div>
    </div>
  );
};

export default OverviewTokenWalletBlock;
