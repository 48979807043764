import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

const PriceFilter = ({ params }) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [currency, setCurrency] = useState("USD");

  useEffect(() => {
    if (params.min_price) {
      setMinPrice(params.min_price);
    }
    if (params.max_price) {
      setMaxPrice(params.max_price);
    }
    if (params.currency) {
      setCurrency(params.currency);
    }
  }, [params]);

  const handleApply = () => {
    const currentUrl = window.location.href; // Get the current URL
    const baseUrl = currentUrl.split("?")[0]; // Get the base URL without query parameters
    const queryParams = new URLSearchParams(window.location.search); // Get existing query parameters

    // Remove page parameter
    queryParams.delete("page");

    // Set the new parameters
    queryParams.set("min_price", minPrice);
    queryParams.set("max_price", maxPrice);
    queryParams.set("currency", currency);

    // Construct the new URL
    const newUrl = `${baseUrl}?${queryParams.toString()}`;

    // Redirect to the new URL
    window.location.href = newUrl; // Redirect to the new URL
  };

  const handleReset = () => {
    setMinPrice("");
    setMaxPrice("");
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <div className="range-header">
        <p>Price Range</p>
        <RadioGroup
          aria-label="currency"
          name="currency"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          row
          className="range-radio-group"
        >
          <FormControlLabel value="EUR" control={<Radio />} label="EUR" />
          <FormControlLabel value="USD" control={<Radio />} label="USD" />
        </RadioGroup>
      </div>
      <div className="yacht-filter-form-fields-wrapper">
        <TextField
          className="yacht-filter-fields"
          label="Min Price"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="price-min"
        />
        <TextField
          className="yacht-filter-fields"
          label="Max Price"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="price-max"
        />
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete">
          Reset
        </Button>
        <Button onClick={handleApply} className="btn-save">
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default PriceFilter;
