import React, { Component } from "react";
import { Typography, Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import PropertyBoxComponent from "../../components/PropertyBoxComponent";
import Loader from "../../components/GeneralComponent/Loader";
import "./styles.scss";

class PropertiesMarketplace extends Component {
  render() {
    const pmProperties = this.props.properties;
    const properties = pmProperties?.data || [];

    return (
      <>
        <div className="block market-lising-section">
          {pmProperties && Object.keys(pmProperties).length > 0 ? (
            <>
              <Container>
                {properties.some((p) => p.property_type === "direct") && (
                  <>
                    {/* <h3 className="h3 block-title">Explore Direct Sale</h3> */}
                    <Grid container spacing={4}>
                      {properties
                        .filter((p) => p.property_type === "direct")
                        .map((property, index) => {
                          return (
                            <Grid key={index} item md={3} sm={6} xs={12}>
                              <PropertyBoxComponent
                                variant={property.property_type}
                                property={property}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                )}

                {properties.some((p) => p.property_type === "secondary") && (
                  <>
                    <h3 className="h3 block-title">Explore Sale</h3>
                    <Grid container spacing={2}>
                      {properties
                        .filter((p) => p.property_type === "secondary")
                        .map((property, index) => {
                          return (
                            <Grid key={index} item md={3} sm={6} xs={12}>
                              <PropertyBoxComponent
                                variant={property.property_type}
                                property={property}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                )}

                {properties.some((p) => p.property_type === "primary") && (
                  <>
                    <h3 className="h3 block-title">Explore Crowdfunding</h3>
                    <Grid container spacing={2}>
                      {properties
                        .filter((p) => p.property_type === "primary")
                        .map((property, index) => {
                          return (
                            <Grid key={index} item md={3} sm={6} xs={12}>
                              <PropertyBoxComponent
                                variant={property.property_type}
                                property={property}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                )}
              </Container>
              {/* <div className="properties-show-all-container">
                <Typography align="center" variant="body2">
                  <Link to="/marketplace" className="show-all-link">
                    Show all {`(${this.props.count})`}
                  </Link>
                </Typography>
              </div> */}
            </>
          ) : (
            <>
              <Loader />
              <Typography align="center" variant="body2">
                Loading properties...
              </Typography>
            </>
          )}
        </div>
      </>
    );
  }
}

export { PropertiesMarketplace };
