import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useConfirm } from "./useConfirm";

const ConfirmModal = () => {
  const { prompt = "", proceed, cancel, isOpen = false } = useConfirm();

  const title = prompt.title || undefined;
  const message = prompt.message || prompt;
  const okayTitle = prompt.okay || "Ok";
  const cancelTitle = prompt.cancel;

  const modals = [];
  if (isOpen) {
    modals.push(
      <Dialog
        open={isOpen}
        onClose={cancel}
        disableBackdropClick={prompt.disableBackdropClick}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          {cancelTitle && (
            <Button disableElevation onClick={() => cancel && cancel()}>
              {cancelTitle}
            </Button>
          )}
          <Button
            variant="contained"
            disableElevation
            onClick={() => proceed && proceed()}
          >
            {okayTitle}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return modals.length ? (
    <>
      {modals.map((m, idx) => (
        <div key={idx}>{m}</div>
      ))}
    </>
  ) : null;
};

export { ConfirmModal };
export default ConfirmModal;
