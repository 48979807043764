import React, { useEffect, useState } from "react";
import {
  numberToRegionName,
  regionData,
  regionDataNumbers,
} from "../yacht-types";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Button,
  ListSubheader,
} from "@material-ui/core";

const RegionFilter = ({ params }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);

  useEffect(() => {
    if (params.geo) {
      const geoNumbers = params.geo.split("*").filter(Boolean).map(Number);
      const countries = geoNumbers
        .map((number) => numberToRegionName[number])
        .filter(Boolean);

      setSelectedCountries(countries);
    }
  }, [params]);

  const handleChange = (event) => {
    setSelectedCountries(event.target.value);
  };

  const handleReset = () => {
    setSelectedCountries([]);
  };

  const handleApply = () => {
    const currentUrl = window.location.href; // Get the current URL
    const baseUrl = currentUrl.split("?")[0]; // Get the base URL without query parameters
    const queryParams = new URLSearchParams(window.location.search); // Get existing query parameters

    // Set the new parameters
    // Map the regions to their corresponding numbers
    const numbers = selectedCountries.map(
      (region) => regionDataNumbers[region]
    );

    // Join the numbers with '*'
    const geoParam = numbers.join("*");

    // Remove page parameter
    queryParams.delete("page");

    // Create the URL parameter
    queryParams.set("geo", `${geoParam}`);

    // Construct the new URL
    const newUrl = `${baseUrl}?${queryParams.toString()}`;

    // Redirect to the new URL
    window.location.href = newUrl; // Redirect to the new URL
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <p>Region</p>
      <div className="yacht-filter-form-fields-wrapper">
        <Select
          labelId="region-select-label"
          id="region-select"
          multiple
          value={selectedCountries}
          onChange={handleChange}
          className="yacht-filter-select-field"
          variant="outlined"
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "Select Region";
            }
            return selected.join(", ");
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 300,
              },
            },
          }}
        >
          {regionData
            .map((group) => [
              <ListSubheader key={`${group.continent}-header`}>
                {group.continent}
              </ListSubheader>,
              ...group.countries.map((country) => (
                <MenuItem key={country} value={country}>
                  <Checkbox checked={selectedCountries.indexOf(country) > -1} />
                  <ListItemText primary={country} />
                </MenuItem>
              )),
            ])
            .flat()}
        </Select>
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button className="btn-delete" onClick={handleReset}>
          Reset
        </Button>
        <Button className="btn-save" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default RegionFilter;
