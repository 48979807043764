import React, { Component } from "react";

export default class PropertyCoverImage extends Component {
  render() {
    const { property } = this.props;

    return (
      <div className="property-cover-image">
        {property.property_picture && property.property_picture.length > 0 && (
          <div
            className="image"
            style={{
              backgroundImage:
                `url('` +
                property.property_picture[0].property_picture_preview +
                `')`,
            }}
          ></div>
        )}
      </div>
    );
  }
}
