import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import "./styles.scss";
import { Typography } from "@material-ui/core";

class ThePropertyComponent extends Component {
  render() {
    const { theProperty } = this.props;

    return (
      <Typography variant="body1">{ReactHtmlParser(theProperty)}</Typography>
    );
  }
}

export default ThePropertyComponent;
