import { Tooltip } from "@material-ui/core";
import React from "react";

const getLink = (hrefLink, text) => {
  if (!hrefLink) {
    return <a>{text}</a>;
  }
  return (
    <a href={hrefLink} target="_blank">
      {text}
    </a>
  );
};

const DocumentsSection = ({ property }) => {
  const documents = property.property_documents;
  // console.log(documents);

  if (documents && documents.length > 0) {
    return (
      <div className="documents-section">
        <div className="documents-wrapper">
          <h3>Documents</h3>
          <table className="styled-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Last Updated</th>
                <th>Download Link</th>
                <th>Transaction Link</th>
              </tr>
            </thead>
            <tbody>
              <>
                {documents.map((document, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{document.file_path}</td>
                      <td>{document.description}</td>
                      <td>{document.updated_date}</td>
                      <td>{getLink(document.download_link, "Download")}</td>
                      <td>
                        <Tooltip
                          title={
                            "This document is notarized in 0bsnetwork blockchain"
                          }
                          arrow
                        >
                          {getLink(
                            document.blockchain_link,
                            "View in Blockchain Explorer"
                          )}
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};

export default DocumentsSection;
