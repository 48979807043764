import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import WelcomeAgent from "./Agent";
import WelcomeSeller from './Seller';
import WelcomeBuyer from './Buyer';
import { Container } from "@material-ui/core";


const types = {
    agent: WelcomeAgent,
    seller: WelcomeSeller,
    buyer: WelcomeBuyer
}

const Welcome = () => {

    const params = useParams();

    const Component = types[params.type];

    if(Component) return (<Container>
        <Component />
    </Container>)

    return (<NotFound />)
}


export default Welcome;